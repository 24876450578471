import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RoundBadge from '@/components/Common/Badge/RoundBadge';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import RadioList, { OptionType } from '@/components/Common/Input/Radio/RadioList';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChevronDownIcon } from '@/components/Icon';
import ConfirmExitPopup from '@/components/Products/ConfirmExitPopup';
import { colors } from '@/const/colors';
import { SALESTYPE } from '@/const/products';
import { SELL_CAR_DEFAULT_TITLE } from '@/const/title';
import { useProductEnumContext } from '@/contexts/Products/ProductEnumContext';
import useUpdateProductFormMutation from '@/hooks/query/useUpdateProductFormMutation';
import useBlockNavigation from '@/hooks/useBlockNavigation';
import useExitConfirmationPopup from '@/hooks/useExitConfirmationPopup';
import useFetchProductData from '@/hooks/useFetchProductData';
import { productsFormAtom, useResetProductsForm } from '@/store/products';
import { formatNumber } from '@/utils/common';
import { processDistanceInput, processPowerInput, validateDistance, validatePower } from '@/utils/validation';

const keyValueList: KeyValueListType = {
  color: '색상',
  transmission: '변속기',
  fuel: '연료',
  garage: '차고지',
  salesType: '판매 방식 변경',
};

type SalesTypeKey = keyof typeof SALESTYPE;

const AdditionalInfoForm = () => {
  const { productEnum } = useProductEnumContext();
  const navigate = useNavigate();
  const { id } = useParams();

  const [productFormData] = useAtom(productsFormAtom);
  const setProductFormData = useSetAtom(productsFormAtom);
  const resetProductsForm = useResetProductsForm();
  const [prevData, setPrevData] = useState<ProductDetailResponse | null>(null);

  const distanceRef = useRef<HTMLInputElement>(null);
  const fuelRef = useRef<HTMLInputElement>(null);
  const powerRef = useRef<HTMLInputElement>(null);
  const colorRef = useRef<HTMLInputElement>(null);
  const garageRef = useRef<HTMLInputElement>(null);

  const checkForChanges = () => {
    return !!getChangedData;
  };

  const saveChanges = () => {
    const changedData = getChangedData();
    if (Object.keys(changedData).length > 0) {
      const request = { id: Number(productFormData?.id), ...changedData };
      updateProductTemporarySaveMutation.mutate(request);
    }
  };

  const { isShowExitConfirmPopup, openExitConfirmPopup, closeExitConfirmPopup, confirmExit } = useExitConfirmationPopup(
    checkForChanges,
    saveChanges,
    resetProductsForm,
  );

  useBlockNavigation(openExitConfirmPopup);

  const [isShow, setIsShow] = useState(false);
  const [title, setTitle] = useState('');
  const [optionData, setOptionData] = useState<OptionType[]>([]);

  const [distanceError, setDistanceError] = useState(false);
  const [distanceErrorMsg, setDistanceErrorMsg] = useState('');
  const [powerError, setPowerError] = useState(false);
  const [powerErrorMsg, setPowerErrorMsg] = useState('');

  useFetchProductData(id, productFormData.id === 0);

  useEffect(() => {
    setPrevData(productFormData);
  }, []);

  const updateProductTemporarySaveMutation = useUpdateProductFormMutation({});

  const getChangedData = () => {
    const changedData: Partial<ProductRegisterRequest> = {};
    const keysToCheck: Array<keyof ProductRegisterRequest> = [
      'transmission',
      'distance',
      'fuel',
      'power',
      'color',
      'garage',
    ];
    keysToCheck.forEach((key) => {
      if (prevData?.[key]?.code !== productFormData?.[key]?.code || prevData?.[key] !== productFormData?.[key]) {
        changedData[key] = productFormData[key]?.code || productFormData[key];
      }
    });
    return changedData;
  };

  const updateProductNextStepMutation = useUpdateProductFormMutation({
    url: `/products/sales/detail-info/${id}`,
  });

  const handleClickNext = () => {
    const changedData: Partial<ProductRegisterRequest> = getChangedData();
    if (Object.keys(changedData).length > 0) {
      const request = { id: Number(productFormData?.id), ...changedData };
      updateProductNextStepMutation.mutate(request);
    } else {
      navigate(`/products/sales/detail-info/${id}`, { replace: true });
    }
  };

  const handleClickPrev = () => {
    navigate(`/products/sales/price-trend/${id}`, { replace: true });
  };

  const handleClickSelectBox = (key: string) => {
    if (!productEnum) return;

    setTitle(key);
    setOptionData(productEnum[key] as OptionType[]);
    setIsShow(true);
  };

  const handleSelectOption = (item: OptionType, key: string) => {
    if (key === 'transmission' && distanceRef && distanceRef.current) {
      distanceRef.current.focus();
    }
    setProductFormData({ ...productFormData, [key]: item } as ProductDetailResponse);
    setTimeout(() => {
      setIsShow(false);
    }, 200);
  };

  const handleChangeDistanceInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    const trimmedValue = processDistanceInput(value);
    const { isValid, errorMessage } = validateDistance(trimmedValue);

    setProductFormData({ ...productFormData, [name]: trimmedValue } as ProductDetailResponse);

    if (!isValid) {
      setDistanceError(true);
      setDistanceErrorMsg(errorMessage);
    } else {
      setDistanceError(false);
      setDistanceErrorMsg('');
    }
  };

  const handleChangePowerInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;

    const trimmedValue = processPowerInput(value);

    const { isValid, errorMessage } = validatePower(trimmedValue);

    setProductFormData({ ...productFormData, [name]: trimmedValue } as ProductDetailResponse);

    if (!isValid) {
      setPowerError(true);
      setPowerErrorMsg(errorMessage);
    } else {
      setPowerError(false);
      setPowerErrorMsg('');
    }
  };

  const handleChangeRadioInput = (newVal: OptionType, key: string) => {
    if (key === 'transmission' && distanceRef && distanceRef.current) {
      distanceRef.current.focus();
    }
    setProductFormData({ ...productFormData, [key]: newVal } as ProductDetailResponse);
  };

  const scrollIntoView = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e) {
      const { name } = e.target;
      if (name === 'distance') {
        distanceRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'power') {
        powerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
  };

  useLayoutEffect(() => {
    const detectMobileKeyboard = () => {
      scrollIntoView();
    };
    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  });

  const isDisabledNextBtn = () => {
    return (
      !productFormData?.transmission?.code ||
      !productFormData?.distance ||
      !productFormData?.fuel?.code ||
      !productFormData?.power ||
      distanceError ||
      powerError
    );
  };

  return (
    <>
      <MenuHeader title={SALESTYPE[productFormData.type?.code as SalesTypeKey] || SELL_CAR_DEFAULT_TITLE} />

      <div className="flex flex-col w-full pt-[60px] p-4">
        <div className="py-4 my-2 pb-24">
          <div className="flex justify-between relative">
            <h3 className="text-2xl font-bold text-gray-8 break-keep">
              차량의 추가 정보를
              <br />
              입력해주세요.
            </h3>
            <RoundBadge text="6/9" />
          </div>

          <div className="flex flex-col gap-[30px] pt-[30px]">
            <div>
              <label className="text-base font-medium mb-3 text-gray-8">
                변속기<span className="font-normal text-red">(필수)</span>
              </label>
              <div className="px-1">
                <RadioList
                  name="transmission"
                  list={(productEnum?.transmission.filter((item) => item.code !== 'BOTH') as OptionType[]) || []}
                  value={productFormData?.transmission?.code || ''}
                  onChange={(val) => {
                    handleChangeRadioInput(val, 'transmission');
                  }}
                  justify="equal"
                />
              </div>
            </div>
            <TextInputLabelUp
              ref={distanceRef}
              name="distance"
              label="주행거리"
              placeholder="주행거리 입력"
              value={formatNumber(productFormData?.distance?.toString(), true) || ''}
              onChange={(e) => handleChangeDistanceInput(e)}
              onFocus={(e) => scrollIntoView(e)}
              inputMode="numeric"
              required
              error={distanceError}
              errorMsg={distanceErrorMsg}
              suffix="km"
              maxLength={8}
            />
            <TextInputLabelUp
              ref={powerRef}
              name="power"
              label="마력수"
              placeholder="마력수 입력"
              value={productFormData?.power || ''}
              error={powerError}
              errorMsg={powerErrorMsg}
              onChange={(e) => handleChangePowerInput(e)}
              onFocus={(e) => scrollIntoView(e)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  handleClickSelectBox('fuel');
                }
              }}
              required
              inputMode="numeric"
              maxLength={3}
            />

            <div onClick={() => handleClickSelectBox('fuel')}>
              <TextInputLabelUp
                ref={fuelRef}
                name="fuel"
                label="연료"
                placeholder="연료 선택"
                value={productFormData?.fuel?.desc || ''}
                type="text"
                readOnly
                suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
                required
              />
            </div>

            <div onClick={() => handleClickSelectBox('color')}>
              <TextInputLabelUp
                ref={colorRef}
                name="color"
                label="색상"
                placeholder="색상 선택"
                value={productFormData?.color?.desc || ''}
                type="text"
                readOnly
                suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
              />
            </div>

            <div onClick={() => handleClickSelectBox('garage')}>
              <TextInputLabelUp
                ref={garageRef}
                name="garage"
                label="차고지"
                placeholder="차고지 선택"
                value={productFormData?.garage?.desc || ''}
                type="text"
                readOnly
                suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
              />
            </div>
          </div>
        </div>
        <DownToUpDetailPopup isShow={isShow} onClosePopup={() => setIsShow(false)} title={keyValueList[title]}>
          <div className="px-4 pb-8">
            <RadioList
              name={title}
              list={optionData}
              value={productFormData?.[title]?.code || ''}
              onChange={(val) => {
                handleSelectOption(val, title);
              }}
              horizontal={false}
            />
          </div>
        </DownToUpDetailPopup>
        <DualFooterButton
          leftButtonText="이전"
          onClickLeftButton={handleClickPrev}
          rightButtonText="다음(상세정보)"
          disabledRight={isDisabledNextBtn()}
          onClickRightButton={handleClickNext}
        />
      </div>
      <ConfirmExitPopup isShow={isShowExitConfirmPopup} onClose={closeExitConfirmPopup} onConfirm={confirmExit} />
    </>
  );
};

export default AdditionalInfoForm;
