import React, { SVGProps } from 'react';

const SvgVehicleInspectionPlusColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 50 50" {...props}>
    <path
      fill="#D1DDFF"
      fillRule="evenodd"
      d="M11.25 25c5.523 0 10-4.477 10-10a9.971 9.971 0 0 0-.727-3.75h7.42a4.167 4.167 0 0 1 4.167 4.167v19.047a4.167 4.167 0 0 1-4.167 4.167H10.417a4.167 4.167 0 0 1-4.167-4.167V23.662a9.954 9.954 0 0 0 5 1.338Z"
      clipRule="evenodd"
    />
    <path
      fill="#3C5FBF"
      d="M6.25 32.546h42.593v1.918a4.167 4.167 0 0 1-4.167 4.167h-34.26a4.167 4.167 0 0 1-4.166-4.167v-1.918ZM32.11 15.813h5.764c1.648 0 3.212.733 4.268 2l2.137 2.564 3.226 3.764a5.556 5.556 0 0 1 1.337 3.615v4.79H32.11V15.813Z"
    />
    <circle cx={15.378} cy={37.11} r={4.001} fill="#F5F5F5" stroke="#414141" strokeWidth={4.167} />
    <circle cx={39.718} cy={37.11} r={4.001} fill="#F5F5F5" stroke="#414141" strokeWidth={4.167} />
    <path
      fill="#D1DDFF"
      fillRule="evenodd"
      d="M41.705 17.335c.158.149.308.308.448.477l2.137 2.565 3.226 3.763c.186.216.353.445.501.684-.17.075-.359.116-.557.116H38.07a1.389 1.389 0 0 1-1.39-1.389v-4.827c0-.768.622-1.39 1.39-1.39h3.636Z"
      clipRule="evenodd"
    />
    <circle cx={10.938} cy={14.688} r={8.438} fill="#3C5FBF" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11.879 11.072a.938.938 0 0 0-1.875 0v2.678h-2.68a.938.938 0 0 0 0 1.875h2.68v2.679a.938.938 0 0 0 1.875 0v-2.679h2.677a.938.938 0 0 0 0-1.875H11.88v-2.678Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgVehicleInspectionPlusColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgVehicleInspectionPlusColorIcon;
