import { useAtom, useSetAtom } from 'jotai';
import React, { Suspense, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router-dom';

import { getLicenseList } from '@/api/public';
import loadingLottie from '@/assets/lottie/loadingLottie.json';
import SearchBox from '@/components/Common/Input/SearchBox';
import PullToRefresh from '@/components/Common/PullToRefresh';
import LicenseListSkeleton from '@/components/Common/Skeleton/LicenseListSkeleton';
import MenuHeader from '@/components/Header/MenuHeader';
import { BuyLicenseBoldIcon, EmptyLicensePlateIcon, SellLicenseBoldIcon } from '@/components/Icon';
import ResetIcon from '@/components/Icon/ResetIcon';
import LicenseItemView from '@/components/License/LicenseItemView';
import { colors } from '@/const/colors';
import { useLicenseEnumContext } from '@/contexts/License/LicenseEnumContext';
import useSearchLicenseQuery from '@/hooks/query/useSearchLicenseQuery';
import useNavigateBack from '@/hooks/useNavigateBack';
import { licenseSearchParamsAtom } from '@/store/license';
import { isMemberLoadedAtom, memberAtom } from '@/store/member';
import { compareObjects, getCurrentYear } from '@/utils/common';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const Lottie = React.lazy(() => import('lottie-react'));

const License = () => {
  const currentYear = getCurrentYear();
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const location = useLocation();
  const { licenseEnum } = useLicenseEnumContext();
  const [member] = useAtom(memberAtom);
  const [isMemberLoaded] = useAtom(isMemberLoadedAtom);
  const [searchParams] = useAtom(licenseSearchParamsAtom);
  const setSearchParams = useSetAtom(licenseSearchParamsAtom);
  const [initialSearchParams, setInitialSearchParams] = useState<LicenseSearch>({
    useClassification: { code: '', desc: '' },
    minYear: '2000',
    maxYear: (currentYear + 1).toString(),
    minTons: '1',
    maxTons: '27',
    licenseSalesType: '',
    licenseType: { code: '', desc: '' },
    page: 1,
    memberId: member?.id || null,
  });
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    if (member) {
      setSearchParams((prev) => ({ ...prev, memberId: member?.id }));
      setInitialSearchParams((prev) => ({ ...prev, memberId: member?.id }));
    }
  }, [member]);

  const getLicenseSearchParams = (page: number): URLSearchParams => {
    const params = new URLSearchParams({ page: page.toString() });
    if (searchParams) {
      if (searchParams.minTons) {
        params.set('minTons', searchParams.minTons.toString());
      }
      if (searchParams.maxTons) {
        params.set('maxTons', searchParams.maxTons.toString());
      }
      if (searchParams.minYear) {
        params.set('minYear', searchParams.minYear.toString());
      }
      if (searchParams.maxYear) {
        params.set('maxYear', searchParams.maxYear.toString());
      }
      if (searchParams.licenseSalesType) {
        params.set('licenseSalesType', searchParams.licenseSalesType);
      }
      if (searchParams.licenseType?.code) {
        params.set('licenseType', searchParams.licenseType?.code);
      }
      if (searchParams.useClassification?.code) {
        params.set('useClassification', searchParams.useClassification?.code);
      }
      if (searchParams.memberId) {
        params.set('memberId', searchParams.memberId.toString());
      }
    }
    return params;
  };

  const getLicenseData = async (page: number) => {
    try {
      if (isMemberLoaded) {
        const response = await getLicenseList(getLicenseSearchParams(page));
        const data: License[] = response.data;
        return data;
      }
    } catch (error) {
      throw new Error('Error');
    }
  };

  const { licenseList, isLoading, isFetching, hasNextPage, fetchNextPage, refetch } = useSearchLicenseQuery({
    rowsPerPage: 10,
    queryFn: ({ queryKey, pageParam = 1 }) => {
      return getLicenseData(pageParam);
    },
    searchParams,
  });

  const handleBackNavigation = () => {
    if (location.state) {
      navigate('/more');
    } else {
      navigateBack();
    }
  };

  const navigateToPurchase = () => {
    navigate('/license/purchase');
  };

  const navigateToSales = () => {
    navigate('/license/form', { state: licenseEnum });
  };

  const onClickSearch = () => {
    navigate('/license/search');
  };

  const isShowFilterResetBtn = () => {
    return !compareObjects(searchParams, initialSearchParams);
  };

  const onClickFilterClearBtn = () => {
    setSearchParams({
      useClassification: { code: '', desc: '' },
      minYear: '2000',
      maxYear: (currentYear + 1).toString(),
      minTons: '1',
      maxTons: '27',
      licenseSalesType: '',
      licenseType: { code: '', desc: '' },
      page: 1,
      memberId: member?.id || null,
    });
  };

  return (
    <>
      <MenuHeader title="번호판 거래" onClickPrevBtn={handleBackNavigation}></MenuHeader>
      <div className="w-full flex flex-col pb-28 pt-[116px] h-[100dvh]">
        <div className="fixed items-center flex z-30 top-[55px] mx-auto left-0 right-0 p-4 w-full min-w-[280px] max-w-[720px] max-h-[60px] bg-white">
          <SearchBox name="truckName" placeholder="번호판 검색" value="" onClick={onClickSearch}></SearchBox>
        </div>

        {isShowFilterResetBtn() ? (
          <div className="flex items-center justify-end mr-4">
            <div className="flex items-center justify-center cursor-pointer" onClick={onClickFilterClearBtn}>
              <ResetIcon color={colors.gray[8]}></ResetIcon>
              <span className="text-gray-8 text-sm ml-1">필터해제</span>
            </div>
          </div>
        ) : null}

        <section className="flex sm280:flex-col gap-3 p-4">
          <div
            className="relative rounded-[12px] border border-primary bg-primary p-[18px] text-gray-0 w-full cursor-pointer"
            onClick={navigateToPurchase}
          >
            <p className="font-bold text-[17px] leading-[20px]">
              번호판
              <br />
              구매문의 남기기
            </p>
            <p className="font-medium text-[11px] leading-[14px] mt-2">
              찾고있는 번호판이 없다면
              <br className="sm280:hidden" /> 문의를 남겨주세요.
            </p>
            <div className="sm280:absolute sm280:top-4 sm280:right-4 sm680:absolute sm680:top-4 sm680:right-4 flex justify-end">
              <BuyLicenseBoldIcon color={colors.gray[0]} width={40} height={40} />
            </div>
          </div>
          <div
            className="relative rounded-[12px] border border-primary bg-gray-0 p-[18px] text-gray-8 w-full cursor-pointer"
            onClick={navigateToSales}
          >
            <p className="font-bold text-[17px] leading-[20px]">
              번호판
              <br />
              판매하러 가기
            </p>
            <p className="font-medium text-[11px] leading-[14px] text-gray-7 mt-2">
              직트럭에서 쉽고 빠르게
              <br className="sm280:hidden" /> 번호판 판매하기
            </p>
            <div className="sm280:absolute sm280:top-4 sm280:right-4 sm680:absolute sm680:top-4 sm680:right-4 flex justify-end">
              <SellLicenseBoldIcon color={colors.primary} width={40} height={40} />{' '}
            </div>
          </div>
        </section>

        {isFetching || isLoading || !isMemberLoaded ? (
          <LicenseListSkeleton></LicenseListSkeleton>
        ) : licenseList?.length === 0 ? (
          <div className="flex flex-col justify-center items-center w-full min-h-[calc(100vh-474px)]">
            <EmptyLicensePlateIcon color={colors.gray[4]} width={120} height={120}></EmptyLicensePlateIcon>
            <span className="text-gray-7 text-lg mt-[30px] text-center text-base">
              판매 중인 번호판이 없습니다.
              <br /> 필터를 다시 설정해주세요.
            </span>
          </div>
        ) : (
          <PullToRefresh
            onRefresh={() => refetch()}
            maxDistance={100}
            disabled={false}
            loadingComponent={
              <div className="flex justify-center items-center h-[100px]">
                <div className="w-[54px] h-[54px]">
                  <Suspense fallback={<></>}>
                    <Lottie animationData={loadingLottie} />
                  </Suspense>
                </div>
              </div>
            }
          >
            <section className="flex flex-col gap-3 pt-3 w-full px-4 pb-24">
              {licenseList?.map((item, index) => (
                <div key={index}>
                  <LicenseItemView
                    data={item}
                    index={index}
                    isShowPurchaseButton
                    isDisabledButton={item?.requested}
                  ></LicenseItemView>
                </div>
              ))}
              <div
                ref={ref}
                className={`h-40 flex flex-col justify-center items-center pb-[76px] ${hasNextPage ? '' : 'hidden'}`}
              >
                <MoreVertIcon sx={{ fontSize: 80, color: colors.gray[2] }}></MoreVertIcon>
              </div>
            </section>
          </PullToRefresh>
        )}
      </div>
    </>
  );
};

export default License;
