import React from 'react';
import LazyLoad from 'react-lazyload';
import { useNavigate } from 'react-router-dom';

import { asYYYYMM, formatDistanceToThousandKm, formatPrice } from '@/utils/common';
import Flicking from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';

interface ProductCardProps {
  products: ProductsList[];
}
const ProductFlicking = ({ products }: ProductCardProps) => {
  const navigate = useNavigate();

  const fetchDetail = (id: number) => {
    navigate('/products/' + id, { state: { isPublicDetail: true } });
  };

  return (
    <>
      {products.map((item, index) => (
        <link rel="preload" href={item.representImageUrl} as="image" key={index} />
      ))}
      {!!products.length && (
        <Flicking
          align="center"
          circular={true}
          renderOnlyVisible={true}
          moveType="strict"
          circularFallback="bound"
          className="z-0"
        >
          {products?.map((item, index) => (
            <div
              key={index}
              onClick={() => fetchDetail(item.id)}
              className="mb-4 mr-2 text-left cursor-pointer w-[80%]"
            >
              <LazyLoad className="relative w-full sm280:h-[180px] h-[246px]" offset={100}>
                <div className="w-full sm280:h-[180px] h-[246px] rounded-lg border border-gray-100">
                  <img
                    loading="lazy"
                    className="rounded-lg w-full h-full object-cover"
                    src={item?.representImageUrl}
                    alt="트럭 사진"
                    style={{ pointerEvents: 'none' }}
                  />
                </div>
                <div className="absolute bottom-0 left-0 w-full h-[40px] p-3 rounded-b-lg bg-gradient-to-t from-[rgba(0,0,0,0.7)] to-[rgba(0,0,0,0)]">
                  <div className="absolute bottom-0 left-0 pb-2 px-[10px]">
                    <span className="text-gray-0 text-[14px] font-medium bottom-[10px] left-[10px]">
                      매물번호 {item?.productsNumber}
                    </span>
                  </div>
                </div>
              </LazyLoad>
              <div className="font-semibold text-gray-8 text-[20px] leading-[23px] my-2">{item.truckName}</div>
              <div className="text-gray-6 my-2 text-[16px] leading-[19px] break-keep">
                {asYYYYMM(item.firstRegistrationDate)} • {item.transmission.desc} •{' '}
                {formatDistanceToThousandKm(item.distance)}
                만km • {item.loadedInnerLength}m • {item.power}마력
              </div>
              {item.price && (
                <div className="my-2 text-gray-700">
                  <span className="font-semibold text-2xl xxs:text-lg xs:text-lg">
                    {formatPrice(item.price.toString())}
                  </span>
                </div>
              )}
            </div>
          ))}
        </Flicking>
      )}
    </>
  );
};

export default ProductFlicking;
