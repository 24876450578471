import dayjs from 'dayjs';
import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import { deleteOtherExpensesHistory } from '@/api/drive/deleteDrive';
import { getOtherExpensesHistory } from '@/api/drive/getDrive';
import BasicButton from '@/components/Common/Button/BasicButton';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import CalendarPopup from '@/components/Common/Popup/CalendarPopup';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import OtherExpenseHistoryForm from '@/components/Drive/OtherExpense/OtherExpenseHistoryForm';
import {
  CalendarCheckOutlineIcon,
  CheckCircleOutlineIcon,
  CheckCircleSolidIcon,
  ChevronRightIcon,
  PlusSmallIcon,
} from '@/components/Icon';
import { colors } from '@/const/colors';
import { EXPENSE, EXPENSE_UNCLASSIFIED, INCOME, INCOME_UNCLASSIFIED } from '@/const/drive';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import { otherExpenseHistorySearchParamsAtom } from '@/store/otherExpenseHistory';
import { formatNumber } from '@/utils/common';
import { Checkbox } from '@mui/material';

interface OtherExpenseDetailProps {
  baseDate?: Date;
  isEditMode: boolean;
  updateEditMode: () => void;
  selectedList: number[];
  updateIsNodata?: (isNodata: boolean) => void;
}

const OtherExpenseDetail = ({
  baseDate,
  isEditMode,
  updateEditMode,
  selectedList,
  updateIsNodata,
}: OtherExpenseDetailProps) => {
  const location = useLocation();
  const queryClient = useQueryClient();

  const [isOpenHistoryPopup, setIsOpenHistoryPopup] = useState(false);
  const { showToast } = useToastContext();

  const { member } = useMemberContext();
  const [driveVehicleInfoId, setDriveVehicleInfoId] = useState(member?.driveVehicleInfo?.id);

  const [selectedDeleteList, setSelectedDeleteList] = useState<number[]>([]);

  const [formData, setFormData] = useState<OtherExpenseHistoryItem>();

  const [popupInfo, setPopupInfo] = useState<BasicPopupProps>({
    isShow: false,
    textRightBtn: '',
    onClickRightBtn: undefined,
  });

  useEffect(() => {
    if (member) {
      setDriveVehicleInfoId(member?.driveVehicleInfo?.id);
    }
  }, [member]);

  useEffect(() => {
    setSelectedDeleteList(selectedList);
  }, [selectedList]);

  const [searchParams] = useAtom(otherExpenseHistorySearchParamsAtom);
  const setSearchParams = useSetAtom(otherExpenseHistorySearchParamsAtom);

  const [isOpenCalendarPopup, setIsOpenCalendarPopup] = useState(false);

  const [selectedDate, setSelectedDate] = useState<Date>(
    baseDate || dayjs(location?.state?.data.baseDay).toDate() || new Date(),
  );

  const getOtherExpensesHistoryList = async () => {
    try {
      if (searchParams.driveVehicleInfoId && dayjs(selectedDate).format('YYYY-MM-DD')) {
        const params = new URLSearchParams({
          driveVehicleInfoId: searchParams.driveVehicleInfoId.toString(),
          baseDay: dayjs(selectedDate).format('YYYY-MM-DD'),
        });
        const response = await getOtherExpensesHistory(params);
        return response.response?.[0];
      }
    } catch (error) {
      throw new Error('Error');
    }
  };

  const {
    data: otherExpenseHistory,
    isSuccess,
    refetch,
  } = useQuery(
    [
      'get-other-expense-history',
      { driveVehicleInfoId: driveVehicleInfoId, baseDay: dayjs(selectedDate).format('YYYY-MM-DD') },
    ],
    () => getOtherExpensesHistoryList(),
    {
      onSuccess: (res) => {
        if (updateIsNodata) {
          updateIsNodata(!res);
        }
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
      refetchOnWindowFocus: false,
      enabled: !!searchParams.driveVehicleInfoId && !!dayjs(selectedDate).format('YYYY-MM-DD'),
    },
  );

  const closePopup = () => {
    setPopupInfo({ ...popupInfo, isShow: false });
  };

  const expenseData = otherExpenseHistory?.data.filter(
    (item: OtherExpenseHistoryItem) =>
      item.otherExpensesCategoryType.code === EXPENSE_UNCLASSIFIED || item.otherExpensesCategoryType.code === EXPENSE,
  );

  const incomeData = otherExpenseHistory?.data.filter(
    (item: OtherExpenseHistoryItem) =>
      item.otherExpensesCategoryType.code === INCOME_UNCLASSIFIED || item.otherExpensesCategoryType.code === INCOME,
  );

  const handleClickDetail = (item: OtherExpenseHistoryItem) => {
    if (isEditMode) {
      handleChangeCheck(item);
      return;
    }
    setIsOpenHistoryPopup(true);
    setFormData(item);
  };

  const handleOpenFormModal = () => {
    setIsOpenHistoryPopup(true);
    setFormData(undefined);
  };

  const handleChangeCheck = (item: OtherExpenseHistoryItem) => {
    if (selectedDeleteList.includes(item.otherExpensesHistoryId)) {
      const updatedItem = selectedDeleteList.filter((id) => id !== item.otherExpensesHistoryId);
      setSelectedDeleteList([...updatedItem]);
    } else {
      setSelectedDeleteList([...selectedDeleteList, item?.otherExpensesHistoryId]);
    }
  };

  const openDeleteConfirmation = () => {
    setPopupInfo({
      isShow: true,
      textContent: '선택한 내역을 삭제할까요?',
      textLeftBtn: '취소',
      onClickLeftBtn: closePopup,
      textRightBtn: '삭제',
      onClickRightBtn: handleConfirmDelete,
    });
  };

  const handleConfirmDelete = () => {
    if (selectedDeleteList) {
      deleteOtherExpensesHistoryMutation.mutate(selectedDeleteList);
    }
  };

  const deleteOtherExpensesHistoryMutation = useMutation(
    (deleteList: number[]) => deleteOtherExpensesHistory(deleteList),
    {
      onSuccess: () => {
        closePopup();
        showToast('선택한 내역을 삭제했어요.', 'success', 'bottom');
        setSelectedDeleteList([]);
        updateEditMode();
        refetch();
        queryClient.invalidateQueries({ queryKey: ['get-drive-info'] });
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
    },
  );

  useEffect(() => {
    if (member) {
      setSearchParams({
        driveVehicleInfoId: member.driveVehicleInfo.id,
        baseYearAndMonth: dayjs(selectedDate).format('YYYY-MM') || searchParams.baseYearAndMonth,
      });
    }
  }, [member, selectedDate]);

  const onClickDateSelector = () => {
    if (isEditMode) {
      return;
    }

    setIsOpenCalendarPopup(true);
  };

  const onCloseCalendarPopup = () => {
    setIsOpenCalendarPopup(false);
  };

  const onChangeDate = (date: string | undefined) => {
    if (date === null || date === undefined) {
      return;
    } else {
      setSelectedDate(dayjs(date).toDate());
    }
  };

  const updateSelectedDate = (date: Date) => {
    setSelectedDate(date);
  };

  return (
    <>
      <div className="w-full pb-24">
        <div className="flex flex-col gap-5 px-4">
          <div
            className="flex items-center justify-between text-gray-7 font-semibold text-[18px]"
            onClick={onClickDateSelector}
          >
            <div className="flex items-center gap-1">
              <CalendarCheckOutlineIcon color={colors.gray[7]} />
              {dayjs(selectedDate).format('YYYY-MM-DD')}
            </div>

            {!isEditMode && <ChevronRightIcon color={colors.gray[6]} className="cursor-pointer" />}
          </div>
          {expenseData?.length > 0 && (
            <div>
              <div className="text-gray-7 mb-2">지출</div>
              <div className="flex flex-col gap-3">
                {expenseData.map((item: OtherExpenseHistoryItem, index: number) => (
                  <dl
                    key={`expense-${index}`}
                    className="border border-gray-3 rounded-[8px] p-4 text-gray-7"
                    onClick={() => handleClickDetail(item)}
                  >
                    <dt className="font-medium flex justify-between">
                      <span className="font-medium">-{formatNumber(item.price.toString())}원</span>
                      {isEditMode ? (
                        <Checkbox
                          checked={selectedDeleteList.includes(item.otherExpensesHistoryId)}
                          icon={<CheckCircleOutlineIcon color={colors.gray[5]} />}
                          checkedIcon={<CheckCircleSolidIcon color={colors.primary} />}
                          sx={{
                            padding: 0,
                          }}
                        />
                      ) : (
                        <ChevronRightIcon color={colors.gray[6]} />
                      )}
                    </dt>
                    <dd className="text-[14px]">
                      {item.categoryName} {item.contents && <span className="text-gray-5">|</span>} {item.contents}
                    </dd>
                  </dl>
                ))}
              </div>
            </div>
          )}

          {incomeData?.length > 0 && (
            <div>
              <div className="text-gray-7 mb-2">수익</div>
              <div className="flex flex-col gap-3">
                {incomeData.map((item: OtherExpenseHistoryItem, index: number) => (
                  <dl
                    key={`income-${index}`}
                    className="border border-gray-3 rounded-[8px] p-4 text-gray-7"
                    onClick={() => handleClickDetail(item)}
                  >
                    <dt className="font-medium flex justify-between">
                      <span className="text-primary font-medium">+{formatNumber(item.price)}원</span>
                      {isEditMode ? (
                        <Checkbox
                          checked={selectedDeleteList.includes(item.otherExpensesHistoryId)}
                          icon={<CheckCircleOutlineIcon color={colors.gray[5]} />}
                          checkedIcon={<CheckCircleSolidIcon color={colors.primary} />}
                          sx={{
                            padding: 0,
                          }}
                          onClick={() => handleChangeCheck(item)}
                        />
                      ) : (
                        <ChevronRightIcon color={colors.gray[6]} />
                      )}
                    </dt>
                    <dd className="text-[14px]">
                      {item.categoryName} {item.contents && <span className="text-gray-5">|</span>} {item.contents}
                    </dd>
                  </dl>
                ))}
              </div>
            </div>
          )}
          {!isEditMode && (
            <BasicButton
              name="기타내역 추가"
              icon={<PlusSmallIcon color={colors.gray[6]} />}
              bgColor={colors.gray[0]}
              borderColor={colors.gray[3]}
              textColor={colors.gray[6]}
              fontSize={16}
              height={95}
              fontWeight="semibold"
              onClick={() => handleOpenFormModal()}
            />
          )}
        </div>

        {!!isEditMode && (
          <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 h-14 max-w-[720px] min-w-[280px] bg-white">
            <div className="absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]"></div>
            <div className="w-full min-w-[240px]">
              <BasicButton
                name={`${selectedDeleteList?.length}건 삭제하기`}
                bgColor={colors.primary}
                borderColor={colors.primary}
                textColor={colors.gray[0]}
                fontSize={16}
                height={48}
                fontWeight="bold"
                isDisabled={selectedDeleteList?.length < 1}
                onClick={openDeleteConfirmation}
              />
            </div>
          </div>
        )}
      </div>
      {driveVehicleInfoId && (
        <DownToUpDetailPopup
          isShow={isOpenHistoryPopup && !!driveVehicleInfoId}
          onClosePopup={() => setIsOpenHistoryPopup(false)}
          title={`${!!formData ? '기타내역 수정' : '기타내역 추가'}`}
          isHeightFull
          isBackdrop={true}
        >
          <OtherExpenseHistoryForm
            selectedDate={selectedDate}
            driveVehicleInfoId={driveVehicleInfoId}
            data={formData}
            updateSelectedDate={updateSelectedDate}
            onClosePopup={() => setIsOpenHistoryPopup(false)}
            refetch={refetch}
          />
        </DownToUpDetailPopup>
      )}
      <BasicPopup
        isShow={popupInfo.isShow}
        title={popupInfo?.title}
        textContent={popupInfo.textContent}
        textLeftBtn={popupInfo.textLeftBtn}
        onClickLeftBtn={popupInfo.onClickLeftBtn}
        textRightBtn={popupInfo.textRightBtn}
        onClickRightBtn={popupInfo.onClickRightBtn}
      ></BasicPopup>

      <CalendarPopup
        selectedDate={selectedDate.toDateString()}
        onChangeSelectedDate={onChangeDate}
        isShow={isOpenCalendarPopup}
        onClose={onCloseCalendarPopup}
        textLeftBtn="닫기"
        onClickLeftBtn={onCloseCalendarPopup}
      />
    </>
  );
};

export default OtherExpenseDetail;
