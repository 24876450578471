import dayjs from 'dayjs';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';

import BasicButton from '../Common/Button/BasicButton';
import NoneStyleMultilineTextArea from '../Common/Input/NoneStyleMultilineTextArea';
import SimpleInput from '../Common/Input/SimpleInput';
import BasicPopup from '../Common/Popup/BasicPopup';
import CalendarPopup from '../Common/Popup/CalendarPopup';
import DownToUpDetailPopup from '../Common/Popup/DownToUpDetailPopup';
import TooltipComponent from '../Common/Tooltip/TooltipComponent';
import FuelingHistoryForm from './FuelingHistoryForm';
import TransferForm from './TransferForm';
import { deleteTransportInfo, patchDriveHistory, postDriveHistory } from '@/api/public';
import TransportForm from '@/components/Drive/TransportForm';
import { ChevronRightIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { DRIVE_HISTORY_TYPE_EMPTY_TRANSPORT, DRIVE_HISTORY_TYPE_TRANSPORT } from '@/const/drive';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { nonNumericOrDecimalPattern } from '@/const/regex';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { compareArrays, formatNumber } from '@/utils/common';

interface DriveFormProps {
  selectedDate: Date;
  driveVehicleInfoId: number;
  data?: DriveHistoryList;
  onClosePopup: () => void;
  refetch: () => void;
  onControlTutorial?: () => void;
  updateSelectedDate?: (date: Date) => void;
}

const DriveForm = ({
  selectedDate,
  updateSelectedDate,
  driveVehicleInfoId,
  data,
  onClosePopup,
  refetch,
  onControlTutorial,
}: DriveFormProps) => {
  const { showToast } = useToastContext();
  const tollRef = useRef<HTMLInputElement>(null);
  const memoRef = useRef<HTMLInputElement>(null);

  const driveFormType = data?.driveHistoryType?.code;
  const [driveForm, setDriveForm] = useState<DriveHistoryForm>({
    driveHistoryType: data?.driveHistoryType?.code,
    transferStartFullLocate: data?.transferStartFullLocate,
    transferStartShortLocate: '',
    transferEndFullLocate: data?.transferEndFullLocate,
    transferEndShortLocate: '',
    transferTransitLocateData: data?.transferTransitLocate,
    transportInfoData: data?.transportInfos,
    toll: data?.toll || null,
    memo: data?.memo || null,
  });

  const [prevForm, setPrevForm] = useState<DriveHistoryList>();
  const [isOpenCalendarPopup, setIsOpenCalendarPopup] = useState(false);
  const [formDate, setFormDate] = useState(
    dayjs(selectedDate || new Date())
      .format('YYYY-MM-DD')
      ?.toString(),
  );
  const [popupInfo, setPopupInfo] = useState<BasicPopupProps>({
    isShow: false,
    textRightBtn: '',
    onClickRightBtn: undefined,
  });
  const [isShowFuelingHistoryForm, setIsShowFuelingHistoryForm] = useState(false);
  const [fuelingHistoryValue, setFuelingHistoryValue] = useState('');

  useEffect(() => {
    if (data) {
      setPrevForm(data);
    } else {
      setPrevForm({ transferStartDate: formDate } as DriveHistoryList);
    }
  }, []);

  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isNumber: boolean) => {
    let { name, value } = e.target;
    const convertedValue = isNumber ? String(value).replace(nonNumericOrDecimalPattern, '') : value;

    setDriveForm({ ...driveForm, [name]: convertedValue } as DriveHistoryForm);
  };

  const postDriveHistoryMutation = useMutation((request: DriveHistoryForm) => postDriveHistory(request), {
    onSuccess: () => {
      showToast('운행일지가 등록되었어요', 'success', 'bottom');

      // 날짜가 변경되는 경우, 일자별 내역을 변경된 날짜의 내역으로 노출하기 위해 분기 처리
      if (prevForm?.transferStartDate == formDate) {
        refetch();
      } else {
        if (updateSelectedDate) {
          updateSelectedDate(new Date(formDate));
        }
      }
      onClosePopup();
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  const patchDriveHistoryMutation = useMutation(
    (request: { id: number; body: DriveHistoryForm }) => patchDriveHistory(request),
    {
      onSuccess: () => {
        showToast('운행일지가 수정되었어요', 'success', 'bottom');
        refetch();
        onClosePopup();
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
    },
  );

  const closePopup = () => {
    setPopupInfo({ ...popupInfo, isShow: false });
  };

  const setPopupInfoForValidForm = (content: string) => {
    setPopupInfo({
      isShow: true,
      textContent: content,
      textRightBtn: '확인',
      onClickRightBtn: closePopup,
    });
  };

  const validateForm = (): boolean => {
    if (driveForm) {
      if (!driveForm.transferStartFullLocate) {
        setPopupInfoForValidForm('상차지를 입력해주세요.');
        return false;
      } else if (!driveForm.transferEndFullLocate) {
        setPopupInfoForValidForm('하차지를 입력해주세요.');
        return false;
      }

      const transportCompanyValidity = driveForm.transportInfoData?.some((item) => {
        return !item.transportCompany;
      });
      if (transportCompanyValidity) {
        setPopupInfoForValidForm('운송사를 입력해주세요.');
        return false;
      }

      const transportCostValidity = driveForm.transportInfoData?.some((item) => {
        return !item.isCancel && !item.transportCost;
      });
      if (transportCostValidity) {
        setPopupInfoForValidForm('운송료를 입력해주세요.');
        return false;
      }
    }
    return true;
  };

  const onClickSaveBtn = () => {
    if (validateForm()) {
      if (data && prevForm) {
        if (patchDriveHistoryMutation.isLoading) {
          return;
        }

        const request: any = {};
        if (dayjs(selectedDate).format('YYYY-MM-DD')?.toString() !== formDate) {
          request.transferStartDate = formDate;
        }

        if (prevForm.toll !== (!!driveForm.toll ? driveForm.toll : 0)) {
          request.toll = !!driveForm.toll ? driveForm.toll : 0;
        }
        if (prevForm.memo !== driveForm.memo) {
          request.memo = driveForm.memo;
        }
        if (prevForm.transferEndFullLocate !== driveForm.transferEndFullLocate) {
          request.transferEndFullLocate = driveForm.transferEndFullLocate;
          request.transferEndShortLocate = driveForm.transferEndShortLocate;
        }
        if (prevForm.transferStartFullLocate !== driveForm.transferStartFullLocate) {
          request.transferStartFullLocate = driveForm.transferStartFullLocate;
          request.transferStartShortLocate = driveForm.transferStartShortLocate;
        }
        if (
          prevForm.transferTransitLocate &&
          !compareArrays(
            prevForm?.transferTransitLocate || [],
            driveForm?.transferTransitLocateData?.filter((transit) => transit.transferTransitFullLocate !== '') || [],
          )
        ) {
          request.transferTransitLocateData = driveForm?.transferTransitLocateData?.filter(
            (transit) => transit.transferTransitFullLocate !== '',
          );
        }
        if (!compareArrays(prevForm?.transportInfos || [], driveForm?.transportInfoData || [])) {
          request.transportInfoData = driveForm?.transportInfoData
            ?.map((transport) => {
              if (!transport.isCancel && transport.transportCompany !== '' && transport.transportCost !== null) {
                return {
                  transportCompany: transport.transportCompany,
                  transportCost: transport.transportCost,
                  transportItem: transport.transportItem,
                  isCancel: transport.isCancel,
                  cancelCost: transport.cancelCost,
                  isReceivedReceipt: transport.isReceivedReceipt,
                  isReceivedCost: transport.isReceivedCost,
                };
              } else if (transport.isCancel && transport.transportCompany !== '') {
                return {
                  transportCompany: transport.transportCompany,
                  transportCost: transport.transportCost,
                  transportItem: transport.transportItem,
                  isCancel: transport.isCancel,
                  cancelCost: transport.cancelCost,
                  isReceivedCost: transport.isReceivedCost,
                };
              } else {
                return null;
              }
            })
            .filter(Boolean);
        }
        if (Object.keys(request).length > 0) {
          patchDriveHistoryMutation.mutate({ id: Number(data.id), body: request });
        } else {
          onClosePopup();
        }
      } else {
        if (postDriveHistoryMutation.isLoading) {
          return;
        }
        const request = {
          transferStartDate: formDate,
          driveHistoryType: DRIVE_HISTORY_TYPE_TRANSPORT,
          driveVehicleInfoId: driveVehicleInfoId,
          toll: driveForm.toll ? Number(driveForm.toll) : null,
          memo: driveForm.memo,
          transferEndFullLocate: driveForm.transferEndFullLocate || '',
          transferEndShortLocate: driveForm.transferEndShortLocate || '',
          transferStartFullLocate: driveForm.transferStartFullLocate || '',
          transferStartShortLocate: driveForm.transferStartShortLocate || '',
          transferTransitLocateData: driveForm.transferTransitLocateData
            ?.filter((transit) => transit.transferTransitFullLocate !== '')
            .map((transit) => ({
              transferTransitShortLocate: transit?.transferTransitShortLocate || '',
              transferTransitFullLocate: transit.transferTransitFullLocate,
            })),

          transportInfoData: driveForm.transportInfoData?.map((transport) => ({
            transportCompany: transport.transportCompany,
            transportCost: Number(transport.transportCost),
            cancelCost: Number(transport.cancelCost),
            transportItem: transport.transportItem || '',
            isCancel: transport.isCancel || false,
            isReceivedCost: transport.isCancel,
            isReceivedReceipt: transport.isReceivedReceipt,
          })),
        };
        postDriveHistoryMutation.mutate(request);
      }
    }
  };

  const onChangeDate = (date: string | undefined) => {
    if (date === null || date === undefined) {
      return;
    } else {
      setFormDate(date);
    }
  };

  const upDateDriveForm = (updateTransferData: DriveHistoryForm) => {
    setDriveForm((prev) => ({
      ...prev,
      ...updateTransferData,
    }));
  };

  const onClickDateSelector = () => {
    setIsOpenCalendarPopup(true);
  };

  const onCloseCalendarPopup = () => {
    setIsOpenCalendarPopup(false);
  };

  const deleteTransportInfoMutation = useMutation((id: number) => deleteTransportInfo(id), {
    onSuccess: () => {
      onClosePopup();
      refetch();
      showToast('내역이 삭제되었어요.', 'success', 'bottom');
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  const onClickDelete = () => {
    setPopupInfo({
      isShow: true,
      title: data?.title,
      textContent: '내역을 삭제하시겠어요?',
      textLeftBtn: '취소',
      onClickLeftBtn: closePopup,
      textRightBtn: '삭제하기',
      onClickRightBtn: handleDeleteConfirmation,
    });
  };

  const handleDeleteConfirmation = () => {
    closePopup();
    if (data?.id) {
      deleteTransportInfoMutation.mutate(data.id);
    }
  };

  const scrollIntoView = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e) {
      const { name } = e.target;
      if (name === 'toll') {
        tollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'memo') {
        memoRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
  };

  useLayoutEffect(() => {
    const detectMobileKeyboard = () => {
      scrollIntoView();
    };
    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  });

  const onClickInputFuelingHistory = () => {
    setIsShowFuelingHistoryForm(true);
  };

  const onCloseFormPopup = () => {
    setIsShowFuelingHistoryForm(false);
  };

  const updateFuelingHistoryValue = (value: string) => {
    setFuelingHistoryValue(value);
  };

  return (
    <>
      <div className="w-full">
        <div className="px-4">
          <dl className="flex justify-center items-center border-b border-gray-3 py-5">
            <dt className="min-w-[100px] text-gray-7">날짜</dt>
            <dd className="flex-1 flex justify-between text-gray-8 px-3" onClick={onClickDateSelector}>
              <SimpleInput name="date" placeholder="날짜 선택" value={formDate} readOnly></SimpleInput>
              <ChevronRightIcon color={colors.gray[6]}></ChevronRightIcon>
            </dd>
          </dl>
        </div>
        <div className="px-4">
          <TransferForm driveForm={driveForm} updateDriveForm={upDateDriveForm}></TransferForm>
        </div>
      </div>

      <TransportForm
        transportInfoData={driveForm.transportInfoData || null}
        updateDriveForm={upDateDriveForm}
        onControlTutorial={onControlTutorial}
      ></TransportForm>
      <div className="border-t-8 border-gray-1 pb-20 h-full">
        <div className="px-4">
          <dl className="flex justify-center items-center border-b border-gray-3 py-5">
            <dt className="min-w-[100px] text-gray-7">주유비</dt>
            <dd
              className="flex-1 flex items-center justify-between text-gray-8 px-3"
              onClick={onClickInputFuelingHistory}
            >
              <SimpleInput name="fueling" placeholder="주유비 추가" value={fuelingHistoryValue} readOnly></SimpleInput>
              <ChevronRightIcon color={colors.gray[6]}></ChevronRightIcon>
            </dd>
          </dl>
          {driveFormType !== DRIVE_HISTORY_TYPE_EMPTY_TRANSPORT && (
            <dl className="flex justify-center items-center border-b border-gray-3 py-5">
              <dt className="min-w-[100px] text-gray-7">
                <span className="align-middle">통행료</span>
                <TooltipComponent
                  title=""
                  content="통행료는 선택 사항이지만 가능하면 필수로 작성하는 게 좋습니다. 편의를 위해 하이패스 사용자는 해당월 마지막 일정에 총 금액을 넣는 방법도 있습니다. 총 금액은 하이패스 공식 사이트(https://www.hipass.co.kr/)에서 확인하실 수 있습니다."
                  alignmentTipbox="left"
                />
              </dt>
              <dd className="flex-1 flex justify-between text-gray-8 px-3">
                <SimpleInput
                  ref={tollRef}
                  placeholder="통행료 입력"
                  name="toll"
                  value={driveForm?.toll ? formatNumber(String(driveForm?.toll)) : ''}
                  onChange={(e) => onChangeFormInput(e, true)}
                  onFocus={(e) => scrollIntoView(e)}
                  inputMode="numeric"
                ></SimpleInput>
                <span className="text-gray-8">원</span>
              </dd>
            </dl>
          )}

          <dl className="flex justify-center items-center border-b border-gray-3 py-5">
            <dt className="min-w-[100px] text-gray-7">메모</dt>
            <dd className="flex-1 text-gray-8 px-3">
              <NoneStyleMultilineTextArea
                ref={memoRef}
                name="memo"
                placeholder="메모 입력"
                value={driveForm?.memo || ''}
                onChange={(e) => onChangeFormInput(e, false)}
                onFocus={(e) => scrollIntoView(e)}
              />
            </dd>
          </dl>
        </div>
      </div>
      <div className="flex items-center justify-center absolute w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 max-w-[720px] min-w-[280px] bg-white z-40">
        <div
          className={`absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]`}
        ></div>
        {data?.id && (
          <div className="w-1/4 min-w-[80px] mr-2">
            <BasicButton
              name={`삭제`}
              bgColor={colors.gray[0]}
              borderColor={colors.gray[3]}
              textColor={colors.gray[6]}
              fontSize={16}
              height={48}
              fontWeight="bold"
              onClick={() => onClickDelete()}
            ></BasicButton>
          </div>
        )}
        <div className="w-full min-w-[240px]">
          <BasicButton
            name="저장"
            bgColor={colors.primary}
            borderColor={colors.primary}
            textColor={colors.gray[0]}
            fontSize={16}
            height={48}
            fontWeight="bold"
            onClick={() => onClickSaveBtn()}
          ></BasicButton>
        </div>
      </div>
      <CalendarPopup
        selectedDate={formDate}
        onChangeSelectedDate={onChangeDate}
        isShow={isOpenCalendarPopup}
        onClose={onCloseCalendarPopup}
        textLeftBtn="닫기"
        onClickLeftBtn={onCloseCalendarPopup}
      />

      <BasicPopup
        isShow={popupInfo.isShow}
        title={popupInfo?.title}
        textContent={popupInfo.textContent}
        textLeftBtn={popupInfo.textLeftBtn}
        onClickLeftBtn={popupInfo.onClickLeftBtn}
        textRightBtn={popupInfo.textRightBtn}
        onClickRightBtn={popupInfo.onClickRightBtn}
      ></BasicPopup>
      <DownToUpDetailPopup
        isShow={isShowFuelingHistoryForm}
        onClosePopup={onCloseFormPopup}
        title={'주유비 추가'}
        isHeightFull
        isBackdrop={false}
      >
        <FuelingHistoryForm
          onClosePopup={onCloseFormPopup}
          selectedDate={selectedDate}
          driveVehicleInfoId={driveVehicleInfoId}
          refetch={refetch}
          updateFuelingHistoryValue={updateFuelingHistoryValue}
        ></FuelingHistoryForm>
      </DownToUpDetailPopup>
    </>
  );
};

export default DriveForm;
