import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import RoundBadge from '@/components/Common/Badge/RoundBadge';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import RadioList, { OptionType } from '@/components/Common/Input/Radio/RadioList';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import { ChevronDownIcon } from '@/components/Icon';
import ConfirmExitPopup from '@/components/Products/ConfirmExitPopup';
import { colors } from '@/const/colors';
import { ETC, SALESTYPE } from '@/const/products';
import { SELL_CAR_DEFAULT_TITLE } from '@/const/title';
import { useProductEnumContext } from '@/contexts/Products/ProductEnumContext';
import useUpdateProductFormMutation from '@/hooks/query/useUpdateProductFormMutation';
import useBlockNavigation from '@/hooks/useBlockNavigation';
import useExitConfirmationPopup from '@/hooks/useExitConfirmationPopup';
import { productsFormAtom, useResetProductsForm } from '@/store/products';

const keyValueList: KeyValueListType = {
  model: '모델',
  manufacturerCategories: '제조사',
};

type SalesTypeKey = keyof typeof SALESTYPE;

function ModelForm() {
  const { productEnum } = useProductEnumContext();
  const { id } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const productOriginData = location?.state?.productOriginData;

  const [productFormData] = useAtom(productsFormAtom);
  const setProductFormData = useSetAtom(productsFormAtom);
  const resetProductsForm = useResetProductsForm();

  const [prevData, setPrevData] = useState<ProductDetailResponse | null>(null);

  const [title, setTitle] = useState('');
  const [isOpenOptionModal, setIsOpenOptionModal] = useState(false);
  const [optionData, setOptionData] = useState<OptionType[]>([]);

  const checkForChanges = () => {
    return (
      productOriginData?.model?.id !== productFormData?.model?.id || prevData?.model?.id !== productFormData?.model?.id
    );
  };

  const saveChanges = () => {
    const request = { id: Number(productFormData?.id), modelId: Number(productFormData?.model?.id) };
    updateProductTemporarySaveMutation.mutate(request);
  };

  const { isShowExitConfirmPopup, openExitConfirmPopup, closeExitConfirmPopup, confirmExit } = useExitConfirmationPopup(
    checkForChanges,
    saveChanges,
    resetProductsForm,
  );

  useBlockNavigation(openExitConfirmPopup);

  useEffect(() => {
    setPrevData(productFormData);
  }, []);

  useEffect(() => {
    if (!productOriginData) {
      apiManager
        .get('/api/v1/products/' + id)
        .then((response) => {
          const responseData: ProductDetailResponse = response.data;
          setPrevData(responseData);
          if (responseData.manufacturerCategories.code === ETC) {
            setProductFormData({ ...responseData, model: { id: 45, name: '기타(쌍용 외)' } });
          } else {
            setProductFormData(responseData);
          }
        })
        .catch(() => {});
    }
  }, []);

  const isEtcManufacturerCategories =
    productOriginData?.manufacturerCategories?.code === ETC || prevData?.manufacturerCategories?.code === ETC;

  const updateProductTemporarySaveMutation = useUpdateProductFormMutation({});

  const updateProductNextStepMutation = useUpdateProductFormMutation({
    url: `/products/sales/tons/${id}`,
  });

  const handleClickNext = () => {
    const hasChanges = checkForChanges();

    if (hasChanges || isEtcManufacturerCategories) {
      const request = { id: Number(productFormData?.id), modelId: Number(productFormData?.model?.id) };
      updateProductNextStepMutation.mutate(request);
    } else {
      navigate(`/products/sales/tons/${id}`, { replace: true });
    }
  };

  const handleClickPrev = () => {
    navigate(`/products/sales/info/${id}`, {
      state: { productOriginData: productOriginData || prevData },
      replace: true,
    });
  };

  const getFilterManufacturerCategories = () => {
    if (productFormData?.manufacturerCategories && productEnum) {
      const manufacturerCategories = productEnum['manufacturerAndModel'].map((item) => item.manufacturerCategories);
      return manufacturerCategories;
    }
    return [];
  };

  const getFilteredModels = () => {
    if (productFormData?.manufacturerCategories && productEnum) {
      const models = productEnum['manufacturerAndModel']
        .filter((item) => item.manufacturerCategories.id === Number(productFormData.manufacturerCategories?.id))
        .map((item) => item.model);
      return models;
    }
    return [];
  };

  const handleClickSelectBox = (key: string) => {
    setTitle(key);
    if (!productEnum) return;

    if (!productFormData?.manufacturerCategories) return;
    if (key === 'model') {
      if (!productFormData?.manufacturerCategories) return;
      const filteredModels = getFilteredModels();
      if (filteredModels.length > 0) {
        const flattenedArr = filteredModels.reduce<Model[]>((acc, val) => acc.concat(val), []);
        const option = flattenedArr.map((item, index) => {
          return { code: String(item.id), desc: item.name };
        });
        setOptionData(option);
      }
    } else if (key === 'manufacturerCategories') {
      if (!isEtcManufacturerCategories) return;
      const filteredManufacturerCategories = getFilterManufacturerCategories();

      if (filteredManufacturerCategories.length > 0) {
        const flattenedArr = filteredManufacturerCategories.reduce<Model[]>((acc, val) => acc.concat(val), []);
        const option = flattenedArr.map((item, index) => {
          return { code: String(item.id), desc: item.name };
        });
        setOptionData(option);
      }
    }

    setIsOpenOptionModal(true);
  };

  const handleSelectOption = (item: OptionType, key: string) => {
    let value = { id: Number(item.code), name: item.desc };

    if (key === 'manufacturerCategories') {
      setProductFormData({
        ...productFormData,
        manufacturerCategories: value,
        model: {},
      } as ProductDetailResponse);
    } else if (key === 'model') {
      setProductFormData({
        ...productFormData,
        model: value,
      } as ProductDetailResponse);
    }

    setTimeout(() => {
      setIsOpenOptionModal(false);
    }, 200);
  };

  return (
    <>
      <MenuHeader
        title={
          SALESTYPE[productOriginData?.type?.code as SalesTypeKey] ||
          SALESTYPE[productFormData.type?.code as SalesTypeKey] ||
          SELL_CAR_DEFAULT_TITLE
        }
      />
      <div className="flex flex-col w-full pt-[60px] p-4">
        <div className="pt-6 text-gray-8">
          <div className="flex justify-between">
            <h3 className="text-2xl font-bold">
              제조사와 모델을
              <br />
              선택해주세요.
            </h3>
            <RoundBadge text="1/9"></RoundBadge>
          </div>

          <div className="flex flex-col gap-[30px] text-base pt-[30px] pb-24">
            {isEtcManufacturerCategories && (
              <div className="py-5 px-4 rounded-[10px] bg-gray-1 text-red font-medium text-sm xxs:text-xs leading-[18px]">
                * 기타(쌍용 외) 제조사에서 타 제조사로 변경 시 추후 수정이 불가능하니 유의해주세요.
              </div>
            )}

            <div onClick={() => handleClickSelectBox('manufacturerCategories')}>
              <TextInputLabelUp
                name="manufacturerCategories"
                label="제조사"
                placeholder="제조사 선택"
                value={productFormData?.manufacturerCategories?.name}
                type="text"
                disabled={!isEtcManufacturerCategories}
                suffix={isEtcManufacturerCategories && <ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
                readOnly
              />
            </div>

            <div onClick={() => handleClickSelectBox('model')}>
              <TextInputLabelUp
                name="model"
                label="모델"
                placeholder="모델 선택"
                value={productFormData?.model?.name || ''}
                type="text"
                readOnly
                suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
              />
            </div>
          </div>
        </div>
        <DualFooterButton
          leftButtonText="이전"
          onClickLeftButton={handleClickPrev}
          rightButtonText="다음(상세정보)"
          onClickRightButton={handleClickNext}
          disabledRight={!productFormData?.model?.name}
        />
      </div>
      <DownToUpDetailPopup
        isShow={isOpenOptionModal}
        onClosePopup={() => setIsOpenOptionModal(false)}
        title={keyValueList?.[title]}
      >
        <div className="px-4 pb-8">
          <RadioList
            name={title}
            list={optionData}
            value={
              title === 'model'
                ? String(productFormData?.model?.id)
                : String(productFormData?.manufacturerCategories?.id) || ''
            }
            onChange={(val) => {
              handleSelectOption(val, title);
            }}
            horizontal={false}
          ></RadioList>
        </div>
      </DownToUpDetailPopup>
      <ConfirmExitPopup isShow={isShowExitConfirmPopup} onClose={closeExitConfirmPopup} onConfirm={confirmExit} />
    </>
  );
}

export default ModelForm;
