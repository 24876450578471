import React from 'react';

import { PhoneIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { REPRESENTATIVE_NUMBER } from '@/const/common';
import { phoneCall } from '@/utils/common';
import { Fab } from '@mui/material';

const PhoneCallFloationgButton = () => {
  return (
    <>
      <Fab
        sx={{
          backgroundColor: colors.gray[0],
          width: 52,
          height: 52,
          borderRadius: '50px',
          '&:hover': {
            backgroundColor: colors.gray[0],
          },
          zIndex: 100,
        }}
        onClick={() => phoneCall(REPRESENTATIVE_NUMBER)}
      >
        <PhoneIcon color={colors.primary} />
      </Fab>
    </>
  );
};

export default PhoneCallFloationgButton;
