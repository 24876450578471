import dayjs from 'dayjs';
import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import CategorySelectModal from './CategorySelectModal';
import { createOtherExpensesHistory } from '@/api/drive/createDrive';
import { deleteOtherExpensesHistory } from '@/api/drive/deleteDrive';
import { updateOtherExpensesHistory } from '@/api/drive/updateDrive';
import BasicButton from '@/components/Common/Button/BasicButton';
import SwitchMultiButton from '@/components/Common/Button/SwitchMultiButton';
import NoneStyleMultilineTextArea from '@/components/Common/Input/NoneStyleMultilineTextArea';
import SimpleInput from '@/components/Common/Input/SimpleInput';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import CalendarPopup from '@/components/Common/Popup/CalendarPopup';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import { MenuHeaderIcon } from '@/components/Header/MenuHeader';
import { ChevronRightIcon, XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { EXPENSE, EXPENSE_UNCLASSIFIED, INCOME, INCOME_UNCLASSIFIED } from '@/const/drive';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { categoriesAtom, fetchCategoriesAtom } from '@/store/category';
import { otherExpenseHistorySearchParamsAtom } from '@/store/otherExpenseHistory';
import { formatNumber } from '@/utils/common';

interface DriveFormProps {
  selectedDate: Date;
  driveVehicleInfoId: number;
  data?: OtherExpenseHistoryItem;
  onClosePopup: () => void;
  refetch: () => void;
  onControlTutorial?: () => void;
  updateSelectedDate?: (date: Date) => void;
}

const OtherExpenseHistoryForm = ({
  selectedDate,
  updateSelectedDate,
  driveVehicleInfoId,
  data,
  onClosePopup,
  refetch,
  onControlTutorial,
}: DriveFormProps) => {
  const { showToast } = useToastContext();
  const queryClient = useQueryClient();

  const [categoryList] = useAtom(categoriesAtom);
  const [, fetchCategories] = useAtom(fetchCategoriesAtom);

  const [searchParams] = useAtom(otherExpenseHistorySearchParamsAtom);
  const setSearchParams = useSetAtom(otherExpenseHistorySearchParamsAtom);

  useEffect(() => {
    const params = new URLSearchParams({
      driveVehicleInfoId: String(driveVehicleInfoId),
    });

    if (categoryList.length < 1) {
      fetchCategories(params);
    }
  }, []);

  useEffect(() => {
    if (data) {
      const categoryType =
        data.otherExpensesCategoryType.code === EXPENSE || data.otherExpensesCategoryType.code === EXPENSE_UNCLASSIFIED
          ? EXPENSE
          : INCOME;

      setCategoryType(categoryType);

      const categoryObj = {
        id: data.otherExpensesCategoryId,
        name:
          data.otherExpensesCategoryType.code === EXPENSE_UNCLASSIFIED ||
          data.otherExpensesCategoryType.code === INCOME_UNCLASSIFIED
            ? ''
            : data.categoryName,
        type: data.otherExpensesCategoryType,
      };
      setSelectedCategory(categoryObj);
    }
  }, [data]);

  const [isEditMode, setIsEditMode] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState<OtherExpensesCategory | null>(null);

  const popupTitle = {
    EXPENSE: '지출',
    INCOME: '수익',
  };

  const [otherExpenseForm, setOtherExpenseForm] = useState<OtherExpenseForm>({
    baseDay: '',
    contents: data?.contents || '',
    otherExpensesHistoryId: data?.otherExpensesHistoryId || undefined,
    driveVehicleInfoId: driveVehicleInfoId,
    otherExpensesCategoryId: 0,
    price: data?.price || 0,
  });

  const [isShowCategoryPopup, setIsShowCategoryPopup] = useState(false);

  const [isOpenCalendarPopup, setIsOpenCalendarPopup] = useState(false);
  const [formDate, setFormDate] = useState(
    dayjs(selectedDate || new Date())
      .format('YYYY-MM-DD')
      ?.toString(),
  );
  const [popupInfo, setPopupInfo] = useState<BasicPopupProps>({
    isShow: false,
    textRightBtn: '',
    onClickRightBtn: undefined,
  });
  const [categoryType, setCategoryType] = useState<string>(data?.otherExpensesCategoryType?.code || EXPENSE);

  const postOtherExpensesHistoryMutation = useMutation(
    (request: OtherExpensesHistoryRequest) => createOtherExpensesHistory(request),
    {
      onSuccess: () => {
        showToast('기타내역이 추가되었어요.', 'success', 'bottom');
        onClosePopup();

        refetch();
        queryClient.invalidateQueries({ queryKey: ['get-drive-info'] });

        if (updateSelectedDate) {
          updateSelectedDate(new Date(formDate));
        }
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
    },
  );

  const patchOtherExpensesHistoryMutation = useMutation(
    (request: { id: number; formData: OtherExpensesHistoryUpdateRequest }) => updateOtherExpensesHistory(request),
    {
      onSuccess: () => {
        showToast('기타내역이 수정되었어요.', 'success', 'bottom');
        onClosePopup();
        queryClient.invalidateQueries({ queryKey: ['get-drive-info'] });

        refetch();

        if (updateSelectedDate) {
          updateSelectedDate(new Date(formDate));
        }
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
    },
  );

  const closePopup = () => {
    setPopupInfo({ ...popupInfo, isShow: false });
  };

  const onClickSaveBtn = (otherExpensesHistoryId?: number | undefined) => {
    if (otherExpensesHistoryId) {
      const unclassifiedType = categoryType === INCOME ? INCOME_UNCLASSIFIED : EXPENSE_UNCLASSIFIED;

      const request = {
        baseDay: formDate,
        contents: otherExpenseForm.contents,
        otherExpensesCategoryId: !!selectedCategory?.id
          ? selectedCategory?.id
          : categoryList.find((item) => item.type.code === unclassifiedType)?.id || 0,
        price: otherExpenseForm.price || 0,
      };

      patchOtherExpensesHistoryMutation.mutate({ id: otherExpensesHistoryId, formData: request });
    } else {
      const unclassifiedType = categoryType === INCOME ? INCOME_UNCLASSIFIED : EXPENSE_UNCLASSIFIED;

      const request: OtherExpensesHistoryRequest = {
        baseDay: formDate,
        contents: otherExpenseForm.contents,
        driveVehicleInfoId: driveVehicleInfoId,
        otherExpensesCategoryId: !!otherExpenseForm.otherExpensesCategoryId
          ? otherExpenseForm.otherExpensesCategoryId
          : categoryList.find((item) => item.type.code === unclassifiedType)?.id || 0,
        price: otherExpenseForm.price || 0,
      };

      postOtherExpensesHistoryMutation.mutate(request);
    }
  };

  const onChangeDate = (date: string | undefined) => {
    if (date === null || date === undefined) {
      return;
    } else {
      setFormDate(date);
    }
  };

  const onClickDateSelector = () => {
    setIsOpenCalendarPopup(true);
  };

  const onCloseCalendarPopup = () => {
    setIsOpenCalendarPopup(false);
  };

  const deleteOtherExpensesHistoryMutation = useMutation((id: number[]) => deleteOtherExpensesHistory(id), {
    onSuccess: () => {
      showToast('기타내역이 삭제되었어요.', 'success', 'bottom');
      onClosePopup();
      setIsEditMode(false);
      refetch();
      queryClient.invalidateQueries({ queryKey: ['get-drive-info'] });
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  const onClickDelete = () => {
    setPopupInfo({
      isShow: true,
      title: '',
      textContent: '기타 내역을 삭제할까요?',
      textLeftBtn: '취소',
      onClickLeftBtn: closePopup,
      textRightBtn: '삭제',
      onClickRightBtn: handleDeleteConfirmation,
    });
  };

  const handleDeleteConfirmation = () => {
    closePopup();
    if (otherExpenseForm?.otherExpensesHistoryId) {
      deleteOtherExpensesHistoryMutation.mutate([otherExpenseForm?.otherExpensesHistoryId]);
    }
  };

  const onCloseCategoryPopup = () => {
    setIsShowCategoryPopup(false);
  };

  const onChangeBtnValue = (type: CategoryType) => {
    setCategoryType(type);
    setSelectedCategory(null);
  };

  const handleChangeFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'contents') {
      if (value.length > 120) {
        return;
      } else {
        setOtherExpenseForm((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      }
    } else if (name === 'price') {
      const nonNumericOrDecimalPattern = /[^0-9.]/g;
      const convertedValue = value.replace(nonNumericOrDecimalPattern, '');

      if (convertedValue.startsWith('.')) return;

      setOtherExpenseForm((prev) => {
        return {
          ...prev,
          [name]: Number(convertedValue),
        };
      });
    } else {
      setOtherExpenseForm((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const onClickTour = () => {
    if (localStorage.getItem('driveTutorial') === 'true' && onControlTutorial) {
      setTimeout(() => {
        onControlTutorial();
      }, 500);
      setIsShowCategoryPopup(true);
      setIsEditMode(false);
    }
  };

  const handleClickCategory = () => {
    setIsShowCategoryPopup(true);
    setIsEditMode(false);
  };

  const handleChangeMode = () => {
    setIsEditMode(!isEditMode);

    if (localStorage.getItem('driveTutorial') === 'true' && onControlTutorial) {
      onControlTutorial();
    }
  };

  const menuHeaderIcons: MenuHeaderIcon[] = [
    {
      icon:
        categoryList.filter((item) => item.type.code === categoryType).length === 0 ? (
          ''
        ) : isEditMode ? (
          <>닫기</>
        ) : (
          <span className="categoryEditTutorial">편집</span>
        ),
      onClickIcon: handleChangeMode,
    },
  ];

  const updateSelectedCategoryValue = (id: number) => {
    const selectedItem = categoryList.find((item) => item.id === id);
    setSelectedCategory(selectedItem as OtherExpensesCategory);
    setOtherExpenseForm((prev) => {
      return {
        ...prev,
        otherExpensesCategoryId: Number(id),
      };
    });
  };

  const handleDeleteSelectedCategory = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setSelectedCategory(null);
  };

  return (
    <>
      <div className="w-full pb-24">
        <div className="px-4">
          <dl className="flex justify-center items-center border-b border-gray-3 py-5">
            <dt className="min-w-[100px] text-gray-7">날짜</dt>
            <dd className="flex-1 flex justify-between text-gray-8 px-3" onClick={onClickDateSelector}>
              <SimpleInput name="date" placeholder="날짜 선택" value={formDate} readOnly></SimpleInput>
              <ChevronRightIcon color={colors.gray[6]} />
            </dd>
          </dl>
        </div>
        <div className="text-base px-4 pb-2 border-gray-1">
          <div className="cateogryManageTutorial" onClick={onClickTour}>
            <dl className="border-b border-gray-3 py-5">
              <div className="flex justify-center items-center">
                <dt className="min-w-[100px] text-gray-7">분류</dt>
                <dd className="flex-1 flex items-center gap-4 text-gray-8 px-3">
                  <SwitchMultiButton
                    className="drive-form-type"
                    type="checkBox"
                    buttonInfo={[
                      {
                        title: '지출',
                        isClicked: categoryType === EXPENSE,
                        borderColor: 'border-primary',
                        textColor: colors.primary,
                        fontWeight: 'normal',
                        height: 35,
                        onClickBtn: () => onChangeBtnValue(EXPENSE),
                      },
                      {
                        title: '수익',
                        isClicked: categoryType === INCOME,
                        borderColor: 'border-blue-4',
                        textColor: colors.blue[4],
                        fontWeight: 'normal',
                        height: 35,
                        onClickBtn: () => onChangeBtnValue(INCOME),
                      },
                    ]}
                  ></SwitchMultiButton>
                </dd>
              </div>
            </dl>

            <dl className="flex justify-center items-center border-b border-gray-3 py-5">
              <dt className="min-w-[100px] text-gray-7">카테고리</dt>
              <dd className="flex-1 flex justify-between text-gray-8 px-3" onClick={handleClickCategory}>
                <SimpleInput
                  placeholder="카테고리 선택"
                  name="category"
                  value={selectedCategory?.name || ''}
                  readOnly
                />
                {selectedCategory?.name ? (
                  <XMarkIcon
                    color={colors.gray[6]}
                    onClick={(e) => {
                      handleDeleteSelectedCategory(e);
                    }}
                  />
                ) : (
                  <ChevronRightIcon color={colors.gray[6]} />
                )}
              </dd>
            </dl>
          </div>

          <dl className="flex justify-center items-center border-b border-gray-3 py-5">
            <dt className="min-w-[100px] text-gray-7">내용</dt>
            <dd className="flex-1 text-gray-8 px-3">
              <NoneStyleMultilineTextArea
                placeholder="내용 입력"
                name="contents"
                value={otherExpenseForm?.contents || ''}
                onChange={(e) => handleChangeFormData(e)}
              />
            </dd>
          </dl>
          <dl className={`flex justify-center items-center border-b border-gray-3 py-5`}>
            <dt className="min-w-[100px] text-gray-7">
              금액<span className="font-normal text-red ml-1">(필수)</span>
            </dt>
            <dd className="flex-1 flex justify-between text-gray-8 px-3">
              <SimpleInput
                name={'price'}
                placeholder={'금액 입력'}
                value={otherExpenseForm.price ? formatNumber(otherExpenseForm.price, true) : ''}
                onChange={(e) => handleChangeFormData(e)}
                inputMode="numeric"
                type="text"
                maxLength={11}
              />
              <span className="text-gray-8">원</span>
            </dd>
          </dl>
        </div>
      </div>

      <div className="flex items-center justify-center absolute w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 max-w-[720px] min-w-[280px] bg-white z-40">
        <div
          className={`absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]`}
        ></div>
        {otherExpenseForm?.otherExpensesHistoryId && (
          <div className="w-1/4 min-w-[80px] mr-2">
            <BasicButton
              name={`삭제`}
              bgColor={colors.gray[0]}
              borderColor={colors.gray[3]}
              textColor={colors.gray[6]}
              fontSize={16}
              height={48}
              fontWeight="bold"
              onClick={() => onClickDelete()}
            ></BasicButton>
          </div>
        )}
        <div className="w-full min-w-[240px]">
          <BasicButton
            name="저장"
            bgColor={colors.primary}
            borderColor={colors.primary}
            textColor={colors.gray[0]}
            fontSize={16}
            height={48}
            fontWeight="bold"
            isDisabled={!otherExpenseForm?.price}
            onClick={() => onClickSaveBtn(otherExpenseForm?.otherExpensesHistoryId)}
          ></BasicButton>
        </div>
      </div>
      <CalendarPopup
        selectedDate={formDate}
        onChangeSelectedDate={onChangeDate}
        isShow={isOpenCalendarPopup}
        onClose={onCloseCalendarPopup}
        textLeftBtn="닫기"
        onClickLeftBtn={onCloseCalendarPopup}
      />

      <BasicPopup
        isShow={popupInfo.isShow}
        title={popupInfo?.title}
        textContent={popupInfo.textContent}
        textLeftBtn={popupInfo.textLeftBtn}
        onClickLeftBtn={popupInfo.onClickLeftBtn}
        textRightBtn={popupInfo.textRightBtn}
        onClickRightBtn={popupInfo.onClickRightBtn}
      ></BasicPopup>
      <DownToUpDetailPopup
        isShow={isShowCategoryPopup}
        onClosePopup={onCloseCategoryPopup}
        title={`${popupTitle[categoryType as CategoryType]} 카테고리`}
        isHeightFull
        isBackdrop={false}
        isShowXMark={false}
        icons={menuHeaderIcons}
        isShowPrevBtn={true}
      >
        <CategorySelectModal
          type={categoryType}
          isEditMode={isEditMode}
          onClosePopup={onCloseCategoryPopup}
          driveVehicleInfoId={driveVehicleInfoId}
          selectedCategoryId={selectedCategory?.id || 0}
          updateSelectedCategoryId={updateSelectedCategoryValue}
          refetch={refetch}
        />
      </DownToUpDetailPopup>
    </>
  );
};

export default OtherExpenseHistoryForm;
