import React, { forwardRef } from 'react';

import { CarPaymentColorIcon, CautionColorIcon, NameTransferColorIcon } from '@/components/Icon';

const TransferGuidePopup = forwardRef<HTMLDivElement>(({}, ref) => {
  return (
    <div ref={ref} className="flex flex-col justify-center items-center pb-[100px]">
      <div className="flex flex-col justify-center items-center">
        <p className="text-gray-8 text-center pb-5 text-pretty mx-7 break-keep text-[16px]">
          <span className="font-bold">
            차량 명의 이전에 필요한 <br />
            준비 서류, 차량 대금 처리, 거래 시 유의 사항{' '}
          </span>
          등<br /> 화물차 직거래 매칭 시 고객님들의
          <br />
          안전한 거래를 위해 도움을 드리는
          <br />
          서비스입니다.
        </p>
        <div className="flex flex-wrap justify-center gap-3 mt-[14px]">
          <div className="flex flex-col items-center justify-center text-center rounded-[26px] w-[90px] h-[90px] text-gray-8 bg-gray-1 font-semibold text-[14px] leading-[18px]">
            <NameTransferColorIcon width={30} height={30}></NameTransferColorIcon>
            <div>
              명의이전
              <br />
              준비서류
            </div>
          </div>
          <div className="flex flex-col items-center justify-center text-center rounded-[26px] w-[90px] h-[90px] text-gray-8 bg-gray-1 font-semibold text-[14px] leading-[18px]">
            <CarPaymentColorIcon width={36} height={36}></CarPaymentColorIcon>
            <div>
              차량대금
              <br />
              처리
            </div>
          </div>
          <div className="flex flex-col items-center justify-center text-center rounded-[26px] w-[90px] h-[90px] text-gray-8 bg-gray-1 font-semibold text-[14px] leading-[18px]">
            <CautionColorIcon width={33} height={33}></CautionColorIcon>
            <div>
              거래시
              <br />
              유의사항
            </div>
          </div>
        </div>
        <div className="text-gray-6 text-[12px] mt-[46px] mb-[30px]">※ 해당 서비스는 유료 서비스입니다.</div>
      </div>

      <div className="bg-gray-1 py-[46px] px-4 text-gray-8 text-center text-pretty break-keep w-full">
        명의 이전에 필요한 서류 및 절차는
        <br />
        법인명의 영업용번호판,
        <br />
        개인명의 영업용번호판에 따라 상이하므로 <br className="xxs:hidden" />
        서비스 신청 후 별도의 안내를 드릴 예정입니다.
      </div>
    </div>
  );
});

export default TransferGuidePopup;
