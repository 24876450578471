import React from 'react';
import { Link } from 'react-router-dom';

interface SubCardsProps {
  cards: ButtonCard[];
}

export default function SubCards({ cards }: SubCardsProps) {
  const handleSaveEntryPoint = () => {
    sessionStorage.setItem('entryPoint', location.pathname);
  };

  return (
    <>
      <div
        className={`grid grid-cols-${
          cards.length
        } bg-gray-0 shadow-[0_0_15px_0_rgba(0,0,0,0.12)] mx-4 rounded-[12px] sm280:bg-transparent sm280:shadow-none ${
          cards.length == 4 ? 'sm280:grid-cols-2' : ''
        } sm280:gap-2 `}
      >
        {cards.map((item, index) => (
          <div key={index} className="flex justify-center items-center relative">
            {index > 0 && (
              <div className="sm280:hidden absolute left-0 right-0 top-1/2 transform -translate-y-1/2 w-[1px] h-[36px] bg-gray-3"></div>
            )}
            <Link
              to={item.path}
              className={`flex py-4 sm280:py-[7px] px-3 items-center justify-center relative sm280:bg-gray-0 rounded-[12px] w-full sm280:shadow-[0_0_15px_0_rgba(0,0,0,0.12)]`}
              onClick={handleSaveEntryPoint}
            >
              <div className="flex flex-col sm280:flex-row sm280:flex-row-reverse sm280:justify-between justify-center items-center w-full">
                <div className="flex justify-center items-center w-[40px] h-[40px]">{item.icon}</div>
                <span className="font-semibold text-gray-8 text-[12px] break-keep text-center sm680:text-[15px]">
                  {item.title}
                </span>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}
