import React, { useState } from 'react';
import { useMutation } from 'react-query';

import FilledBadge from '../Common/Badge/FilledBadge';
import TransportInfo from './TransportInfo';
import { patchTransportInfo } from '@/api/public';
import { ChevronRightIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { performance } from '@/firebase/firebase';
import { formatDistanceToKm } from '@/utils/common';
import { trace } from '@firebase/performance';

export interface DriveItemViewProps {
  driveVehicleInfoId: number;
  data: DriveHistoryList;
  isEmpty: boolean;
  badgeLabel?: string;
  badgeTextColor?: string;
  badgeBorderColor?: string;
  detailContents: TransportInfos[];
  tagList?: OptionDataType[];
  refetch: () => void;
  onClickItem: (data?: DriveHistoryList) => void;
  onControlTutorial?: () => void;
}

interface GrayBadgeProps {
  title: string;
  value: string;
  textColor: string;
  textWeight: 'normal' | 'medium' | 'semibold' | 'bold';
}

const GrayBadge = ({ title, value, textColor, textWeight }: GrayBadgeProps) => {
  return (
    <div className="flex justify-center items-center py-1 px-2 mr-[6px] rounded-lg bg-gray-1 text-base text-gray-1 h-6 min-w-[40px]">
      <span className={`text-gray-7 text-[13px] ${title ? 'mr-1' : ''}`}>{title}</span>
      <span className={`text-${textColor} text-[13px] font-${textWeight}`}>{value}</span>
    </div>
  );
};

const DriveItemView = ({
  driveVehicleInfoId,
  data,
  isEmpty,
  tagList,
  refetch,
  onClickItem,
  onControlTutorial,
}: DriveItemViewProps) => {
  const { showToast } = useToastContext();
  const [isLoadingBtn, setIsLoadingBtn] = useState<{ [key: number]: boolean }>({});
  const t = trace(performance, 'PATCH_TRANSPORT_INFO');
  const patchTransportInfoMutation = useMutation(
    (request: { id: string; body: TransportInfoRequest }) => patchTransportInfo(request),
    {
      onMutate: (request) => {
        setIsLoadingBtn({ [request?.id]: true });
        t.start();
        return t;
      },
      onSuccess: (response) => {
        if (response) {
          t.stop();
          refetch();
          showToast(`${response.data.isReceivedCost ? '수금완료' : '미수금으'}로 변경되었어요.`, 'success', 'bottom');
        }
      },
      onError: () => {
        t.stop();
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
      onSettled: (response) => {
        setIsLoadingBtn({ [response?.data?.id]: false });
      },
    },
  );

  const onClickCheck = (e: React.MouseEvent<HTMLDivElement>, info: TransportInfos) => {
    e.stopPropagation();
    if (localStorage.getItem('driveTutorial') === 'true' && onControlTutorial) {
      onControlTutorial();
    } else {
      if (driveVehicleInfoId) {
        const request = {
          id: String(info.id),
          body: {
            driveVehicleInfoId: driveVehicleInfoId,
            isReceivedCost: !info.isReceivedCost,
          },
        };

        patchTransportInfoMutation.mutate(request);
      }
    }
  };

  return (
    <>
      <div className="pb-[10px]" onClick={() => onClickItem(data)}>
        <div className="flex justify-between items-center pt-3">
          <div className="flex items-center">
            <span className="text-gray-8 font-semibold mr-[6px] text-lg line-clamp-2">{data.title}</span>
            {isEmpty && (
              <div className="pr-[6px]">
                <FilledBadge
                  text="공차"
                  textColor="text-primary"
                  bgColor="bg-gray-1"
                  fontWeight="font-semibold"
                  rounded="rounded-lg"
                ></FilledBadge>
              </div>
            )}
            {data.distance > 0 && (
              <div className="pr-[6px]">
                <FilledBadge
                  text={`${formatDistanceToKm(String(data.distance), 1)}km`}
                  textColor="text-gray-8"
                  bgColor="bg-gray-1"
                  fontWeight="font-semibold"
                  rounded="rounded-lg"
                ></FilledBadge>
              </div>
            )}
            <ChevronRightIcon color={colors.gray[7]} width={20} height={20}></ChevronRightIcon>
          </div>
        </div>
        {data.transportInfos?.map((info, index) => (
          <div key={index} className="pt-[6px]">
            <TransportInfo
              isTutorial={localStorage.getItem('driveTutorial') === 'true'}
              transportInfo={info}
              onClickPatchOutstandingAmount={onClickCheck}
              isLoadingBtn={isLoadingBtn}
            />
          </div>
        ))}
        {tagList && tagList.length > 0 && (
          <div className="flex pt-[12px] pb-[6px]">
            {tagList.map((item, index) => (
              <div key={index}>
                <GrayBadge
                  title={item?.key || ''}
                  value={item?.desc || ''}
                  textColor="gray-7"
                  textWeight="medium"
                ></GrayBadge>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default DriveItemView;
