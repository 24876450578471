import React from 'react';
import { useNavigate } from 'react-router-dom';

import BasicButton from '../Common/Button/BasicButton';
import ProductFlicking from '../Common/ProductFlicking';
import { colors } from '@/const/colors';
import { SALES_TYPE_ASSURANCE } from '@/const/products';

const AssuranceProduts = ({ products }: ProductsProps) => {
  const navigate = useNavigate();

  const onClickMore = () => {
    navigate('/products', { state: { salesType: SALES_TYPE_ASSURANCE } });
  };

  return (
    <>
      <div className="flex flex-col pb-6 w-full bg-gray-0">
        <div className="p-6">
          <h2 className="text-[20px] font-semibold text-gray-8">직트럭에서 검수한 차량</h2>
        </div>
        <ProductFlicking products={products}></ProductFlicking>
        <div className="px-4 pt-0">
          <BasicButton
            name="더보기"
            bgColor={colors.gray[0]}
            borderColor={colors.gray[3]}
            textColor={colors.gray[6]}
            fontSize={16}
            height={48}
            fontWeight="bold"
            onClick={() => onClickMore()}
          />
        </div>
      </div>
    </>
  );
};

export default AssuranceProduts;
