import React from 'react';
import LazyLoad from 'react-lazyload';
import { useNavigate } from 'react-router-dom';

import { ChevronRightIcon } from '../Icon';
import { colors } from '@/const/colors';
import { formatDistanceToThousandKm, formatPrice, formatShortYear } from '@/utils/common';
import Flicking from '@egjs/react-flicking';

const InterestProducts = ({ products }: ProductsProps) => {
  const navigate = useNavigate();

  const handleClickGoToList = () => {
    navigate('/interest');
  };

  const fetchDetail = (id?: number) => {
    navigate('/products/' + id, { state: { isPublicDetail: true } });
  };

  return (
    <>
      <div className="flex flex-col pb-6 w-full bg-gray-0 min-h-[315px] max-h-[315px]">
        <div className="px-4 py-[10px] flex justify-between" onClick={handleClickGoToList}>
          <h2 className="text-[20px] font-semibold text-gray-8">찜한 차량</h2>
          <ChevronRightIcon color={colors.gray[6]} width={24} height={24} />
        </div>
        {products.map((item, index) => (
          <link rel="preload" href={item.representImageUrl} as="image" key={index} />
        ))}
        {!!products.length && (
          <Flicking
            align="prev"
            circular={false}
            renderOnlyVisible={true}
            moveType="strict"
            bound={true}
            className="z-0 px-4"
          >
            {products?.map((item, index) => (
              <div
                key={index}
                onClick={() => fetchDetail(item.productId)}
                className="mb-4 mr-2 text-left cursor-pointer w-[154px] h-[231px]"
              >
                <LazyLoad className="relative w-[154px] h-[116px]" offset={100}>
                  <div className="w-[154px] h-[116px] rounded-lg">
                    <img
                      loading="lazy"
                      className="rounded-lg w-full h-full object-cover"
                      src={item?.representImageUrl}
                      alt="트럭 사진"
                      style={{ pointerEvents: 'none' }}
                    />
                  </div>
                  <div className="absolute bottom-0 left-0 w-full h-[40px] p-3 rounded-b-lg bg-gradient-to-t from-[rgba(0,0,0,0.7)] to-[rgba(0,0,0,0)]">
                    <div className="absolute bottom-0 left-0 pb-2 px-[10px]">
                      <span className="text-gray-0 text-[14px] font-medium bottom-[10px] left-[10px]">
                        매물번호 {item?.productsNumber}
                      </span>
                    </div>
                  </div>
                </LazyLoad>
                <div className="flex flex-col justify-between h-[115px]">
                  <div>
                    <div className="font-semibold text-gray-8 text-[16px] leading-[19px] my-2 line-clamp-2">
                      {item.truckName}
                    </div>
                    <div className="text-gray-6 my-1 text-[12px] leading-[15px] break-keep line-clamp-2">
                      {formatShortYear(item.firstRegistrationDate?.split('-')?.[0])}/
                      {item.firstRegistrationDate?.split('-')?.[1]}식 • {item.transmission?.desc} •{' '}
                      {formatDistanceToThousandKm(item.distance)}
                      만km • {item.loadedInnerLength}m • {item.power}마력
                    </div>
                  </div>

                  {item.price && (
                    <div className="text-gray-9 line-clamp-1">
                      <span className="font-semibold text-[18px]">{formatPrice(item.price.toString())}</span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </Flicking>
        )}
      </div>
    </>
  );
};

export default InterestProducts;
