import React from 'react';

import BasicPopup from '@/components/Common/Popup/BasicPopup';

interface ConfirmExitPopup {
  isShow: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmExitPopup = ({ isShow, onClose, onConfirm }: ConfirmExitPopup) => {
  return (
    <BasicPopup
      isShow={isShow}
      title="정말 나가시겠어요?"
      textContent={'작성 중인 내용은 임시저장되며<br/>언제든지 재등록이 가능합니다.'}
      textLeftBtn="계속 등록"
      onClickLeftBtn={onClose}
      textRightBtn="나가기"
      onClickRightBtn={onConfirm}
    />
  );
};

export default ConfirmExitPopup;
