import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useExitConfirmationPopup = (checkForChanges: () => boolean, saveChanges: () => void, resetForm: () => void) => {
  const [isShowExitConfirmPopup, setIsShowExitConfirmPopup] = useState(false);
  const navigate = useNavigate();

  const openExitConfirmPopup = () => setIsShowExitConfirmPopup(true);
  const closeExitConfirmPopup = () => setIsShowExitConfirmPopup(false);

  const confirmExit = () => {
    const previousLocation = sessionStorage.getItem('entryPoint') || '/products/my';

    if (checkForChanges()) {
      saveChanges();
    } else {
      resetForm();
    }

    navigate(previousLocation, { replace: true });
    setIsShowExitConfirmPopup(false);
  };

  return {
    isShowExitConfirmPopup,
    openExitConfirmPopup,
    closeExitConfirmPopup,
    confirmExit,
  };
};

export default useExitConfirmationPopup;
