import React, { SVGProps } from 'react';

const SvgTruckBoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 40" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M4 11.8A2.8 2.8 0 0 1 6.8 9h16.396a2.8 2.8 0 0 1 2.8 2.8v.343h4.328c.49 0 .95.228 1.248.617l3.527 4.617c.209.274.322.609.322.954v6.381c0 .718-.481 1.323-1.139 1.511a3.928 3.928 0 1 1-7.176.06h-8.508a3.928 3.928 0 0 1-7.136 3.286A3.928 3.928 0 0 1 4 27.852V11.8Zm26.773 14.483h-.13a1.571 1.571 0 1 0 .13 0Zm-15.817 0a1.571 1.571 0 1 0 .083 0h-.083Zm-6.987 0h-.083a1.571 1.571 0 1 0 .083 0Zm18.027-7.858h6.911a1.399 1.399 0 0 0-.13-.205l-2.422-3.17a1.4 1.4 0 0 0-1.112-.55h-3.247v3.925Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgTruckBoldIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgTruckBoldIcon;
