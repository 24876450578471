import React, { useCallback, useEffect, useRef, useState } from 'react';

import BasicPopup from '../Common/Popup/BasicPopup';
import { PaperAirplaneIcon, PlusSmallIcon } from '../Icon';
import ChatInputComponent from './ChatInputComponent';
import { colors } from '@/const/colors';
import { THEUNBAN } from '@/const/referralCode';
import usePartnerSession from '@/hooks/usePartnerSession';
import { base64ToBytes } from '@/utils/common';
import * as Sentry from '@sentry/react';

type ChatRoomDataProps = {
  handleInputHeightChange: (Height: number) => void;
  chatInput: string;
  updateChatInput: (data: string) => void;
  sendChat: () => void;
  imageList: File[];
  updateImageList: (data: File[]) => void;
};

const ChatRoomInput = ({
  handleInputHeightChange,
  chatInput,
  updateChatInput,
  sendChat,
  imageList,
  updateImageList,
}: ChatRoomDataProps) => {
  const isTheunban = usePartnerSession() === THEUNBAN;

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef(null);
  const [sending, setSending] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [pendingFiles, setPendingFiles] = useState<File[]>();

  const onChangeChat = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateChatInput(e.target.value);
  }, []);

  const handleClickPlusButton = () => {
    if (isTheunban) {
      if (window.FlutterChannel) {
        window.FlutterChannel.postMessage('runImagePicker');
      }
    }

    if (fileInputRef.current) {
      (fileInputRef.current as HTMLInputElement).click();
    }
  };

  const handleSendButtonClick = async () => {
    if (sending || !chatInput.trim()) {
      return;
    }
    setSending(true);
    try {
      sendChat();
    } catch (error) {
      console.error('Error sending chat:', error);
    } finally {
      setSending(false);
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const maxFiles = 15;
      const filesArray = Array.from(selectedFiles);

      if (filesArray.length > maxFiles) {
        setPendingFiles(filesArray.slice(0, maxFiles));
        setIsOpenPopup(true);
      } else {
        updateImageList(filesArray);
      }
      e.target.value = '';
    }
  };

  useEffect(() => {
    if (imageList.length > 0) {
      sendChat();
    }
  }, [imageList]);

  useEffect(() => {
    if (inputRef.current) {
      const observer = new ResizeObserver(() => {
        if (inputRef.current) {
          handleInputHeightChange((inputRef.current as HTMLDivElement).clientHeight);
        }
      });
      observer.observe(inputRef.current, {});

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    if (isTheunban) {
      if (window.FlutterChannel) {
        window.receiveImageFileDataFromFlutter = (data: string) => {
          try {
            const imageData = JSON.parse(data);
            const fileList = imageData
              .map((item: { name: string; data: string }) => {
                try {
                  const byteArray = base64ToBytes(item.data);
                  if (!byteArray) {
                    const error = new Error('Error creating byte array from image data');
                    Sentry.captureException(error);
                    return null;
                  }
                  return new File([byteArray], item.name, { type: 'image/jpeg' });
                } catch (error) {
                  Sentry.captureException(error);
                }
              })
              .filter((file: any): file is File => file !== null);
            updateImageList(fileList);
          } catch (error) {
            Sentry.captureException(error);
          }
        };
      } else {
        const error = new Error('FlutterChannel is not available');
        Sentry.captureException(error);
      }
    }
  }, [isTheunban]);

  const handleClickSend = () => {
    if (pendingFiles) {
      updateImageList(pendingFiles);
    }
    setIsOpenPopup(false);
  };

  const handleClickReSelect = () => {
    setIsOpenPopup(false);
    handleClickPlusButton();
  };

  return (
    <>
      <div
        ref={inputRef}
        className="shrink-0 z-30 fixed bottom-0 left-0 w-full py-2 px-4 bg-white flex items-center justify-between shadow-[0_-2px_4px_rgba(0,0,0,0.05)] mx-auto left-0 right-0 my-0 max-w-[720px] min-w-[280px] max-h-[700px]"
      >
        <div className="flex rounded-lg mr-[10px] justify-center items-center text-center w-[28px] h-[28px]">
          <div
            className="flex justify-center items-center cursor-pointer border-[1.3px] border-gray-5 rounded-[7px]"
            onClick={handleClickPlusButton}
          >
            <PlusSmallIcon color={colors.gray[5]} width={28} height={28}></PlusSmallIcon>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept="image/*"
            multiple
          />
        </div>

        <ChatInputComponent
          name="message"
          placeholder="메시지 보내기"
          value={chatInput}
          fontSize={16}
          minRows={1}
          maxRows={4}
          onChange={onChangeChat}
        ></ChatInputComponent>
        <div className={`ml-[10px] ${chatInput.trim() ? 'cursor-pointer' : ''}`} onClick={handleSendButtonClick}>
          <PaperAirplaneIcon color={chatInput.trim() ? colors.primary : colors.gray[5]}></PaperAirplaneIcon>
        </div>
      </div>

      <BasicPopup
        isShow={isOpenPopup}
        title=""
        textContent={'이미지는 최대 15개까지<br/>전송 가능합니다.<br/>초과된 파일을 제외하고 전송할까요?'}
        textLeftBtn="재선택"
        onClickLeftBtn={() => handleClickReSelect()}
        textRightBtn="전송하기"
        onClickRightBtn={() => handleClickSend()}
      ></BasicPopup>
    </>
  );
};

export default ChatRoomInput;
