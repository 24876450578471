import { AxiosError } from 'axios';
import { useAtom, useAtomValue } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  createCapitalCounselServices,
  createTransferAgencyServices,
} from '@/api/AdditionalServices/createAdditionalServices';
import apiManager from '@/api/AxiosInstance';
import OutlinedBadge from '@/components/Common/Badge/OutlinedBadge';
import BasicButton from '@/components/Common/Button/BasicButton';
import IconInfoButton from '@/components/Common/Button/IconInfoButton';
import PhoneCallFloationgButton from '@/components/Common/Button/PhoneCallFloationgButton';
import CarPriceTrendInfo from '@/components/Common/CarPriceTrendInfo';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import TooltipComponent from '@/components/Common/Tooltip/TooltipComponent';
import Error from '@/components/Error/Error';
import MenuHeader, { MenuHeaderIcon } from '@/components/Header/MenuHeader';
import {
  DocumentTextSolidIcon,
  EnlargementOutlineIcon,
  ErrorCircleIcon,
  HomeOutlineIcon,
  LoanConsulationSolidIcon,
} from '@/components/Icon';
import { MoreMenuData } from '@/components/Menu/MoreMenu';
import CapitalGuidePopup from '@/components/More/CaptialCounselService/CapitalGuidePopup';
import TransferGuidePopup from '@/components/More/TransferAgencyService/TransferGuidePopup';
import ProductDetailFooter from '@/components/Products/Purchase/ProductDetailFooter';
import ProductDetailTab from '@/components/Products/Purchase/ProductTab';
import HistoryCountView from '@/components/Products/Sale/HistoryCountView';
import {
  IS_ALREADY_APPLY_CAPITAL_COUNSEL_SERVICE,
  IS_ALREADY_APPLY_TRANSFER_AGENCY_SERVICE,
} from '@/const/additionalService';
import { colors } from '@/const/colors';
import { PRODUCT_DETAIL } from '@/const/common';
import { ALREADY_SALE_PRODUCTS, DELETE_PRODUCTS } from '@/const/errorCode';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import {
  SALES_TYPE_ASSURANCE,
  SALES_TYPE_CONSIGNMENT,
  SALES_TYPE_NORMAL,
  SALES_TYPE_THIRD_PARTY_DEALER,
} from '@/const/products';
import { THEUNBAN } from '@/const/referralCode';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useAuthRedirect from '@/hooks/useAuthRedirect';
import usePartnerSession from '@/hooks/usePartnerSession';
import NotFoundPage from '@/pages/Error/NotFoundPage';
import { isMemberLoadedAtom, memberAtom, updateAdditionalServiceAtom } from '@/store/member';
import { formatPrice, getFormatDateOnlyNum } from '@/utils/common';
import Flicking, { ChangedEvent } from '@egjs/react-flicking';

type LiceseProps = {
  truckNumber: string | undefined;
};

const LicensePlate = ({ truckNumber }: LiceseProps) => {
  return (
    <div className="bg-white rounded-lg m-5">
      <div className="bg-yellow rounded-lg border-2 border-gray-7 p-3 flex items-center justify-center px-0">
        <input
          type="text"
          maxLength={9}
          className="license-plate-input bg-transparent border-none focus:outline-none text-[26px] xxs:text-[22px] font-semibold justify-center text-center w-[200px] text-gray-8"
          value={truckNumber}
          readOnly
        ></input>
      </div>
    </div>
  );
};

const ProductDetail = () => {
  const popupRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const [isNearBottom, setIsNearBottom] = useState(false);
  const threshold = 20;

  const [isAlreadyApplyCapitalService, setIsAlreadyApplyCaptialService] = useState(false);
  const [isAlreadyApplyTransferService, setIsAlreadyApplyTransferService] = useState(false);

  const isTheunban = usePartnerSession() === THEUNBAN;
  const authRedirect = useAuthRedirect();
  const isMemberLoaded = useAtomValue(isMemberLoadedAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const flickingRef = useRef<Flicking>(null);
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [memberAtomData] = useAtom(memberAtom);
  const [, updateAdditionalService] = useAtom(updateAdditionalServiceAtom);

  const isRegister = location.state?.isRegisterBtn;
  const isPublicDetail = location.state?.isPublicDetail == null ? true : location.state?.isPublicDetail;
  const [isSeller, setIsSeller] = useState<boolean>();
  const [isShowBasicPopup, setIsShowBasicPopup] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<ProductSearchParams>();
  const productImages: string[] = [];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [tabNum, setTabNum] = useState(0);

  const [moreMenuData, setMoreMenuData] = useState<MoreMenuData>();
  const [isShowErrorPage, setIsShowErrorPage] = useState(false);
  const [isShowErrorPageForDeleteOrCompletedProduct, setIsShowErrorPageForDeleteOrCompletedProduct] = useState(false);
  const { showToast } = useToastContext();
  const [popupInfo, setPopupInfo] = useState<BasicPopupProps>({
    isShow: false,
    title: '',
    textContent: '',
    componentContent: null,
    textRightBtn: '',
    onClickRightBtn: () => {},
    textLeftBtn: '',
    onClickLeftBtn: () => {},
  });
  const [isScrolling, setIsScrolling] = useState(false);
  const [isOpenCapitalModal, setIsOpenCapitalModal] = useState(false);
  const [isOpenTransferModal, setIsOpenTransferModal] = useState(false);

  const productDetailPath = isPublicDetail ? '/api/v1/public/products/' : '/api/v1/products/';

  const fetchProductDetail = async (id?: string, memberId?: number) => {
    const queryParams = new URLSearchParams();
    if (memberId) {
      queryParams.append('memberId', String(memberId));
    }

    const response = await apiManager.get(`${productDetailPath}${id}?` + queryParams.toString());
    return response.data;
  };

  const { data, error, isLoading } = useQuery(
    ['productDetail', id, memberAtomData?.id],
    () => fetchProductDetail(id, memberAtomData?.id),
    {
      onError: (error: AxiosError) => {
        if (error?.code === ALREADY_SALE_PRODUCTS || error?.code === DELETE_PRODUCTS) {
          setIsShowErrorPage(false);
          setIsShowErrorPageForDeleteOrCompletedProduct(true);
        } else {
          setIsShowErrorPage(true);
          setIsShowErrorPageForDeleteOrCompletedProduct(false);
        }
      },
      enabled: isMemberLoaded,
      refetchOnWindowFocus: false,
      retry: 0,
    },
  );

  useEffect(() => {
    if (data) {
      setMoreMenuData({
        id: data.id,
        status: data.status.code,
      });

      const tons = data?.tons;
      const year = getFormatDateOnlyNum(data?.firstRegistrationDate, ['year']);
      const model = data?.model;
      const loaded = data?.loaded;
      const loadedInnerLength = data?.loadedInnerLength;
      const axis = data?.axis;
      setSearchParams({ tons, year, model, loaded, loadedInnerLength, axis });
      setIsSeller(data?.sellerId === memberAtomData?.id);
    }

    if (error) {
      const errorResponse = error as ErrorResponse;
      if (errorResponse.code === ALREADY_SALE_PRODUCTS || errorResponse.code === DELETE_PRODUCTS) {
        setIsShowErrorPage(false);
        setIsShowErrorPageForDeleteOrCompletedProduct(true);
      } else {
        setIsShowErrorPage(true);
        setIsShowErrorPageForDeleteOrCompletedProduct(false);
      }
    }
  }, [data, error]);

  const updateHistory = (path: string) => {
    window.history.replaceState(null, '', path);
  };

  useEffect(() => {
    if (data) {
      if (isRegister) {
        showToast('차량 등록이 완료되었어요.', 'success', 'bottom');

        setTimeout(() => {
          setIsShowBasicPopup(true);
          setPopupInfo({
            isShow: true,
            title: '번호판도 함께<br/>판매하시겠어요?',
            textContent: '',
            componentContent: <LicensePlate truckNumber={data?.truckNumber}></LicensePlate>,
            textRightBtn: '판매할래요',
            onClickRightBtn: onClickSaleLicense,
            textLeftBtn: '아니요',
            onClickLeftBtn: () => {
              setIsShowBasicPopup(false);
            },
          });
        }, 1000);
        queryClient.invalidateQueries({ queryKey: ['product-search'] });
      } else if (isRegister === false) {
        showToast('차량 수정이 완료되었어요.', 'success', 'bottom');
        queryClient.invalidateQueries({ queryKey: ['product-search'] });
      }
    }
  }, [data]);

  useEffect(() => {
    let scrollTimeout: NodeJS.Timeout;

    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(scrollTimeout);

      scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  const onClickSaleLicense = () => {
    if (data) {
      setIsShowBasicPopup(false);
      updateHistory(`/products/${data.id}`);
      navigate('/license/form', {
        state: { isModify: false, licenseInfo: { year: data?.year, tons: data?.tons, productId: data.id } },
      });
    }
  };

  if (data?.productsImage) {
    const images = data.productsImage;

    const imageFields = [
      'frontSideImageUrl',
      'frontImageUrl',
      'backSideImageUrl',
      'backImageUrl',
      'tireImageUrl',
      'engineImageUrl',
      'insideImageUrl',
      'dashboardImageUrl',
      'sheetImageUrl',
    ];

    imageFields.forEach((field) => {
      if (images[field] && images[field] !== '') {
        productImages.push(images[field]);
      }
    });

    if (images.optionImageUrl) {
      images.optionImageUrl.forEach((item: any) => {
        if (item !== '') {
          productImages.push(item);
        }
      });
    }
  }

  const onClickImage = () => {
    navigate(`/products/${id}/image`, { state: { title: data?.truckNumber, imageList: productImages } });
  };

  const menuHeaderIcons: MenuHeaderIcon[] = [
    { icon: <HomeOutlineIcon color={colors.gray[8]}></HomeOutlineIcon>, onClickIcon: () => navigate('/') },
  ];

  const getBadgeColor = (type: string) => {
    switch (type) {
      case SALES_TYPE_NORMAL:
      case SALES_TYPE_CONSIGNMENT:
      case SALES_TYPE_THIRD_PARTY_DEALER:
        return 'primary';
      case SALES_TYPE_ASSURANCE:
        return 'green';
      default:
        return '';
    }
  };

  const getTooltipText = (type: string) => {
    switch (type) {
      case 'NORMAL':
        return '개인 차주가 판매 중인 차량입니다.';
      case SALES_TYPE_ASSURANCE:
        return '직트럭에서 점검 및 수리 후 상품화가 완료된 차량입니다.';
      case SALES_TYPE_CONSIGNMENT:
        return '판매자 요청에 의해 직트럭이 위탁 대행 중인 차량입니다.';
      case SALES_TYPE_THIRD_PARTY_DEALER:
        return '타 매매상사의 딜러가 판매 중인 차량입니다.';
      default:
        return '';
    }
  };

  const handleFlickingChanged = (e: ChangedEvent<Flicking>) => {
    const { index } = e.currentTarget;

    setCurrentImageIndex(index);
  };

  const handleCloseModal = () => {
    setIsOpenCapitalModal(false);
    setIsOpenTransferModal(false);
    setIsNearBottom(false);
  };

  const handleClickApply = () => {
    if (!authRedirect(PRODUCT_DETAIL(data.id))) {
      return;
    }

    setIsShowBasicPopup(true);
    setPopupInfo({
      isShow: true,
      title: `${data.truckName}`,
      textContent: `해당 차량으로 ${
        isOpenCapitalModal ? '화물차 대출 상담' : isOpenTransferModal ? '서류 이전 대행' : ''
      }<br/>서비스를 신청할까요?`,
      textRightBtn: '신청하기',
      onClickRightBtn: () => {
        handleClickApplyOk();
      },
      textLeftBtn: '취소',
      onClickLeftBtn: () => {
        setIsShowBasicPopup(false);
      },
    });
  };

  const handleClickApplyOk = () => {
    if (isOpenCapitalModal) {
      const requestData = {
        name: memberAtomData?.name,
        phoneNumber: memberAtomData?.phoneNumber,
        productId: data.id,
      };

      postCapitalCounselServices.mutate(requestData);
    } else if (isOpenTransferModal) {
      const requestData = {
        name: memberAtomData?.name,
        phoneNumber: memberAtomData?.phoneNumber,
      };

      postTransferAgencyServices.mutate(requestData);
    }

    setIsShowBasicPopup(false);
  };

  const postCapitalCounselServices = useMutation(
    (request: AdditionalServicesApplyRequest) => createCapitalCounselServices(request),
    {
      onSuccess: () => {
        showToast('화물차 대출 상담 서비스를 신청했어요.', 'success', 'bottom');
        setIsAlreadyApplyCaptialService(true);
        updateAdditionalService(IS_ALREADY_APPLY_CAPITAL_COUNSEL_SERVICE);
      },
      onError: (error: AxiosError) => {
        if (error.code && error.message) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
      onSettled: () => {
        setIsOpenCapitalModal(false);
      },
    },
  );

  const postTransferAgencyServices = useMutation(
    (request: AdditionalServicesApplyRequest) => createTransferAgencyServices(request),
    {
      onSuccess: () => {
        showToast('서류 이전 대행 서비스를 신청했어요.', 'success', 'bottom');
        setIsAlreadyApplyTransferService(true);
        updateAdditionalService(IS_ALREADY_APPLY_TRANSFER_AGENCY_SERVICE);
      },
      onError: (error: AxiosError) => {
        if (error.code && error.message) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
      onSettled: () => {
        setIsOpenTransferModal(false);
      },
    },
  );

  const handleScrollBottom = () => {
    const popupElement = popupRef.current;
    const contentElement = contentRef.current;

    if (!popupElement) return;
    if (!contentElement) return;

    const scrollTop = popupElement.scrollTop;
    const viewportHeight = popupElement.clientHeight;
    const scrollHeight = contentElement.scrollHeight;

    if (scrollTop + viewportHeight < scrollHeight) {
      popupElement.scrollBy({
        top: viewportHeight,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    const popupElement = popupRef.current;
    const contentElement = contentRef.current;

    if (!popupElement) return;
    if (!contentElement) return;

    const scrollTop = popupElement.scrollTop;
    const viewportHeight = popupElement.clientHeight;
    const scrollHeight = contentElement.scrollHeight;

    setIsNearBottom(scrollTop + viewportHeight >= scrollHeight - threshold);
  };

  useEffect(() => {
    setTimeout(() => {
      handleScroll();
    }, 0);
  }, [isOpenTransferModal, isOpenCapitalModal]);

  useEffect(() => {
    if (memberAtomData) {
      setIsAlreadyApplyTransferService(memberAtomData.isAlreadyApplyTransferAgencyService);
      setIsAlreadyApplyCaptialService(memberAtomData.isAlreadyApplyCapitalCounselService);
    }
  }, [memberAtomData]);

  const isShowMoreMenuIcon = isSeller && !isTheunban && data?.salesType?.code !== SALES_TYPE_CONSIGNMENT;

  return (
    <>
      {data && !isShowErrorPage && !isShowErrorPageForDeleteOrCompletedProduct && (
        <>
          <MenuHeader
            title={data.truckNumber || ''}
            icons={isTheunban ? [] : menuHeaderIcons}
            moreMenuIcon={isShowMoreMenuIcon}
            moreMenuData={moreMenuData}
            isMain={isTheunban ? true : false}
          />
          <section className="flex flex-col justify-between w-full pb-28 pt-[60px]">
            <div className="relative">
              <Flicking
                ref={flickingRef}
                align="center"
                circular={true}
                renderOnlyVisible={true}
                className="z-0 w-full"
                moveType="strict"
                circularFallback="bound"
                onChanged={(e) => handleFlickingChanged(e)}
              >
                {productImages.map((imageUrl, index) => (
                  <div
                    className="flex w-full h-[16.875rem]"
                    key={index}
                    onClick={() => {
                      onClickImage();
                    }}
                  >
                    <img src={imageUrl as string} className="w-full h-full object-cover" alt="product_img"></img>
                  </div>
                ))}
              </Flicking>
              <div
                className="absolute bottom-0 left-0 w-full h-[50px] p-3 bg-gradient-to-t from-[rgba(0,0,0,0.4)] to-[rgba(0,0,0,0)]"
                onClick={() => {
                  onClickImage();
                }}
              >
                <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 z-10 bg-gray-9 opacity-70 rounded-full text-white min-w-[46px] h-auto text-center text-[14px] leading-[16px] font-medium p-2">
                  {currentImageIndex + 1}/{productImages?.length}
                </div>
                <div className="absolute bottom-0 right-0 p-3 z-10">
                  <EnlargementOutlineIcon color={colors.gray[0]}></EnlargementOutlineIcon>
                </div>
              </div>
            </div>

            <div className="p-4">
              <div className="relative flex justify-between items-start">
                <span className="font-semibold text-xl align-middle">{data.truckName}</span>
              </div>
              <span className="text-gray-6 pt-2">매물번호 {data.productsNumber}</span>
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-end">
                  <p className="font-semibold text-xl">{formatPrice(data.price?.toString())}</p>
                </div>

                {data?.salesType && (
                  <div className="flex items-center gap-[2px]">
                    <OutlinedBadge color={getBadgeColor(data?.salesType?.code)} text={data?.salesType?.desc} />
                    <TooltipComponent
                      title={getTooltipText(data?.salesType?.code || '')}
                      content=""
                      position="right"
                      alignmentTipbox="right"
                      color={colors.red}
                    />
                  </div>
                )}
              </div>
              <p className="text-sm text-gray-7">조회수 {data.hit}</p>
            </div>
            <div className="px-4 pt-4 border-t-8 border-gray-1">
              <h4 className="text-lg font-semibold inline-block align-middle">시세 정보</h4>
              <TooltipComponent
                title=""
                content="* 톤수별 평균 키로수 (영업용 기준) <br/>1. 14~25톤 (1년 기준 10만내외) <br/>2. 4.5톤 (1년 기준 8만km내외) <br/>3. 2.5~3.5톤 (1년 기준 6만km 내외) <br/> 4. 1톤 (1년 기준 4만km내외)"
                alignmentTipbox="left"
              />
              <CarPriceTrendInfo
                priceSearchParams={searchParams}
                showPriceComparison={true}
                price={data.price?.toString()}
                apiType="public"
              />
              <div className="bg-gray-1 rounded-[12px] text-[14px] text-red mb-6 p-4">
                * 차량 주행거리, 차량 상태, 차량 옵션 등으로 시세 차이가 발생할 수 있습니다.
              </div>
            </div>

            <div className="border-t-8 border-gray-1 py-6">
              <p className="text-gray-8 font-semibold text-[18px] px-4 mb-3">이런 서비스는 어떠세요?</p>
              <div className="sm280:flex-col flex items-center justify-center gap-[12px] px-4">
                <IconInfoButton
                  onClick={() => setIsOpenCapitalModal(true)}
                  icon={<LoanConsulationSolidIcon color={colors.green[1]} width={38} height={38} />}
                  title="최저금리 대출<br/>상담받기"
                />
                <IconInfoButton
                  onClick={() => setIsOpenTransferModal(true)}
                  icon={<DocumentTextSolidIcon color={colors.blue[7]} width={38} height={38} />}
                  title="서류 이전<br/>한큐에 해결"
                />
              </div>
            </div>
            <HistoryCountView
              productData={data}
              onChange={setTabNum}
              tabMapping={{ SEIZURE: 1, MORTGAGE: 1, TRADING: 1, TUNING: 1 }}
            />
            <div className="bg-gray-1 w-full h-2"></div>

            <ProductDetailTab product={data} value={tabNum} onChange={setTabNum} />
          </section>
          {!isSeller && (
            <div className="flex items-center justify-center fixed w-full mx-auto my-0 bottom-0 left-0 right-0 h-16 max-w-[720px] min-w-[280px] z-40">
              <ProductDetailFooter product={data} isShowBubble={!isScrolling} />
            </div>
          )}
        </>
      )}
      {isShowErrorPage && <NotFoundPage />}
      {isShowErrorPageForDeleteOrCompletedProduct && (
        <div className="w-full flex flex-col justify-center items-center h-screen">
          <Error
            icon={<ErrorCircleIcon color={colors.gray[5]} width={144} height={144}></ErrorCircleIcon>}
            title="삭제된 차량입니다"
            description="판매되었거나</br>삭제된 차량입니다."
          />
        </div>
      )}
      <BasicPopup
        isShow={isShowBasicPopup}
        title={popupInfo?.title}
        textContent={popupInfo?.textContent}
        componentContent={popupInfo?.componentContent}
        textRightBtn={popupInfo.textRightBtn}
        onClickRightBtn={popupInfo.onClickRightBtn}
        textLeftBtn={popupInfo.textLeftBtn}
        onClickLeftBtn={popupInfo.onClickLeftBtn}
      />

      <DownToUpDetailPopup
        ref={popupRef}
        isShow={isOpenCapitalModal || isOpenTransferModal}
        onClosePopup={handleCloseModal}
        title={isOpenCapitalModal ? '화물차 대출 상담 서비스' : isOpenTransferModal ? '서류 이전 대행 서비스' : ''}
        onScroll={handleScroll}
      >
        <div className="pt-4 pb-2">
          {isOpenCapitalModal ? (
            <>
              <CapitalGuidePopup ref={contentRef} price={data.price} />
              <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 h-14 max-w-[720px] min-w-[280px] bg-white">
                <div className="absolute right-[16px] top-[-72px]">
                  <PhoneCallFloationgButton />
                </div>

                <div className="absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]"></div>
                <BasicButton
                  name={
                    isAlreadyApplyCapitalService
                      ? '서비스 신청완료'
                      : isNearBottom
                      ? '한도 조회 신청하기'
                      : '아래로 내리기'
                  }
                  bgColor={colors.primary}
                  borderColor={colors.primary}
                  textColor={colors.gray[0]}
                  fontSize={16}
                  height={48}
                  fontWeight="bold"
                  isDisabled={isAlreadyApplyCapitalService}
                  onClick={isNearBottom ? handleClickApply : handleScrollBottom}
                />
              </div>
            </>
          ) : isOpenTransferModal ? (
            <>
              <TransferGuidePopup ref={contentRef} />
              <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 h-14 max-w-[720px] min-w-[280px] bg-white">
                <div className="absolute right-[16px] top-[-72px]">
                  <PhoneCallFloationgButton />
                </div>

                <div className="absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]"></div>
                <BasicButton
                  name={
                    isAlreadyApplyTransferService
                      ? '서비스 신청완료'
                      : isNearBottom
                      ? '서비스 신청하기'
                      : '아래로 내리기'
                  }
                  bgColor={colors.primary}
                  borderColor={colors.primary}
                  textColor={colors.gray[0]}
                  fontSize={16}
                  height={48}
                  fontWeight="bold"
                  isDisabled={isAlreadyApplyTransferService}
                  onClick={isNearBottom ? handleClickApply : handleScrollBottom}
                />
              </div>
            </>
          ) : null}
        </div>
      </DownToUpDetailPopup>
    </>
  );
};

export default ProductDetail;
