import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TruckBoldIcon } from '../Icon';
import BasicButton from './Button/BasicButton';
import { colors } from '@/const/colors';
import { PAGE_NAMES } from '@/const/common';
import { getPageName } from '@/utils/Products/productUtils';
import ClearIcon from '@mui/icons-material/Clear';

interface DraftListingProps {
  data: ProductDetailResponse;
  onClickDelete: (item: ProductDetailResponse) => void;
  updateList: React.Dispatch<React.SetStateAction<ProductDetailResponse[]>>;
}

const DraftListingBox = ({ data, onClickDelete, updateList }: DraftListingProps) => {
  const navigate = useNavigate();
  const [pageNum, setPageNum] = useState(0);

  useEffect(() => {
    const pageName = getPageName(data);
    setPageNum(PAGE_NAMES[pageName]);
  }, [data]);

  const handleContinueClick = () => {
    const pageName = getPageName(data);
    const prefixPath = '/products/sales';
    navigate(`${prefixPath}/${pageName}/${data.id}`);
  };

  const renderImage = data.productsImage?.frontSideImageUrl ? (
    <img
      className="rounded-lg w-[66px] h-[66px] object-cover bg-cover"
      src={data.productsImage.frontSideImageUrl}
      width={66}
      height={66}
      alt="트럭 사진"
    />
  ) : (
    <div className="flex justify-center items-center bg-gray-3 rounded-[6px] w-[66px] h-[66px]">
      <TruckBoldIcon color={colors.gray[5]} width={40} height={40} />
    </div>
  );

  return (
    <>
      <li className="flex flex-col px-4 py-4 bg-gray-1 rounded-lg my-2 relative">
        <div className="flex justify-between flex-1">
          <div className="flex rounded-lg">{renderImage}</div>
          <div className="flex-1 ml-3">
            <div className="flex flex-wrap gap-[6px] mr-6 text-gray-8 font-semibold">{data.truckNumber}</div>
            <div className="pt-2 text-gray-7 text-sm font-medium flex justify-between xxs:flex-col">
              <span className="mr-2 flex items-center h-[34px]">{pageNum - 1}/9 완료</span>
              <div className="xxs:flex xxs:justify-end">
                <BasicButton
                  name="이어서 등록"
                  bgColor={colors.blue[7]}
                  borderColor={colors.blue[7]}
                  textColor={colors.gray[0]}
                  fontSize={14}
                  width={88}
                  height={34}
                  fontWeight="semibold"
                  onClick={handleContinueClick}
                />
              </div>
            </div>
          </div>
        </div>

        <ClearIcon
          className="absolute top-4 right-4 cursor-pointer"
          sx={{ color: colors.gray[7] }}
          onClick={() => onClickDelete(data)}
        ></ClearIcon>
      </li>
    </>
  );
};

export default DraftListingBox;
