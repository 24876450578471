import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ImageModal from '@/components/Common/ImageModal';
import MenuHeader from '@/components/Header/MenuHeader';
import NotFoundPage from '@/pages/Error/NotFoundPage';

const ProductImageDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isShow, setIsShow] = useState(false);
  const [imageUrlIndex, setImageUrlIndex] = useState<number | null>(null);

  const title = location.state?.title;
  const imageList = location.state?.imageList;

  const handleClickImg = (index: number) => {
    setIsShow(true);
    setImageUrlIndex(index);
  };

  return (
    <>
      {imageList && imageList.length > 0 ? (
        <>
          <MenuHeader title={title || ''} onClickPrevBtn={() => navigate(-1)} />
          <div className="w-full h-screen bg-gray-0 top-0 max-w-[720px] pb-[24px]">
            <div className="relative max-h-[800px] max-w-[720px] mt-[60px]">
              {imageList?.map((item: string, index: number) => (
                <div key={index}>
                  <div
                    className="flex flex-col w-full overflow-auto"
                    style={{
                      width: '100%',
                    }}
                    onClick={() => handleClickImg(index)}
                  >
                    <div className="w-full flex flex-col justify-center items-center overflow-hidden">
                      <img src={item} alt="이미지" className="w-full object-center" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <ImageModal isShow={isShow} onClose={() => setIsShow(false)} imgList={imageList} index={imageUrlIndex} />
        </>
      ) : (
        <NotFoundPage />
      )}
    </>
  );
};

export default ProductImageDetail;
