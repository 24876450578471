import React, { SVGProps } from 'react';

const SvgMinusCircleSolidIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12ZM6 11a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2H6Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgMinusCircleSolidIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgMinusCircleSolidIcon;
