import React, { ReactNode, forwardRef, useEffect, useRef, useState } from 'react';

import { MenuHeaderIcon } from '@/components/Header/MenuHeader';
import { ChevronLeftIcon, XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import '@/css/downToUpDetailPopup.css';

interface SelectPopupInterface {
  isShow: boolean;
  children?: ReactNode;
  onClosePopup: (isShow: boolean) => void;
  iconTitle?: ReactNode;
  title?: string;
  onClear?: () => void;
  isHeightFull?: boolean;
  heightRate?: number;
  isBackdrop?: boolean;
  isShowXMark?: boolean;
  icons?: MenuHeaderIcon[];
  isShowPrevBtn?: boolean;
  onScroll?: () => void;
}

const DownToUpDetailPopup = forwardRef<HTMLDivElement, SelectPopupInterface>(
  (
    {
      isShow,
      children,
      onClosePopup,
      iconTitle,
      title,
      onClear,
      isHeightFull,
      heightRate = 100,
      isBackdrop = true,
      isShowXMark = true,
      icons,
      isShowPrevBtn = false,
      onScroll,
    },
    ref,
  ) => {
    const popupRef = useRef<HTMLDivElement | null>(null);

    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
      setShowPopup(isShow);
    }, [isShow]);

    useEffect(() => {
      document.body.style.overflow = isShow ? 'hidden' : 'auto';

      return () => {
        document.body.style.overflow = 'auto';
      };
    }, [isShow]);

    const onClickClose = () => {
      if (onClear) {
        onClear();
      }
      onClosePopup(false);
    };

    return (
      <>
        {showPopup && (
          <div>
            {isBackdrop && (
              <div className="fixed bg-black opacity-75 w-full h-full justify-center items-center top-0 left-0 inline-flex z-40"></div>
            )}

            <div
              ref={popupRef}
              className={`${
                showPopup ? 'fadeInUpObj' : 'fadeOutObj'
              } fixed z-50 bottom-0 left-0 w-full rounded-t-xl bg-white flex flex-col items-center justify-center shadow-[0_-2px_4px_rgba(0,0,0,0.05)] mx-auto left-0 right-0 my-0 max-w-[720px] min-w-[280px]`}
              style={{
                maxHeight: `calc(${heightRate}dvh - 60px)`,
              }}
            >
              <div
                className={`w-full rounded-t-xl max-w-[720px] min-w-[280px] ${
                  isHeightFull ? 'h-[calc(100dvh-60px)]' : ''
                }`}
                style={{
                  maxHeight: `calc(${heightRate}dvh - 60px)`,
                }}
              >
                <div className="sticky py-4">
                  {isShowXMark && (
                    <div className="absolute m-4 cursor-pointer top-0 right-0" onClick={onClickClose}>
                      <XMarkIcon color={colors.gray[9]}></XMarkIcon>
                    </div>
                  )}

                  {icons && (
                    <div className="flex items-center gap-2 absolute m-4 cursor-pointer top-0 right-0">
                      {icons.map((icon, index) => (
                        <div key={index}>
                          <div onClick={icon.onClickIcon} className="flex items-center cursor-pointer gap-1">
                            {icon.icon}
                            {icon.text && <span className="text-[14px] text-gray-8">{icon.text}</span>}
                          </div>
                          {icon.text && index < icons.length - 1 && <span className="text-gray-4"> | </span>}
                        </div>
                      ))}
                    </div>
                  )}

                  {isShowPrevBtn && (
                    <div className="flex items-center gap-2 absolute m-4 cursor-pointer top-0 left-0">
                      <div className="mr-4 cursor-pointer flex justify-center items-center" onClick={onClickClose}>
                        <ChevronLeftIcon color={colors.gray[9]}></ChevronLeftIcon>
                      </div>
                    </div>
                  )}

                  {title && <h4 className="font-bold text-center text-gray-8">{title}</h4>}
                  {iconTitle && iconTitle}
                </div>
                <div
                  className={`overflow-y-auto`}
                  ref={ref}
                  style={{
                    maxHeight: `calc(${heightRate}dvh - 112px)`,
                  }}
                  onScroll={onScroll}
                >
                  {children}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  },
);

export default DownToUpDetailPopup;
