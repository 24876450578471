import { useSetAtom } from 'jotai';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { patchProducts } from '@/api/public';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { productsFormAtom, useResetProductsForm } from '@/store/products';

interface usePatchProductsMutationProps {
  url?: string;
  isFinalStep?: boolean;
}

const useUpdateProductFormMutation = ({ url, isFinalStep }: usePatchProductsMutationProps) => {
  const navigate = useNavigate();
  const { showToast } = useToastContext();

  const setProductFormData = useSetAtom(productsFormAtom);
  const resetProductsForm = useResetProductsForm();

  const { mutate } = useMutation(patchProducts, {
    onSuccess: (response) => {
      if (response && response.data) {
        if (url) {
          navigate(url, { state: { isRegisterBtn: isFinalStep }, replace: true });
        }
        setProductFormData(response.data);
      } else {
        showToast('응답 데이터가 올바르지 않습니다.', 'error', 'bottom');
      }
    },
    onError: () => {
      showToast('문제가 생겼어요. 다시 시도해주세요.', 'error', 'bottom');
    },
  });

  return { mutate };
};

export default useUpdateProductFormMutation;
