import apiManager from '../AxiosInstance';
import { MULTIPART_FORM_DATA } from '@/const/headers';

export const createProductsImages = async (formData: FormData) => {
  const res = await apiManager.post('/api/v1/products-images', formData, {
    headers: {
      'Content-Type': MULTIPART_FORM_DATA,
    },
  });
  return res;
};

export const createProductsImagesMulti = async (formData: FormData) => {
  const res = await apiManager.post('/api/v1/products-images/multi', formData, {
    headers: {
      'Content-Type': MULTIPART_FORM_DATA,
    },
  });
  return res;
};
