// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.next-enter {
  z-index: 10;
  transform: translateX(100vw);
  will-change: transform;
}
.next-enter-active {
  transform: translateX(0);
  transition: transform 500ms ease;
}
.next-exit {
  z-index: 0;
  transform: translateX(0);
}

.next-exit-active {
  transform: translateX(-100vw) !important;
  transition: transform 500ms ease !important;
}

.prev-enter {
  z-index: 10;
  transform: translateX(-100vw);
  will-change: transform;
}
.prev-enter-active {
  transform: translateX(0);
  transition: transform 500ms ease;
}
.prev-exit {
  z-index: 0;
  transform: translateX(0);
}
.prev-exit-active {
  transform: translateX(100vw);
  transition: transform 500ms ease;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  z-index: 10;
  opacity: 1;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  z-index: 0;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/css/transition.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,4BAA4B;EAC5B,sBAAsB;AACxB;AACA;EACE,wBAAwB;EACxB,gCAAgC;AAClC;AACA;EACE,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,wCAAwC;EACxC,2CAA2C;AAC7C;;AAEA;EACE,WAAW;EACX,6BAA6B;EAC7B,sBAAsB;AACxB;AACA;EACE,wBAAwB;EACxB,gCAAgC;AAClC;AACA;EACE,UAAU;EACV,wBAAwB;AAC1B;AACA;EACE,4BAA4B;EAC5B,gCAAgC;AAClC;;AAEA;EACE,UAAU;AACZ;AACA;EACE,WAAW;EACX,UAAU;EACV,kEAAkE;AACpE;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;EACV,UAAU;EACV,qCAAqC;AACvC","sourcesContent":[".next-enter {\n  z-index: 10;\n  transform: translateX(100vw);\n  will-change: transform;\n}\n.next-enter-active {\n  transform: translateX(0);\n  transition: transform 500ms ease;\n}\n.next-exit {\n  z-index: 0;\n  transform: translateX(0);\n}\n\n.next-exit-active {\n  transform: translateX(-100vw) !important;\n  transition: transform 500ms ease !important;\n}\n\n.prev-enter {\n  z-index: 10;\n  transform: translateX(-100vw);\n  will-change: transform;\n}\n.prev-enter-active {\n  transform: translateX(0);\n  transition: transform 500ms ease;\n}\n.prev-exit {\n  z-index: 0;\n  transform: translateX(0);\n}\n.prev-exit-active {\n  transform: translateX(100vw);\n  transition: transform 500ms ease;\n}\n\n.fade-enter {\n  opacity: 0;\n}\n.fade-enter-active {\n  z-index: 10;\n  opacity: 1;\n  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;\n}\n.fade-exit {\n  opacity: 1;\n}\n.fade-exit-active {\n  z-index: 0;\n  opacity: 0;\n  transition: opacity 300ms ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
