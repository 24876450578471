import React, { useEffect, useRef, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { useNavigate } from 'react-router-dom';

import { formatPrice, formatShortYear } from '@/utils/common';
import Flicking, { EVENTS } from '@egjs/react-flicking';

const RecommendProducts = ({ products }: ProductsProps) => {
  const flickingRef = useRef<Flicking | null>(null);
  const navigate = useNavigate();

  const fetchDetail = (id: number) => {
    navigate('/products/' + id, { state: { isPublicDetail: true } });
  };

  const chunk = <T,>(array: T[], size: number): T[][] => {
    const chunks: T[][] = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  };

  const groupedProducts: ProductsList[][] = chunk(products, 3);
  const [bulletList, setBulletList] = useState(Array(groupedProducts.length).fill({ isNow: false }));

  useEffect(() => {
    setBulletList((prevBulletList) => {
      const newBulletList = Array(groupedProducts.length).fill({ isNow: false });
      newBulletList[0] = { isNow: true };
      return newBulletList;
    });
  }, [groupedProducts.length]);

  useEffect(() => {
    if (flickingRef.current) {
      flickingRef.current.on(EVENTS.CHANGED, (event) => {
        const updatedBullet = groupedProducts.map((_, index) => ({ isNow: index === event.index }));
        setBulletList(updatedBullet);
      });
    }
  }, [groupedProducts]);

  const onClickBullet = (index: number) => {
    if (flickingRef.current) {
      flickingRef.current.moveTo(index).catch(() => void 0);
    }
  };

  return (
    <>
      <div className="w-full bg-[#161E36]">
        <div className="w-full h-[30px] bg-gradient-to-b from-[#485781] to-[#161E36]"></div>
        <div className="px-4 pb-[30px] relative">
          <h2 className="text-[20px] font-semibold text-gray-0 text-white">회원님의 맞춤차량 ✨</h2>
          {products.map((item, index) => (
            <link rel="preload" href={item.representImageUrl} as="image" key={index} />
          ))}
          {!!products.length && (
            <Flicking
              ref={flickingRef}
              align="prev"
              renderOnlyVisible={true}
              moveType="strict"
              circularFallback="bound"
              className="z-0"
              panelsPerView={1}
            >
              {groupedProducts.map((group, groupIndex) => (
                <div key={groupIndex} className="sm680:flex gap-[12px] w-full py-5 mr-4">
                  {group.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => fetchDetail(item.id)}
                      className="mb-4 text-left cursor-pointer sm680:w-[calc(33.333%-8px)] flex-shrink-0 flex sm680:flex-col"
                    >
                      <LazyLoad offset={100} className="relative">
                        <div className="rounded-[8px] sm280:w-[140px] sm280:h-[105px] w-[154px] h-[116px] sm680:w-full">
                          <img
                            loading="lazy"
                            className="rounded-lg w-full h-full object-cover"
                            src={item?.representImageUrl}
                            alt="트럭 사진"
                            style={{ pointerEvents: 'none' }}
                          />
                        </div>
                        <div className="absolute bottom-0 left-0 w-full h-[40px] p-3 rounded-b-lg bg-gradient-to-t from-[rgba(0,0,0,0.7)] to-[rgba(0,0,0,0)]">
                          <div className="absolute bottom-0 left-0 pb-2 px-[10px]">
                            <span className="text-gray-0 text-[14px] font-medium bottom-[10px] left-[10px]">
                              매물번호 {item?.productsNumber}
                            </span>
                          </div>
                        </div>
                      </LazyLoad>
                      <div className="ml-3 sm680:ml-0 flex flex-col justify-between">
                        <div>
                          <div className="font-semibold text-gray-0 text-[14px] leading-[17px] my-2 sm280:my-0 line-clamp-2 sm680:line-clamp-1">
                            {item.truckName}
                          </div>
                          <div className="text-gray-0 my-1 text-[12px] leading-[15px] line-clamp-2 break-keep">
                            {formatShortYear(item.firstRegistrationDate.split('-')?.[0])}/
                            {item.firstRegistrationDate.split('-')?.[1]}식 • {item.distance}만km •{' '}
                            {item.loadedInnerLength}m
                          </div>
                        </div>

                        {item.price && (
                          <div className="text-gray-0 line-clamp-1">
                            <span className="font-semibold text-[18px]">{formatPrice(item.price.toString())}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </Flicking>
          )}

          <div className="absolute bottom-0 left-[50%] transform -translate-x-1/2 flex items-end justify-center py-8 cursor-pointer z-1">
            {bulletList.map((data, index) => (
              <div
                key={index}
                className={`w-2 h-2 rounded-full cursor-pointer m-1 ${data.isNow ? 'bg-gray-0' : 'bg-gray-8'}`}
                onClick={() => onClickBullet(index)}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecommendProducts;
