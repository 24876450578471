import { PAGE_NAMES } from '@/const/common';
import { BEFORE_SALE } from '@/const/productStatus';
import { PRODUCT_TYPE_SPEED, SALES_TYPE_ASSURANCE, SALES_TYPE_CONSIGNMENT, SALES_TYPE_NORMAL } from '@/const/products';

export const getPageName = (data: ProductDetailResponse): keyof typeof PAGE_NAMES => {
  if (data.status.code === BEFORE_SALE) {
    if (!data.model) return 'model';
    if (!data.tons) return 'tons';
    if (!data.loaded || !data.loadedInnerLength) return 'loaded';
    if (!data.axis) return 'axis';
    if (!data.type) return 'price-trend';
    if (!data.transmission || !data.distance || !data.fuel || !data.power || !data.productsImage)
      return 'additional-info';
    if (
      !data?.productsImage?.frontSideImageUrl &&
      !data?.productsImage?.backSideImageUrl &&
      !data?.productsImage?.frontImageUrl
    )
      return 'additional-info';
    if (
      !data?.productsImage?.frontSideImageUrl ||
      !data?.productsImage?.backSideImageUrl ||
      !data?.productsImage?.frontImageUrl
    )
      return 'photo';
    if (!data.price) return 'price';
  }
  return 'price';
};

export const isLengthOnlyLoadedType = (data: any) => {
  const validCodes = ['CARGO', 'TANKLORRY', 'TRAILER', 'LADDER', 'AUTOLADDER', 'TONGS'];
  return validCodes.includes(data?.loaded?.code);
};

export const isAllLoadedInfoAvailable = (data: any) => {
  return !!data?.loadedInnerLength && !!data?.loadedInnerArea && !!data?.loadedInnerHeight;
};

export const shouldShowOnlyLength = (data: any) => {
  return (
    !isAllLoadedInfoAvailable(data) &&
    (isLengthOnlyLoadedType(data) || data?.loaded?.code === '' || data?.loaded === null)
  );
};

export const getProductType = (
  type?: EnumPresenter,
  salesType?: EnumPresenter | null,
  statusOfSpeedProduct?: EnumPresenter,
) => {
  if (type?.code == PRODUCT_TYPE_SPEED) {
    return statusOfSpeedProduct;
  } else {
    return getProductTypeBySalesType(salesType?.code);
  }
};

export const getProductTypeBySalesType = (salesType?: string) => {
  switch (salesType) {
    case SALES_TYPE_NORMAL:
      return { code: salesType, desc: '직거래' };
    case SALES_TYPE_CONSIGNMENT:
      return { code: salesType, desc: '판매대행' };
    case SALES_TYPE_ASSURANCE:
      return { code: salesType, desc: '직트럭 상품용' };
  }
};

export const isShowTypeChangeButtonAndMoreMenu = (salesType?: string) => {
  return SALES_TYPE_CONSIGNMENT != salesType;
};
