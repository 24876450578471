import React from 'react';

import CheckBoxComponent from '@/components/Common/Input/CheckBox/CheckBoxComponent';
import { ListItemText, MenuItem } from '@mui/material';

export interface OptionType {
  code: string;
  desc: string;
  count?: number;
}

interface CheckBoxListProps {
  optionData: any[];
  selectedData: any[];
  onClickItem: (item: OptionType) => void;
  horizontal?: boolean;
  grid?: number;
}

const CheckBoxList = ({ optionData, selectedData, onClickItem, horizontal = false, grid }: CheckBoxListProps) => {
  const getGridClasses = (grid: number) => {
    switch (grid) {
      case 1:
        return 'grid-cols-1';
      case 2:
        return 'grid-cols-2';
      case 3:
        return 'grid-cols-3';
      case 4:
        return 'grid-cols-4';
      default:
        return '';
    }
  };

  return (
    <div className={`${horizontal ? 'flex gap-2' : ''} ${grid ? `grid ${getGridClasses(grid)}` : ''}`}>
      <>
        {optionData?.map((item, index) => (
          <MenuItem
            key={index}
            value={item.code}
            onClick={(e) => onClickItem(item)}
            className="items-center"
            sx={{
              padding: '0px',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            disableRipple
          >
            <CheckBoxComponent
              checked={selectedData.some((selectedItem) => selectedItem.code === item.code)}
            ></CheckBoxComponent>
            <ListItemText
              primary={
                <div className={`${horizontal ? '' : 'flex'} items-center pl-[6px]`}>
                  <span className="flex-1 text-base text-gray-9">{item.desc}</span>
                  {(!!item.count || item.count === 0) && (
                    <span>
                      <span className="text-gray-5 text-sm ml-1">{item.count}</span>
                    </span>
                  )}
                </div>
              }
            />
          </MenuItem>
        ))}
      </>
    </div>
  );
};

export default CheckBoxList;
