import React, { SVGProps } from 'react';

const SvgAccountsReceivableColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="#737373"
      fillRule="evenodd"
      d="M21.36 3.955h.556a3.492 3.492 0 1 0-1.103 3.34c.208-.185.524-.215.737-.035.212.18.24.5.038.691a4.5 4.5 0 1 1 1.354-3.996h.618a.2.2 0 0 1 .16.32l-1.1 1.467a.2.2 0 0 1-.32 0l-1.1-1.467a.2.2 0 0 1 .16-.32ZM19 2.9a.5.5 0 0 0-1 0v1.947c0 .2.1.388.267.5l1.456.97a.5.5 0 1 0 .554-.833L19 4.633V2.9Z"
      clipRule="evenodd"
    />
    <path
      fill="#BEBEBE"
      fillRule="evenodd"
      d="M18.506 9.186c1.275 0 2.426-.53 3.244-1.382A10.952 10.952 0 0 1 23 12.9c0 6.075-4.925 11-11 11s-11-4.924-11-11c0-6.075 4.925-11 11-11 .941 0 1.855.119 2.727.341a4.5 4.5 0 0 0 3.779 6.944Zm-9.994.234a.75.75 0 0 0-1.427.461l.558 1.726H6.75a.75.75 0 0 0 0 1.5H8.128l1.057 3.27a.75.75 0 0 0 1.427 0L12 12.09l1.386 4.289a.75.75 0 0 0 1.427 0l1.057-3.27h1.38a.75.75 0 0 0 0-1.5h-.895l.558-1.727a.75.75 0 1 0-1.427-.461l-1.387 4.288-1.386-4.288a.75.75 0 0 0-1.427 0l-1.386 4.288L8.512 9.42Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgAccountsReceivableColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgAccountsReceivableColorIcon;
