import React, { SVGProps } from 'react';

const SvgOtherExpensesColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <rect width={20} height={3} x={2} y={2} fill="#737373" rx={1.5} />
    <path fill="#EFEFEF" d="M4 3.5h16V22l-2-1-2 1-2-1-2 1-2-1-2 1-2-1-2 1V3.5Z" />
    <path
      fill="#737373"
      fillRule="evenodd"
      d="M7.568 7.998a.75.75 0 0 1 .944.483L9.9 12.77l1.386-4.289a.75.75 0 0 1 1.427 0l1.386 4.289 1.387-4.289a.75.75 0 1 1 1.427.462l-.558 1.726h.895a.75.75 0 0 1 0 1.5h-1.38l-1.057 3.27a.75.75 0 0 1-1.427 0l-1.386-4.289-1.387 4.289a.75.75 0 0 1-1.427 0l-1.057-3.27H6.75a.75.75 0 0 1 0-1.5h.893l-.558-1.726a.75.75 0 0 1 .483-.945Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgOtherExpensesColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgOtherExpensesColorIcon;
