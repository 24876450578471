import React, { SVGProps } from 'react';

const SvgOilingColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="#34A853"
      fillRule="evenodd"
      d="M6 1.9a2 2 0 0 0-2 2v17.5H3a.5.5 0 0 0-.5.5v1H18v-1a.5.5 0 0 0-.5-.5h-1v-8.608h.773a.1.1 0 0 1 .1.1v3.884c0 .062.004.123.014.184.213 1.406 1.367 2.108 2.462 2.109 1.086 0 2.242-.686 2.525-2.052.017-.082.026-.165.026-.25v-5.052a1.9 1.9 0 0 0-.556-1.344l-1.915-1.914a.1.1 0 0 1-.029-.07V4.8a.9.9 0 1 0-1.8 0v3.586c0 .503.2.987.556 1.343l1.915 1.914a.1.1 0 0 1 .029.071v4.987c-.1.376-.404.567-.751.567-.342 0-.606-.179-.676-.541v-3.836a1.9 1.9 0 0 0-1.9-1.9H16.5V3.9a2 2 0 0 0-2-2H6Zm1.084 2a1 1 0 0 0-1 1v3.737a1 1 0 0 0 1 1h6.333a1 1 0 0 0 1-1V4.9a1 1 0 0 0-1-1H7.084ZM8.8 15.2l1.45-2.4 1.45 2.4c.755 1.248 1.33 2.885.298 3.917-.393.394-.955.684-1.748.684-.794 0-1.362-.287-1.76-.682-1.035-1.029-.445-2.67.31-3.92Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgOilingColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgOilingColorIcon;
