import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import RoundBadge from '@/components/Common/Badge/RoundBadge';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import ConfirmExitPopup from '@/components/Products/ConfirmExitPopup';
import VehicleImageUploader from '@/components/Products/Sale/VehicleImageUploader';
import { SALESTYPE } from '@/const/products';
import { SELL_CAR_DEFAULT_TITLE } from '@/const/title';
import useUpdateProductFormMutation from '@/hooks/query/useUpdateProductFormMutation';
import useBlockNavigation from '@/hooks/useBlockNavigation';
import useExitConfirmationPopup from '@/hooks/useExitConfirmationPopup';
import { productsFormAtom, useResetProductsForm } from '@/store/products';

type SalesTypeKey = keyof typeof SALESTYPE;

const PhotoUploadForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [productFormData] = useAtom(productsFormAtom);
  const setProductFormData = useSetAtom(productsFormAtom);
  const resetProductsForm = useResetProductsForm();

  const [prevData, setPrevData] = useState<ProductDetailResponse | null>(null);
  const [isShowGuidePopup, setIsShowGuidePopup] = useState(true);

  const getChangedData = () => {
    const changedData: Partial<ProductRegisterRequest> = {};

    if (prevData?.productsImage?.certificateImageUrl !== productFormData.productsImage?.certificateImageUrl) {
      changedData.certificateImageUrl = productFormData.productsImage?.certificateImageUrl;
    }
    if (prevData?.productsImage?.frontSideImageUrl !== productFormData.productsImage?.frontSideImageUrl) {
      changedData.frontSideImageUrl = productFormData.productsImage?.frontSideImageUrl;
    }
    if (prevData?.productsImage?.backSideImageUrl !== productFormData.productsImage?.backSideImageUrl) {
      changedData.backSideImageUrl = productFormData.productsImage?.backSideImageUrl;
    }
    if (prevData?.productsImage?.frontImageUrl !== productFormData.productsImage?.frontImageUrl) {
      changedData.frontImageUrl = productFormData.productsImage?.frontImageUrl;
    }
    if (prevData?.productsImage?.backImageUrl !== productFormData.productsImage?.backImageUrl) {
      changedData.backImageUrl = productFormData.productsImage?.backImageUrl;
    }
    if (prevData?.productsImage?.tireImageUrl !== productFormData.productsImage?.tireImageUrl) {
      changedData.tireImageUrl = productFormData.productsImage?.tireImageUrl;
    }
    if (prevData?.productsImage?.engineImageUrl !== productFormData.productsImage?.engineImageUrl) {
      changedData.engineImageUrl = productFormData.productsImage?.engineImageUrl;
    }
    if (prevData?.productsImage?.insideImageUrl !== productFormData.productsImage?.insideImageUrl) {
      changedData.insideImageUrl = productFormData.productsImage?.insideImageUrl;
    }
    if (prevData?.productsImage?.dashboardImageUrl !== productFormData.productsImage?.dashboardImageUrl) {
      changedData.dashboardImageUrl = productFormData.productsImage?.dashboardImageUrl;
    }
    if (prevData?.productsImage?.sheetImageUrl !== productFormData.productsImage?.sheetImageUrl) {
      changedData.sheetImageUrl = productFormData.productsImage?.sheetImageUrl;
    }

    const optionImages = productFormData.productsImage?.optionImageUrl || [];

    const prevOptionImages = prevData?.productsImage?.optionImageUrls || [];
    if (!arraysEqual(optionImages, prevOptionImages)) {
      changedData.optionImageUrls = optionImages;
    }

    return changedData;
  };

  function arraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }

  const updateProductTemporarySaveMutation = useUpdateProductFormMutation({});

  const checkForChanges = () => {
    return !!getChangedData();
  };

  const saveChanges = () => {
    const changedData = getChangedData();

    if (Object.keys(changedData).length > 0) {
      const request = {
        id: Number(productFormData?.id),
        certificateImageUrl: changedData.certificateImageUrl,
        frontSideImageUrl: changedData.frontSideImageUrl,
        backSideImageUrl: changedData.backSideImageUrl,
        frontImageUrl: changedData.frontImageUrl,
        backImageUrl: changedData.backImageUrl,
        tireImageUrl: changedData.tireImageUrl,
        engineImageUrl: changedData.engineImageUrl,
        insideImageUrl: changedData.insideImageUrl,
        dashboardImageUrl: changedData.dashboardImageUrl,
        sheetImageUrl: changedData.sheetImageUrl,
        optionImageUrls: changedData.optionImageUrls,
      };
      updateProductTemporarySaveMutation.mutate(request);
    }
  };

  const { isShowExitConfirmPopup, openExitConfirmPopup, closeExitConfirmPopup, confirmExit } = useExitConfirmationPopup(
    checkForChanges,
    saveChanges,
    resetProductsForm,
  );

  useBlockNavigation(openExitConfirmPopup);

  useEffect(() => {
    setPrevData(productFormData);
  }, []);

  const updateProductNextStepMutation = useUpdateProductFormMutation({
    url: `/products/sales/price/${id}`,
  });

  const handleClickNext = () => {
    const changedData = getChangedData();

    if (Object.keys(changedData).length > 0) {
      const request = {
        id: Number(productFormData?.id),
        certificateImageUrl: changedData.certificateImageUrl,
        frontSideImageUrl: changedData.frontSideImageUrl,
        backSideImageUrl: changedData.backSideImageUrl,
        frontImageUrl: changedData.frontImageUrl,
        backImageUrl: changedData.backImageUrl,
        tireImageUrl: changedData.tireImageUrl,
        engineImageUrl: changedData.engineImageUrl,
        insideImageUrl: changedData.insideImageUrl,
        dashboardImageUrl: changedData.dashboardImageUrl,
        sheetImageUrl: changedData.sheetImageUrl,
        optionImageUrls: changedData.optionImageUrls,
      };
      updateProductNextStepMutation.mutate(request);
    } else {
      navigate(`/products/sales/price/${id}`, { replace: true });
    }
  };

  useEffect(() => {
    if (productFormData.id === 0) {
      apiManager
        .get('/api/v1/products/' + id)
        .then((response) => {
          const responseData: ProductDetailResponse = response.data;
          setProductFormData(responseData);
        })
        .catch(() => {});
    }
  }, [productFormData]);

  const isDisabledNextBtn = useMemo(() => {
    const images = productFormData.productsImage;
    return !images?.frontSideImageUrl || !images?.backSideImageUrl || !images?.frontImageUrl;
  }, [productFormData]);

  return (
    <>
      <MenuHeader title={SALESTYPE[productFormData.type?.code as SalesTypeKey] || SELL_CAR_DEFAULT_TITLE} />
      <div className="flex flex-col w-full pt-[60px] p-4">
        <div className="py-4 my-2 pb-24">
          <div className="flex justify-between">
            <h3 className="text-2xl font-bold text-gray-8 break-keep">차량 사진을 등록해주세요.</h3>
            <RoundBadge text="8/9" />
          </div>
          <div className="mt-4 mb-[30px]">
            <VehicleImageUploader productInfo={productFormData} setProductInfo={setProductFormData} />
          </div>
          <DualFooterButton
            leftButtonText="이전"
            onClickLeftButton={() => navigate(`/products/sales/detail-info/${id}`, { replace: true })}
            rightButtonText="다음(판매가격)"
            disabledRight={isDisabledNextBtn}
            onClickRightButton={handleClickNext}
          />
        </div>
      </div>

      <BasicPopup
        isShow={isShowGuidePopup}
        title="삭제 대상 이미지"
        textContent={
          '<div class="leading-[20px]">텍스트 삽입 및 번호판을 블러 처리한 <br class="sm280:hidden"/>사진 업로드 시 무통보 삭제될 수 있습니다.<div>'
        }
        componentContent={
          <>
            <img
              src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/delete_standard_img.jpg"
              className="min-w-[184px]"
              alt="delete_standard_img"
            ></img>
            <div className="text-[16px] font-medium text-red pt-2">[삭제 대상 이미지 예시]</div>
          </>
        }
        textRightBtn="확인"
        onClickRightBtn={() => setIsShowGuidePopup(false)}
      />

      <ConfirmExitPopup isShow={isShowExitConfirmPopup} onClose={closeExitConfirmPopup} onConfirm={confirmExit} />
    </>
  );
};

export default PhotoUploadForm;
