import React, { SVGProps } from 'react';

const SvgUploadOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke={props.color || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m8 8 4-4 4 4M12 4v11M4 13v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-5"
    />
  </svg>
);
SvgUploadOutlineIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgUploadOutlineIcon;
