import React from 'react';

import { ZigtruckRoundedColorIcon } from '@/components/Icon';

interface TopBannerProps {
  isShow: boolean;
}

function TopBanner({ isShow }: TopBannerProps) {
  const onClickBtn = () => {
    window.open('intent://www.zigtruck.io/#Intent;package=com.zigtruck.android;scheme=https;end');
  };

  return (
    <>
      {isShow && (
        <div
          className="fixed flex justify-between items-center w-full h-[48px] top-0 left-0 bg-gray-0 mx-auto left-0 right-0 my-0 max-w-[720px] min-w-[280px] px-4 py-2 z-30"
          onClick={onClickBtn}
        >
          <div className="flex justify-center items-center">
            <ZigtruckRoundedColorIcon width={32} height={32}></ZigtruckRoundedColorIcon>
            <div className="mx-[10px] sm280:mx-[6px] text-[15px] sm280:text-[12px] sm680:text-[16px] font-semibold">
              앱으로 빠르게 직거래하세요!
            </div>
          </div>

          <div className="bg-blue-1 text-primary font-semibold py-2 px-3 xs:px-2 xxs:px-2 rounded-full sm280:text-[10px] text-[12px] sm680:text-[14px] cursor-pointer">
            앱 다운로드
          </div>
        </div>
      )}
    </>
  );
}

export default TopBanner;
