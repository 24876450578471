import { atom } from 'jotai';

import { getOtherExpensesCategory } from '@/api/drive/getDrive';

export const categoriesAtom = atom<OtherExpensesCategory[]>([]);

export const fetchCategoriesAtom = atom(null, async (get, set, driveVehicleInfoId: URLSearchParams) => {
  if (!driveVehicleInfoId) return;

  try {
    const defaultCategories = await getOtherExpensesCategory(driveVehicleInfoId);
    set(categoriesAtom, defaultCategories);
  } catch (error) {
    console.error('기본 카테고리 불러오기 실패:', error);
    set(categoriesAtom, []);
  }
});

// 카테고리 추가 atom
export const addCategoryAtom = atom(null, (get, set, newCategory: OtherExpensesCategory) => {
  const currentCategories = get(categoriesAtom);
  set(categoriesAtom, [...currentCategories, newCategory]);
});

// 카테고리 삭제 atom
export const deleteCategoryAtom = atom(null, (get, set, categoryId: number) => {
  const currentCategories = get(categoriesAtom);
  const updatedCategories = currentCategories.filter((category) => category.id !== categoryId);
  set(categoriesAtom, updatedCategories);
});

// 카테고리 업데이트 atom
export const updateCategoryAtom = atom(null, (get, set, updatedCategory: OtherExpensesCategory) => {
  const currentCategories = get(categoriesAtom);
  const updatedCategories = currentCategories.map((category) =>
    category.id === updatedCategory.id ? updatedCategory : category,
  );
  set(categoriesAtom, updatedCategories);
});
