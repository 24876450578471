import React, { SVGProps } from 'react';

const SvgMoneyCircleBoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 30 30" {...props}>
    <path
      fill={props.color || 'currentColor'}
      fillRule="evenodd"
      d="M14.75 28.5c7.594 0 13.75-6.156 13.75-13.75S22.344 1 14.75 1 1 7.156 1 14.75 7.156 28.5 14.75 28.5Zm-4.113-17.975a.937.937 0 1 0-1.784.576l.698 2.158H8.438a.937.937 0 1 0 0 1.875H10.156l1.321 4.088a.938.938 0 0 0 1.784 0l1.733-5.36 1.733 5.36a.938.938 0 0 0 1.784 0l1.321-4.088h1.725a.938.938 0 0 0 0-1.875h-1.119l.698-2.158a.938.938 0 0 0-1.784-.576l-1.733 5.36-1.733-5.36a.937.937 0 0 0-1.784 0l-1.733 5.36-1.732-5.36Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgMoneyCircleBoldIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgMoneyCircleBoldIcon;
