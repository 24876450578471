import dayjs from 'dayjs';
import React from 'react';

import { ChevronRightIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { EXPENSE, EXPENSE_UNCLASSIFIED } from '@/const/drive';
import { formatNumber, formatNumberWithNegative, getDayOfWeek } from '@/utils/common';

interface OtherExpenseItemBoxProps {
  data: OtherExpenseHistory;
}

const OtherExpenseItemBox = ({ data }: OtherExpenseItemBoxProps) => {
  const isExpenseItem = (otherExpensesCategoryType: EnumPresenter) => {
    return otherExpensesCategoryType?.code === EXPENSE || otherExpensesCategoryType?.code === EXPENSE_UNCLASSIFIED;
  };

  return (
    <ul className="grid grid-cols-1 gap-3 w-full">
      <li className="flex flex-col justify-between gap-3 p-3 border rounded-lg px-4">
        <div className="items-center pb-3 border-b-[1px]">
          <div className="flex flex-1 justify-between items-center cursor-pointer">
            <p className="text-[14px] font-medium text-gray-6 pb-3">
              {dayjs(data.baseDay).date()}일 {getDayOfWeek(data.baseDay)}
            </p>
            <ChevronRightIcon color={colors.gray[6]}></ChevronRightIcon>
          </div>
          <p className={`font-medium text-[16px] ${data.totalCost < 0 ? 'text-gray-7' : 'text-primary'}`}>
            {formatNumberWithNegative(String(data.totalCost))}원
          </p>
        </div>

        {data?.data?.map((item, index) => (
          <div className="flex flex-wrap items-center justify-between text-base cursor-pointer" key={index}>
            <span className="text-gray-7 flex-1 pr-4 text-ellipsis break-all line-clamp-1">
              {item.categoryName} {item.contents && <span className="text-gray-5">|</span>} {item.contents}
            </span>
            <span
              className={`flex-2 text-ellipsis break-all line-clamp-1 min-w-fit
                ${isExpenseItem(item.otherExpensesCategoryType) ? 'text-gray-6' : 'text-gray-8 '}
                `}
            >
              {isExpenseItem(item.otherExpensesCategoryType) ? '-' : '+'}
              {formatNumber(String(item.price))}원
            </span>
          </div>
        ))}
      </li>
    </ul>
  );
};

export default OtherExpenseItemBox;
