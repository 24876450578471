import apiManager from './AxiosInstance';
import { OneStopServiceRequestData } from '@/components/Products/Sale/OneStopServiceRequest';
import { MULTIPART_FORM_DATA } from '@/const/headers';
import { SALES_TYPE_ASSURANCE } from '@/const/products';

export const getTerm = () => {
  return apiManager.get('/api/v1/public/terms');
};

export const getPublicPriceTrend = async (queryParams: URLSearchParams) => {
  const res = await apiManager.get('/api/v1/public/prices', { params: queryParams });
  return res.data;
};

export const getPriceTrend = async (queryParams: URLSearchParams) => {
  const res = await apiManager.get('/api/v1/prices', { params: queryParams });
  return res.data;
};

export const getProductList = (queryParams: URLSearchParams) => {
  return apiManager.get('/api/v1/public/products?', { params: queryParams });
};

export const getNotifications = () => {
  return apiManager.get('/api/v1/notifications');
};

export const deleteNotification = async (id: number): Promise<any> => {
  const { data } = await apiManager.delete(`/api/v1/notifications/${id}`);
  return id;
};

export const getInterestProductsNotificationSettings = () => {
  return apiManager.get('/api/v1/interest-product-notification-settings');
};

export const deleteInterestProductNotificationSettings = async (id: number): Promise<any> => {
  return await apiManager.delete(`/api/v1/interest-product-notification-settings/${id}`);
};
export const postInterestProductNotificationSettings = (request: InterestProductNotificationSettingsRequest) => {
  return apiManager.post('/api/v1/interest-product-notification-settings', request);
};

export const postOneStopService = (request: OneStopServiceRequestData) => {
  return apiManager.post('/api/v1/one-stop-services', request);
};

export const patchProducts = (request: ProductRegisterRequest) => {
  return apiManager.patch(`/api/v2/products/${request.id}`, {
    modelId: request?.modelId,
    tons: request?.tons,
    loaded: request?.loaded,
    loadedInnerLength: request?.loadedInnerLength,
    loadedInnerArea: request?.loadedInnerArea,
    loadedInnerHeight: request?.loadedInnerHeight,
    axis: request?.axis,
    type: request?.type,
    transmission: request?.transmission,
    distance: request?.distance,
    fuel: request?.fuel,
    power: request?.power,
    color: request?.color,
    garage: request?.garage,
    accident: request?.accident,
    accidentContents: request?.accidentContents,
    maintenanceData: request?.maintenanceData,
    transportGoods: request?.transportGoods,
    transportStartLocate: request?.transportStartLocate,
    transportEndLocate: request?.transportEndLocate,
    tireStatus: request?.tireStatus,
    carOption: request?.carOption,
    detailContent: request?.detailContent,

    certificateImageUrl: request.certificateImageUrl,
    frontSideImageUrl: request.frontSideImageUrl,
    backSideImageUrl: request.backSideImageUrl,
    frontImageUrl: request.frontImageUrl,
    backImageUrl: request.backImageUrl,
    tireImageUrl: request.tireImageUrl,
    engineImageUrl: request.engineImageUrl,
    insideImageUrl: request.insideImageUrl,
    dashboardImageUrl: request.dashboardImageUrl,
    sheetImageUrl: request.sheetImageUrl,
    optionImageUrls: request.optionImageUrls,
    year: request?.year,
    price: request?.price,
    status: request?.status,
  });
};

export const patchProductsStatus = (request: { productId: number; status: string }) => {
  return apiManager.patch(`/api/v1/products/${request.productId}/status`, { status: request.status });
};

export const getJobList = (queryParams?: URLSearchParams) => {
  return apiManager.get('/api/v1/public/job', { params: queryParams });
};

export const getJobFilterInfo = () => {
  return apiManager.get('/api/v1/public/job/filter-info');
};

export const downloadContractFile = (request: { contractId: number; formData: FormData }) => {
  return apiManager.post(`/api/v1/contracts/${request.contractId}/download`, request.formData, {
    headers: {
      'Content-Type': MULTIPART_FORM_DATA,
    },
  });
};

export const postDriveVehicleInfo = (request: VehicleInfoRequest) => {
  return apiManager.post('/api/v1/drive-vehicle-info', request);
};

export const patchDriveVehicleInfo = (request: { id: number; requestData: VehicleInfoRequest }) => {
  return apiManager.patch(`/api/v1/drive-vehicle-info/${request.id}`, request.requestData);
};

export const getDriveMyVehicleInfo = () => {
  return apiManager.get('/api/v1/drive-vehicle-info/my');
};

export const getFuelingHistory = (queryParams?: URLSearchParams) => {
  return apiManager.get('/api/v1/fueling-history', { params: queryParams });
};

export const postDriveHistory = (request: DriveHistoryForm) => {
  return apiManager.post(`/api/v1/drive-history`, request);
};

export const patchDriveHistory = (request: { id: number; body: DriveHistoryForm }) => {
  return apiManager.patch(`/api/v1/drive-history/${request.id}`, request.body);
};

export const deleteFuelingHistory = async (id: number): Promise<any> => {
  return apiManager.delete(`/api/v1/fueling-history/${id}`);
};

export const postFuelingHistoryReceipt = (file: File) => {
  return apiManager.post(
    '/api/v1/fueling-history/receipt',
    { file: file },
    { headers: { 'Content-Type': MULTIPART_FORM_DATA } },
  );
};

export const postFuelingHistory = (request: FuelingForm) => {
  return apiManager.post('/api/v1/fueling-history', request);
};

export const patchFuelingHistory = (request: { id: string; body: FuelingForm }) => {
  return apiManager.patch(`/api/v1/fueling-history/${request.id}`, request.body);
};

export const getDriveHistory = (queryParams: URLSearchParams) => {
  return apiManager.get('/api/v2/drive-history', { params: queryParams });
};

export const deleteTransportInfo = (id: number) => {
  return apiManager.delete(`/api/v1/drive-history/${id}`);
};

export const patchTransportInfo = (request: { id: string; body: TransportInfoRequest }) => {
  return apiManager.patch(`/api/v1/transport-info/${request.id}`, request.body);
};

export const getTransportInfoOutstandingAmount = (queryParams: URLSearchParams) => {
  return apiManager.get('/api/v1/transport-info/outstanding-amount', { params: queryParams });
};

export const patchTransportInfoOutstandingAmount = (request: TransportInfoOutstandingAmountModifyRequest) => {
  return apiManager.patch(`/api/v1/transport-info/outstanding-amount`, request);
};

export const getLicenseList = (queryParams?: URLSearchParams) => {
  return apiManager.get('/api/v1/public/license', { params: queryParams });
};

export const postProductInquiry = (request: ProductPurchasingInquiryRequest) => {
  return apiManager.post('/api/v1/public/product-inquiry', request);
};

export const postInterestProducts = (productId: number) => {
  return apiManager.post('/api/v1/interest-products', { productId });
};

export const deleteInterestProducts = (interestProductId: number) => {
  return apiManager.delete(`/api/v1/interest-products/${interestProductId}`);
};

export const getInterestProducts = async (productsStatus?: string) => {
  const res = await apiManager.get('/api/v1/interest-products', { params: { productsStatus } });
  return res.data;
};

export const patchMemberNotifications = (memberNotificationsId: number) => {
  return apiManager.patch(`/api/v1/member-notifications/${memberNotificationsId}`);
};

export const postProductInquiryCall = (productId: number) => {
  return apiManager.post(`/api/v1/products/${productId}/inquiry/call`);
};

export const getSearchHistory = (queryParams?: URLSearchParams) => {
  return apiManager.get('/api/v1/search-history', { params: queryParams });
};

export const deleteSearchHistory = (searchHistoryId: number) => {
  return apiManager.delete(`/api/v1/search-history/${searchHistoryId}`);
};

export const getNotice = async (page: number) => {
  const res = await apiManager.get('/api/v1/notice', { params: { page } });
  return res.data;
};

export const getNoticeDetail = async (id: string | undefined) => {
  const res = await apiManager.get(`/api/v1/notice/${id}`);
  return res.data;
};

export const patchProductsAfterPriceSearch = async (productId: string | undefined) => {
  const res = await apiManager.patch(`/api/v1/products/${productId}/after-price-search`);
  return res.data;
};

export const getBanner = async () => {
  const res = await apiManager.get('/api/v1/public/banners');
  return res.data;
};

export const getAssuranceProducts = async () => {
  const res = await apiManager.get(`/api/v1/public/products?salesType=${SALES_TYPE_ASSURANCE}`);
  return res.data?.data;
};

export const getRecommendProducts = async () => {
  const res = await apiManager.get('/api/v1/products/recommend');
  return res.data;
};

export const postReferralCode = (request: { referralCode: string }) => {
  return apiManager.post('/api/v1/referral-codes', request);
};
