import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { isUnderFourTons } from '../../../../utils/common';
import RoundBadge from '@/components/Common/Badge/RoundBadge';
import DualFooterButton from '@/components/Common/Button/DualFooterButton';
import RadioBoxList from '@/components/Common/Input/Radio/RadioBoxList';
import MenuHeader from '@/components/Header/MenuHeader';
import ConfirmExitPopup from '@/components/Products/ConfirmExitPopup';
import NoAxisWarning from '@/components/Products/NoAxisWarning';
import { SALESTYPE } from '@/const/products';
import { SELL_CAR_DEFAULT_TITLE } from '@/const/title';
import { useProductEnumContext } from '@/contexts/Products/ProductEnumContext';
import useUpdateProductFormMutation from '@/hooks/query/useUpdateProductFormMutation';
import useBlockNavigation from '@/hooks/useBlockNavigation';
import useExitConfirmationPopup from '@/hooks/useExitConfirmationPopup';
import useFetchProductData from '@/hooks/useFetchProductData';
import { productsFormAtom, useResetProductsForm } from '@/store/products';

type SalesTypeKey = keyof typeof SALESTYPE;

const AxisForm = () => {
  const { productEnum } = useProductEnumContext();
  const navigate = useNavigate();
  const { id } = useParams();

  const [productFormData] = useAtom(productsFormAtom);
  const setProductFormData = useSetAtom(productsFormAtom);
  const resetProductsForm = useResetProductsForm();

  const [prevData, setPrevData] = useState<ProductDetailResponse | null>(null);

  const checkForChanges = () => {
    return prevData?.axis?.code !== productFormData?.axis?.code;
  };

  const saveChanges = () => {
    const request = { id: Number(productFormData?.id), axis: productFormData?.axis?.code };
    updateProductTemporarySaveMutation.mutate(request);
  };

  const { isShowExitConfirmPopup, openExitConfirmPopup, closeExitConfirmPopup, confirmExit } = useExitConfirmationPopup(
    checkForChanges,
    saveChanges,
    resetProductsForm,
  );

  useBlockNavigation(openExitConfirmPopup);

  useFetchProductData(id, productFormData.id === 0);

  useEffect(() => {
    setPrevData(productFormData);
    if (isUnderFourTons(productFormData.tons)) {
      setProductFormData({ ...productFormData, axis: { code: 'NONE', desc: '없음' } });
    }
  }, []);

  const updateProductTemporarySaveMutation = useUpdateProductFormMutation({});

  const updateProductNextStepMutation = useUpdateProductFormMutation({
    url: `/products/sales/price-trend/${id}`,
  });

  const handleClickNext = () => {
    const hasChanges = prevData?.axis?.code !== productFormData?.axis?.code;

    if (hasChanges) {
      const request = { id: Number(productFormData?.id), axis: productFormData?.axis?.code };
      updateProductNextStepMutation.mutate(request);
    } else {
      navigate(`/products/sales/price-trend/${id}`, { replace: true });
    }
  };

  const handleClickPrev = () => {
    navigate(`/products/sales/loaded/${id}`, { replace: true });
  };

  const handleClickOption = (item: OptionDataType, key: string) => {
    if (isUnderFourTons(productFormData?.tons)) {
      return;
    }
    setProductFormData({ ...productFormData, [key]: item } as ProductDetailResponse);
  };

  return (
    <>
      <MenuHeader title={SALESTYPE[productFormData.type?.code as SalesTypeKey] || SELL_CAR_DEFAULT_TITLE} />
      <div className="flex flex-col w-full pt-[60px] p-4">
        <div className="pt-6 text-gray-8">
          <div className="flex justify-between">
            <h3 className="text-2xl font-bold text-gray-8 break-keep">가변축을 선택해주세요.</h3>
            <RoundBadge text="4/9" />
          </div>

          <NoAxisWarning tons={productFormData?.tons} className="mt-5" />

          <div className="text-base pt-[30px] pb-24">
            <RadioBoxList
              optionData={productEnum?.axis || []}
              value={productFormData?.axis?.code || ''}
              onClickItem={(item) => handleClickOption(item, 'axis')}
              isDisabled={isUnderFourTons(productFormData?.tons)}
            />
          </div>
        </div>
        <DualFooterButton
          leftButtonText="이전"
          onClickLeftButton={handleClickPrev}
          rightButtonText="시세 조회하기"
          disabledRight={!productFormData?.axis}
          onClickRightButton={handleClickNext}
        />
      </div>
      <ConfirmExitPopup isShow={isShowExitConfirmPopup} onClose={closeExitConfirmPopup} onConfirm={confirmExit} />
    </>
  );
};

export default AxisForm;
