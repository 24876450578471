import React from 'react';

import ChevronRightIcon from '../Icon/ChevronRightIcon';
import { colors } from '@/const/colors';
import { EXPENSE, EXPENSE_UNCLASSIFIED } from '@/const/drive';
import { formatNumber } from '@/utils/common';

export interface HistoryItemViewProps {
  data: OtherExpenseHistoryWithCategory[];
}

const OtherExpenseItemView = ({ data }: HistoryItemViewProps) => {
  return (
    <>
      <div className="flex justify-between items-center pb-3">
        <div className="flex items-center">
          <span className="text-gray-8 font-semibold mr-[6px] text-lg">기타내역</span>
        </div>
        <ChevronRightIcon color={colors.gray[7]}></ChevronRightIcon>
      </div>

      <div className="rounded-lg bg-gray-1 px-4 py-2">
        {data?.map((item, index) => (
          <dl className="flex items-center py-[8px] font-normal" key={index}>
            <dt className="flex-1 text-gray-7 text-base pr-4 text-ellipsis break-all line-clamp-1">
              {item.otherExpensesCategory.name} {item.contents && <span className="text-gray-5">|</span>}{' '}
              {item.contents}
            </dt>
            <dd
              className={`flex items-center ${
                item.otherExpensesCategory.type.code === EXPENSE_UNCLASSIFIED ||
                item.otherExpensesCategory.type.code === EXPENSE
                  ? 'text-gray-6'
                  : 'text-gray-8'
              }`}
            >
              {item.otherExpensesCategory.type.code === EXPENSE_UNCLASSIFIED ||
              item.otherExpensesCategory.type.code === EXPENSE
                ? `-${formatNumber(item.price?.toString())}원`
                : `+${formatNumber(item.price?.toString())}원`}
            </dd>
          </dl>
        ))}
      </div>
    </>
  );
};

export default OtherExpenseItemView;
