import React, { useState } from 'react';

import LoadingComponent from '../LoadingComponent';
import { createProductsImages } from '@/api/products/createProductsImages';
import { DocumentArrowUpSolidIcon, PlusSmallIcon, XMarkIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { useToastContext } from '@/contexts/Common/ToastContext';

interface ImageUploaderProps {
  truckNumber: string;
  fileData: productImageType;
  setFileData: React.Dispatch<React.SetStateAction<productImageType>>;
  fileNameMaxLength?: number;
  imageType: string;
  isLoading?: boolean;
  icon?: string;
  accept?: string;
  isShowTitle?: boolean;
  isShowXmark?: boolean;
  isCoverGradient?: boolean;
}

const ImageUploader = ({
  truckNumber,
  imageType,
  fileData,
  setFileData,
  isLoading,
  icon,
  accept = '*',
  isShowTitle = true,
  isShowXmark = true,
  isCoverGradient = false,
}: ImageUploaderProps) => {
  const { showToast } = useToastContext();
  const [fileName, setFileName] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const openFileDialog = () => {
    if (loading) return;
    const inputElement = document.getElementById(`upload-button-${fileData.id}`) as HTMLInputElement;
    if (inputElement) {
      inputElement.click();
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) return;

    let newFile = e.target.files[0];
    uploadImage(newFile);
  };

  const uploadImage = async (file: File) => {
    const reader = new FileReader();
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('uploadFile', file);
    formData.append('truckNumber', truckNumber);
    formData.append('imageType', imageType);

    try {
      setLoading(true);
      reader.onload = () => {
        setFileName(file.name);
      };

      reader.readAsDataURL(file);
      const response = await createProductsImages(formData);
      if (response.data) {
        setFileData((prevData) => ({
          ...prevData,
          imageUrl: response.data?.url.toString(),
        }));
      }
    } catch (error) {
      showToast('이미지 업로드에 문제가 생겼어요.', 'error', 'bottom');
    } finally {
      setLoading(false);
    }
  };

  const onClickDelete = (e: React.MouseEvent) => {
    setFileName('');
    e.stopPropagation();

    setFileData((prevData) => ({
      ...prevData,
      imageUrl: '',
    }));
  };

  const handleClickLoding = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  const showImage = !!fileData?.imageUrl && icon === 'photo';
  const showFile = !!fileData?.imageUrl && icon === 'file';
  const showSampleImage = !!fileData?.sampleImageUrl;
  const showCoverGradient = !!isCoverGradient && !fileData?.imageUrl;
  const showImageTitle = isShowTitle && fileData.imageTitle && !!fileData?.imageUrl;

  const RequiredTag = () => (
    <div className="absolute top-1 left-1 bg-gray-1 text-red rounded-[4px] px-1 text-[10px] font-medium z-10">필수</div>
  );

  const GradientOverlay = ({ title }: { title: string }) => (
    <>
      <div className="absolute top-0 left-0 w-full h-full text-gray-0 rounded-b-lg bg-gradient-to-t from-[rgba(0,0,0,0.7)] to-[rgba(0,0,0,0)] z-0">
        <div className="flex flex-col items-center justify-center sm280:h-[80px] h-[104px]">
          <PlusSmallIcon color={colors.gray[0]} width={34} height={34} />
        </div>
      </div>
      <div className="absolute bottom-0 left-0 text-gray-0 text-center w-full sm280:text-[12px] text-[14px] font-medium py-1">
        {title}
      </div>
    </>
  );

  return (
    <div
      className="relative flex flex-col items-center justify-center sm280:h-[80px] h-[104px] rounded-lg text-sm min-w-[80px] overflow-hidden whitespace-no-wrap overflow-ellipsis bg-gray-2 text-gray-6 cursor-pointer"
      onClick={openFileDialog}
    >
      {isLoading || loading ? (
        <div onClick={(e) => handleClickLoding(e)}>
          <LoadingComponent />
        </div>
      ) : (
        <div>
          {showImage ? (
            <>
              <img src={fileData.imageUrl} alt="truck_img" className={`rounded-lg w-full h-full object-cover`} />
              {isShowXmark && (
                <XMarkIcon
                  className="absolute top-1 right-1 z-10"
                  color={colors.gray[0]}
                  style={{ filter: 'drop-shadow(0 0 2px rgba(0, 0, 0, 0.7))' }}
                  onClick={(e) => onClickDelete(e)}
                />
              )}
            </>
          ) : (
            showSampleImage && (
              <>
                <img
                  src={fileData.sampleImageUrl}
                  alt={fileData.imageTitle || ''}
                  className="sm360:w-[104px] sm360:h-[68px] w-full h-full"
                />
              </>
            )
          )}

          {showFile ? (
            <span>{fileName ? fileName : `등록증.${fileData.imageUrl.split('.').at(-1)}`}</span>
          ) : showSampleImage ? null : (
            <DocumentArrowUpSolidIcon color={colors.gray[5]} width={32} height={32} />
          )}

          {fileData.isRequired && <RequiredTag />}

          {showCoverGradient && <GradientOverlay title={fileData.imageTitle || ''} />}

          {showImageTitle && (
            <div className="absolute bottom-0 left-0 text-gray-0 text-center w-full sm280:text-[12px] text-[14px] font-medium py-1 bg-[rgba(0,0,0,0.4)]">
              {fileData.imageTitle}
            </div>
          )}
        </div>
      )}
      <input
        id={`upload-button-${fileData?.id}`}
        className="hidden"
        type="file"
        accept={accept}
        onChange={(e) => handleChange(e)}
      ></input>
    </div>
  );
};

export default ImageUploader;
