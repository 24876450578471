import React, { useEffect, useRef, useState } from 'react';

type TooltipBoxProps = {
  msg: string;
  descMsg: string;
  minWidth?: number;
  maxWidth?: number;
  position?: string;
  alignmentTipbox?: 'center' | 'left' | 'right';
  autoAdjustPosition?: boolean;
};

const TooltipBox: React.FC<TooltipBoxProps> = ({
  msg,
  descMsg,
  minWidth = 250,
  maxWidth = 680,
  position = 'left',
  alignmentTipbox = 'center',
  autoAdjustPosition = false,
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [adjustedPosition, setAdjustedPosition] = useState<React.CSSProperties>({});

  useEffect(() => {
    if (tooltipRef.current && autoAdjustPosition) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      const adjustments: React.CSSProperties = {};

      if (tooltipRect.left < 0) {
        adjustments.left = '0';
        adjustments.transform = 'none';
      } else if (tooltipRect.right > viewportWidth) {
        adjustments.left = 'auto';
        adjustments.right = '0';
        adjustments.transform = 'none';
      }

      if (tooltipRect.bottom > viewportHeight) {
        adjustments.top = 'auto';
        adjustments.bottom = '0';
      }

      setAdjustedPosition(adjustments);
    }
  }, [msg, descMsg, minWidth, maxWidth]);

  const alignmentStyle = {
    left: alignmentTipbox === 'center' ? '50%' : alignmentTipbox === 'left' ? '0' : 'auto',
    transform: alignmentTipbox === 'center' ? 'translateX(-50%)' : 'none',
  };

  return (
    <div className="relative">
      <div
        ref={tooltipRef}
        className={`w-auto absolute z-30 ${position}-0 top-0 rounded-lg py-2 px-3 bg-white border border-gray-100 shadow-md`}
        style={{
          minWidth: `${minWidth}px`,
          maxWidth: `${maxWidth}px`,
          ...alignmentStyle,
          ...adjustedPosition,
        }}
      >
        <div className="text-gray-8 break-all text-sm text-left" dangerouslySetInnerHTML={{ __html: msg }}></div>
        <div className="text-gray-8 break-all text-xs text-left" dangerouslySetInnerHTML={{ __html: descMsg }}></div>
      </div>
    </div>
  );
};

export default TooltipBox;
