import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';

import BasicButton from '../Common/Button/BasicButton';
import TextInputLabelLeft from '../Common/Input/TextInputLabelLeft';
import BasicPopup from '../Common/Popup/BasicPopup';
import CalendarPopup from '../Common/Popup/CalendarPopup';
import TooltipComponent from '../Common/Tooltip/TooltipComponent';
import FuelingReceiptUploader from './FuelingReceiptUploader';
import { deleteFuelingHistory, patchFuelingHistory, postFuelingHistory } from '@/api/public';
import { ChevronRightIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR, QUANTITY_IS_LESS_THAN_10000L } from '@/const/errorMessage';
import { nonNumericOrDecimalPattern, positiveFloatRegex, positiveIntegerAndNullRegex } from '@/const/regex';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { fuelingHistoryFormAtom } from '@/store/fuelingHistory';
import { formatNumber, formatYYYYMMDD } from '@/utils/common';

interface FuelingHistoryFormProps {
  onClosePopup: () => void;
  selectedDate: Date;
  driveVehicleInfoId: number;
  data?: FuelingHistory;
  refetch: () => void;
  updateFuelingHistoryValue?: (value: string) => void;
}

const FuelingHistoryForm = ({
  onClosePopup,
  selectedDate,
  driveVehicleInfoId,
  data,
  refetch,
  updateFuelingHistoryValue,
}: FuelingHistoryFormProps) => {
  const { showToast } = useToastContext();
  const setFuelingFormAtom = useSetAtom(fuelingHistoryFormAtom);
  const [isOpenCalendarPopup, setIsOpenCalendarPopup] = useState(false);

  const unitPriceRef = useRef<HTMLInputElement>(null);
  const amountRef = useRef<HTMLInputElement>(null);
  const priceRef = useRef<HTMLInputElement>(null);

  const [amountError, setAmountError] = useState(false);
  const [amountErrorMsg, setAmountErrorMsg] = useState('');

  const [fuelingForm, setFuelingForm] = useState<FuelingForm>({
    driveVehicleInfoId: driveVehicleInfoId,
    receiptImageUrl: data?.receiptImageUrl,
    unitPrice: data?.unitPrice,
    amount: data?.amount,
    price: data?.price || 0,
    refuelDay: data?.refuelDay || formatYYYYMMDD(selectedDate),
  });

  const [popupInfo, setPopupInfo] = useState<BasicPopupProps>({
    isShow: false,
    textRightBtn: '',
    onClickRightBtn: undefined,
  });

  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isOnlyNumber: boolean) => {
    let { name, value } = e.target;
    let convertedValue = String(value).replace(nonNumericOrDecimalPattern, '');
    if (name === 'amount') {
      if (Number(convertedValue) < 10000) {
        setAmountError(false);
        setAmountErrorMsg('');
      } else {
        setAmountError(true);
        setAmountErrorMsg(QUANTITY_IS_LESS_THAN_10000L);
      }
    }

    if (isOnlyNumber) {
      if (positiveIntegerAndNullRegex.test(convertedValue) && !convertedValue.startsWith('-')) {
        const str = convertedValue === '' ? null : convertedValue;
        calculateValues(name, Number(str));
      }
    } else {
      if (positiveFloatRegex.test(convertedValue.trim()) || convertedValue.trim() === '') {
        const str = convertedValue.trim() === '' ? null : convertedValue.trim();
        calculateValues(name, str);
      }
    }
  };

  const onClickSaveBtn = () => {
    if (validateForm()) {
      if (data) {
        if (patchFuelingHistoryMutation.isLoading) {
          return;
        }
        const request = {
          id: data.id.toString(),
          body: fuelingForm,
        };
        patchFuelingHistoryMutation.mutate(request);
      } else {
        if (postFuelingHistoryMutation.isLoading) {
          return;
        }

        if (driveVehicleInfoId) {
          postFuelingHistoryMutation.mutate(fuelingForm);
        }
      }
    }
  };

  const postFuelingHistoryMutation = useMutation((request: FuelingForm) => postFuelingHistory(request), {
    onSuccess: (response) => {
      onClosePopup();
      refetch();
      showToast('주유비가 추가되었어요.', 'success', 'bottom');
      setFuelingFormAtom({ ...fuelingForm, id: response.data.id });

      if (updateFuelingHistoryValue) {
        let value = formatNumber(fuelingForm.price?.toString()) + '원';
        if (fuelingForm.amount) {
          value = value + '(' + fuelingForm.amount + 'L)';
        }
        updateFuelingHistoryValue(value);
      }
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  const patchFuelingHistoryMutation = useMutation(
    (request: { id: string; body: FuelingForm }) => patchFuelingHistory(request),
    {
      onSuccess: () => {
        onClosePopup();
        refetch();
        showToast('주유비가 수정되었어요.', 'success', 'bottom');
        setFuelingFormAtom({ ...fuelingForm, id: data?.id });
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
    },
  );

  const onClickDateSelector = () => {
    setIsOpenCalendarPopup(true);
  };

  const onCloseCalendarPopup = () => {
    setIsOpenCalendarPopup(false);
  };

  const onChangeDate = (date: string | undefined) => {
    if (date === null || date === undefined) {
      return;
    } else {
      setFuelingForm({ ...fuelingForm, refuelDay: date });
    }
  };

  const closePopup = () => {
    setPopupInfo({ ...popupInfo, isShow: false });
  };

  const setPopupInfoForValidForm = (content: string) => {
    setPopupInfo({
      isShow: true,
      textContent: content,
      textRightBtn: '확인',
      onClickRightBtn: closePopup,
    });
  };

  const validateForm = () => {
    const { refuelDay, price } = fuelingForm;
    if (!refuelDay) {
      setPopupInfoForValidForm('주유일자를 입력해주세요.');
      return false;
    } else if (!price) {
      setPopupInfoForValidForm('주유금액을 입력해주세요.');
      return false;
    }

    return true;
  };

  const scrollIntoView = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e) {
      const { name } = e.target;
      if (name === 'unitPrice') {
        unitPriceRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'amount') {
        amountRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'price') {
        priceRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
  };

  useLayoutEffect(() => {
    const detectMobileKeyboard = () => {
      scrollIntoView();
    };
    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  });

  const onClickDelete = () => {
    setPopupInfo({
      isShow: true,
      title: '',
      textContent: `${dayjs(selectedDate || new Date()).format('YYYY-MM-DD')}<br/>주유비를 삭제할까요?`,
      textLeftBtn: '취소',
      onClickLeftBtn: closePopup,
      textRightBtn: '삭제',
      onClickRightBtn: handleDeleteConfirmation,
    });
  };

  const handleDeleteConfirmation = () => {
    closePopup();
    if (data?.id) {
      deleteFuelingHistoryMutation.mutate(data.id);
    }
  };

  const deleteFuelingHistoryMutation = useMutation((id: number) => deleteFuelingHistory(id), {
    onSuccess: () => {
      onClosePopup();
      showToast('주유비가 삭제되었어요.', 'success', 'bottom');
      refetch();
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  const updateFuelingForm = (data: FuelingForm) => {
    setFuelingForm(data);
  };

  const calculateValues = (name: string, value: string | number | null) => {
    let updatedForm = { ...fuelingForm, [name]: value };

    const { unitPrice, amount, price } = updatedForm;
    if (name === 'unitPrice' && amount && unitPrice) {
      updatedForm.price = Math.floor(unitPrice * amount);
    } else if (name === 'amount' && unitPrice && amount) {
      updatedForm.price = Math.floor(unitPrice * amount);
    } else if (name === 'price' && unitPrice && price) {
      updatedForm.amount = parseFloat((price / unitPrice).toFixed(2));
    } else if (name === 'price' && amount && price) {
      updatedForm.unitPrice = Math.floor(price / amount);
    }

    setFuelingForm(updatedForm);
  };

  return (
    <>
      <div className="pb-24">
        <div className="px-4">
          <div className="text-base pb-2">
            <div className="flex flex-col mb-2">
              <span className="flex-1 text-base text-gray-7">영수증</span>
            </div>
            <FuelingReceiptUploader
              driveVehicleInfoId={driveVehicleInfoId}
              receiptImageUrl={fuelingForm.receiptImageUrl}
              updateFuelingForm={updateFuelingForm}
            ></FuelingReceiptUploader>
          </div>
          <div className="flex flex-col gap-4 pt-4">
            <div onClick={onClickDateSelector}>
              <TextInputLabelLeft
                ref={unitPriceRef}
                name="unitPrice"
                label="주유일자"
                placeholder="날짜 선택"
                value={fuelingForm.refuelDay}
                suffix={<ChevronRightIcon color={colors.gray[6]}></ChevronRightIcon>}
                onChange={(e) => onChangeFormInput(e, true)}
                readOnly
              ></TextInputLabelLeft>
            </div>

            <TextInputLabelLeft
              ref={unitPriceRef}
              name="unitPrice"
              label="단가"
              placeholder="단가 입력"
              value={fuelingForm?.unitPrice ? formatNumber(String(fuelingForm?.unitPrice)) : ''}
              onChange={(e) => onChangeFormInput(e, true)}
              onFocus={(e) => scrollIntoView(e)}
              inputMode="numeric"
              maxLength={5}
              suffix="원"
              tooltip={
                <TooltipComponent
                  title=""
                  content="단가는 예상 주유비 계산에 포함됩니다."
                  minWidth={220}
                  position="left"
                  alignmentTipbox="left"
                />
              }
            ></TextInputLabelLeft>

            <TextInputLabelLeft
              ref={amountRef}
              name="amount"
              label="수량"
              placeholder="수량 입력"
              value={fuelingForm?.amount ? String(fuelingForm?.amount) : ''}
              onChange={(e) => onChangeFormInput(e, false)}
              onFocus={(e) => scrollIntoView(e)}
              error={amountError}
              errorMsg={amountErrorMsg}
              inputMode="numeric"
              maxLength={6}
              tooltip={
                <TooltipComponent
                  title=""
                  content="수량은 유가 보조금 계산에 포함됩니다."
                  minWidth={220}
                  position="left"
                  alignmentTipbox="left"
                />
              }
              suffix="L"
            ></TextInputLabelLeft>

            <TextInputLabelLeft
              ref={priceRef}
              name="price"
              label="주유금액"
              placeholder="금액 입력"
              value={fuelingForm?.price ? formatNumber(String(fuelingForm?.price)) : ''}
              onChange={(e) => onChangeFormInput(e, true)}
              onFocus={(e) => scrollIntoView(e)}
              inputMode="numeric"
              maxLength={9}
              suffix="원"
              required
            ></TextInputLabelLeft>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 max-w-[720px] min-w-[280px] bg-white z-30">
        <div
          className={`absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]`}
        ></div>
        {data?.id && (
          <div className="w-1/4 min-w-[80px] mr-2">
            <BasicButton
              name={`삭제`}
              bgColor={colors.gray[0]}
              borderColor={colors.gray[3]}
              textColor={colors.gray[6]}
              fontSize={16}
              height={48}
              fontWeight="bold"
              onClick={() => onClickDelete()}
            ></BasicButton>
          </div>
        )}
        <div className="w-full min-w-[240px]">
          <BasicButton
            name={`저장`}
            bgColor={colors.primary}
            borderColor={colors.primary}
            textColor={colors.gray[0]}
            fontSize={16}
            height={48}
            fontWeight="bold"
            onClick={() => onClickSaveBtn()}
            isDisabled={amountError}
          ></BasicButton>
        </div>
      </div>
      <CalendarPopup
        selectedDate={fuelingForm.refuelDay}
        onChangeSelectedDate={onChangeDate}
        isShow={isOpenCalendarPopup}
        onClose={onCloseCalendarPopup}
        textLeftBtn="닫기"
        onClickLeftBtn={onCloseCalendarPopup}
      />

      <BasicPopup
        isShow={popupInfo.isShow}
        title={popupInfo?.title}
        textContent={popupInfo.textContent}
        textLeftBtn={popupInfo.textLeftBtn}
        onClickLeftBtn={popupInfo.onClickLeftBtn}
        textRightBtn={popupInfo.textRightBtn}
        onClickRightBtn={popupInfo.onClickRightBtn}
      ></BasicPopup>
    </>
  );
};

export default FuelingHistoryForm;
