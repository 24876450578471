import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Calendar, { CalendarProps } from 'react-calendar';

import TooltipComponent from '../Tooltip/TooltipComponent';
import '@/css/customCalendarStyle.css';
import { formatNumber } from '@/utils/common';
import 'dayjs/locale/ko';
import 'react-calendar/dist/Calendar.css';

interface incomeHistoryData {
  baseDay: string;
  expense: number;
  sales: number;
}

interface CustomCalendarProps {
  data: incomeHistoryData[];
  selectedDate: Date;
  onClickDate: (date: Date) => void;
  onClickMonth: (date: Date) => void;
}

const tileContent = (date: Date, data: incomeHistoryData[], selectedDate: Date) => {
  const contents = [];
  const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD');
  const activeDate = dayjs(selectedDate);

  const foundItem = data?.find((item: { baseDay: string | number | Date | dayjs.Dayjs | null | undefined }) => {
    return dayjs(item.baseDay).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD');
  });

  if (foundItem) {
    contents.push(
      <React.Fragment key={`${formattedDate}-content`}>
        <div
          className={`overflow-hidden whitespace-no-wrap overflow-ellipsis plus text-[8px] leading-[10px] text-center text-primary `}
        >
          {foundItem.sales > 0 && <>+{formatNumber(String(foundItem.sales))}</>}
        </div>
        <div
          className={`overflow-hidden whitespace-no-wrap overflow-ellipsis minus text-[8px] leading-[10px] text-center text-gray-8 `}
        >
          {foundItem.expense > 0 && <>-{formatNumber(String(foundItem.expense))}</>}
        </div>
      </React.Fragment>,
    );
  }
  return <div style={{ maxWidth: 'inherit', position: 'relative' }}>{contents}</div>;
};

const CustomCalendar = ({ data, selectedDate, onClickDate, onClickMonth }: CustomCalendarProps) => {
  const [minWidth, setMinWidth] = useState(316);
  const handleDateChange: CalendarProps['onChange'] = (value) => {
    onClickDate(value as Date);
  };

  const handlePrevNextClick = (value: Date | null) => {
    if (value) {
      onClickMonth(new Date(value.getFullYear(), value.getMonth(), 1));
    }
  };

  const getTileClassName = ({ date, view }: { date: Date; view: string }) => {
    const today = new Date();
    if (view !== 'month') return '';

    const isActiveDate = dayjs(date).isSame(selectedDate, 'day');
    const isSunday = date.getDay() === 0;

    if (date.getMonth() === today.getMonth() && date.getDate() === today.getDate()) {
      return 'todayTile';
    }

    if (isActiveDate) {
      return '';
    }

    if (today < date && isSunday) {
      return 'sunday opacity';
    }

    return isSunday ? 'sunday' : 'exceptSunday';
  };

  useEffect(() => {
    const handleResize = () => {
      setMinWidth(window.innerWidth < 360 ? 250 : window.innerWidth < 680 ? 316 : 636);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    // 클린업 함수
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Calendar
        onChange={handleDateChange}
        onClickDay={handleDateChange}
        onActiveStartDateChange={({ activeStartDate, view }) => {
          if (view === 'month') {
            handlePrevNextClick(activeStartDate);
          }
        }}
        value={selectedDate}
        view={'month'}
        formatDay={(locale, date) => dayjs(date).format('D')}
        formatYear={(locale, date) => dayjs(date).format('YYYY')}
        formatMonthYear={(locale, date) => dayjs(date).format('YYYY.MM')}
        calendarType="gregory"
        next2Label={null}
        prev2Label={null}
        tileContent={({ date }) => tileContent(date, data, selectedDate)}
        tileClassName={getTileClassName}
        // maxDate={new Date()}
        showNeighboringMonth={false}
      />
      <div className="absolute top-4 right-4">
        <TooltipComponent
          title="일자 하단에 표기되는<br/> <strong style='font-weight: 500'>+금액은 {매출(운송비+회차비)+유가보조금+기타수익}</strong>이 합산된 금액이며, <strong style='font-weight: 500'>-금액은 {지출(주유비+기타지출+통행료)}</strong>으로 계산된 금액입니다."
          content=""
          minWidth={minWidth}
          position="right"
          alignmentTipbox="right"
        />
      </div>
    </>
  );
};

export default CustomCalendar;
