import React, { useEffect } from 'react';

import { ChevronLeftIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import '@/css/imageModalStyle.css';
import 'swiper/css';
import 'swiper/css/zoom';
import { Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

interface ImageModalProps {
  isShow: boolean;
  onClose: () => void;
  imgList: string[];
  index: number | null;
}

const ImageModal = ({ isShow, onClose, imgList, index }: ImageModalProps) => {
  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isShow]);

  return (
    <>
      {isShow && (
        <div className="fixed flex flex-col items-center justify-center z-50 w-full h-screen bg-black top-0 max-w-[720px] pb-[24px] overflow-hidden">
          <div className={`absolute top-0 left-0 m-4 z-[1000]`} onClick={onClose}>
            <ChevronLeftIcon color={colors.gray[0]} />
          </div>

          <Swiper
            className="w-full h-full"
            zoom={{
              maxRatio: 3,
              minRatio: 1,
            }}
            initialSlide={index || 0}
            modules={[Zoom]}
            slidesPerView={1}
            grabCursor={true}
            speed={500}
            onSlideChangeTransitionStart={() => {
              const swiperImages = document.querySelectorAll('.swiper-slide img');
              swiperImages.forEach((img) => {
                const htmlImage = img as HTMLImageElement;
                htmlImage.style.transition = 'none';
              });
            }}
            onSlideChangeTransitionEnd={() => {
              const swiperImages = document.querySelectorAll('.swiper-slide img');
              swiperImages.forEach((img) => {
                const htmlImage = img as HTMLImageElement;
                htmlImage.style.transition = '';
              });
            }}
          >
            {imgList.map((image, idx) => (
              <SwiperSlide key={idx}>
                <div className="swiper-zoom-container">
                  <img src={image} alt={`이미지 ${idx + 1}`} className="w-full object-center" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
};

export default ImageModal;
