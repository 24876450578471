import React from 'react';

import { colors } from '@/const/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Checkbox } from '@mui/material';

export interface CheckBoxButtonProps {
  isChecked: boolean;
  checkedName: string;
  borderColor: string;
  textColor: string;
  unCheckedName?: string;
  fontWeight: 'normal' | 'semibold' | 'bold';
  height?: number;
  onClick: (e?: any) => void;
  className?: string;
}

function CheckBoxButton({
  isChecked,
  checkedName,
  unCheckedName,
  borderColor,
  textColor,
  fontWeight,
  height,
  onClick,
  className,
}: CheckBoxButtonProps) {
  const onClickBtn = (e: React.MouseEvent) => {
    onClick(e);
  };

  return (
    <div
      className={`flex justify-center items-center font-${fontWeight} ${className ? className : ''}`}
      onClick={(e: React.MouseEvent) => onClickBtn(e)}
    >
      <div
        className={`text-sm text-gray-8 border rounded-lg pl-1 pr-2 flex items-center ${
          isChecked ? `${borderColor}` : 'border-gray-4'
        }`}
        style={{
          height: height ? `${height}px` : '28px',
        }}
      >
        <Checkbox
          checked={isChecked}
          icon={<CheckCircleOutlinedIcon sx={{ color: colors.gray[4], fontSize: 18 }} />}
          checkedIcon={<CheckCircleIcon sx={{ fontSize: 18 }} />}
          sx={{
            '&.Mui-checked': {
              color: textColor,
            },
            padding: '4px',
          }}
        />
        <span className={`text-sm font-medium  text-gray-7`} style={{ color: isChecked ? textColor : '' }}>
          {isChecked ? checkedName : unCheckedName ? unCheckedName : checkedName}
        </span>
      </div>
    </div>
  );
}

export default CheckBoxButton;
