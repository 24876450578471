import React, { SVGProps } from 'react';

const SvgOtherIncomeColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="#3C5FBF"
      fillRule="evenodd"
      d="M8.607 6.446a11.248 11.248 0 0 1-2.082-2.964l-.068-.141a.489.489 0 0 1 .558-.687l.06.015c.6.15 1.233.093 1.798-.16l.13-.06a2.435 2.435 0 0 1 2 .001 2.436 2.436 0 0 0 1.999 0 2.438 2.438 0 0 1 2.001 0l.129.058a2.75 2.75 0 0 0 1.795.161l.062-.015c.402-.1.739.314.558.687l-.069.142a11.252 11.252 0 0 1-2.083 2.963l1.66 1.245a13.137 13.137 0 0 1 5.24 9.884A4.129 4.129 0 0 1 18.17 21.9H5.831a4.128 4.128 0 0 1-4.123-4.323 13.135 13.135 0 0 1 5.24-9.886l1.659-1.245Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M7.568 10.937a.75.75 0 0 1 .944.483L9.9 15.708l1.386-4.288a.75.75 0 0 1 1.427 0l1.386 4.288 1.387-4.288a.75.75 0 1 1 1.427.461l-.558 1.726h.895a.75.75 0 0 1 0 1.5h-1.38l-1.057 3.27a.75.75 0 0 1-1.427 0l-1.386-4.288-1.387 4.289a.75.75 0 0 1-1.427 0l-1.057-3.27H6.75a.75.75 0 0 1 0-1.5h.893l-.558-1.727a.75.75 0 0 1 .483-.944Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgOtherIncomeColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgOtherIncomeColorIcon;
