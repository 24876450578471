import React, { lazy } from 'react';

import BasicButton from '../Common/Button/BasicButton';
import { PlusSmallIcon } from '../Icon';
import DriveItemView from './DriveItemView';
import FuelingItemView from './FuelingItemView';
import OtherExpenseItemView from './OtherExpenseItemView';
import { colors } from '@/const/colors';
import { DRIVE_HISTORY_TYPE_EMPTY_TRANSPORT } from '@/const/drive';
import { PopupInfo } from '@/pages/Drive/DriveHome';
import { formatNumber } from '@/utils/common';
import { Stack, useMediaQuery } from '@mui/material';

const Tour = lazy(() => import('reactour'));
interface DriveViewProps {
  selectedDate: string;
  driveVehicleInfoId: number;
  data?: DriveInfoResponse;
  driveHistoryList: DriveHistoryList[];
  refetch: () => void;
  onClickItem: (type: string, name: string, index?: number) => void;
  onClickAddBtn: (popupInfo: PopupInfo) => void;
  onControlTutorial: () => void;
}

const DriveListView = ({
  selectedDate,
  driveVehicleInfoId,
  data,
  driveHistoryList,
  refetch,
  onClickItem,
  onClickAddBtn,
  onControlTutorial,
}: DriveViewProps) => {
  const isXXS = useMediaQuery('(max-width:360px)');

  const getTagList = (item: DriveHistoryList) => {
    const tagList: OptionDataType[] = [];
    if (item.toll !== null && item.toll > 0) {
      tagList.push({ id: 1, code: 'toll', desc: `${formatNumber(String(item.toll))}원`, key: '통행료' });
    }
    if (item.fuelCost !== null && item.fuelCost > 0) {
      tagList.push({ id: 3, code: 'fuelCost', desc: `${formatNumber(String(item.fuelCost))}원`, key: '예상 주유비' });
    }
    return tagList;
  };

  const isEmpty = (data: DriveHistoryList) => {
    return data?.driveHistoryType?.code === DRIVE_HISTORY_TYPE_EMPTY_TRANSPORT;
  };

  const fuellingHistoryBorder = driveHistoryList.length > 0 ? 'border-t-8 border-gray-1' : '';
  const otherExpenseHistoryBorder =
    driveHistoryList.length > 0 || (data?.fuelingHistories && data?.fuelingHistories.length > 0)
      ? 'border-t-8 border-gray-1'
      : '';
  return (
    <>
      {(!driveHistoryList || driveHistoryList.length === 0) &&
      (!data?.fuelingHistories || data.fuelingHistories.length === 0) &&
      (!data?.otherExpensesHistory || data?.otherExpensesHistory.length === 0) ? (
        <div className="flex justify-center items-center h-[67px]">
          <div className="text-gray-7">내역이 없습니다.</div>
        </div>
      ) : (
        <>
          {driveHistoryList?.map((item, index) => (
            <div className="px-4 pb-2" key={index}>
              <div className={`${index != driveHistoryList.length - 1 ? 'border-b border-gray-4' : ''}`}>
                <DriveItemView
                  driveVehicleInfoId={driveVehicleInfoId}
                  key={index}
                  data={item}
                  isEmpty={isEmpty(item)}
                  detailContents={item?.transportInfos}
                  tagList={getTagList(item)}
                  refetch={refetch}
                  onClickItem={() =>
                    onClickItem('drive', isEmpty(item) ? '공차 수정' : item.title || '일지 추가', index)
                  }
                  onControlTutorial={onControlTutorial}
                />
              </div>
            </div>
          ))}
          {data?.fuelingHistories && data?.fuelingHistories.length > 0 && (
            <div className={`${fuellingHistoryBorder} py-6 px-4`}>
              <div className="flex justify-between items-center pb-3">
                <div className="flex items-center">
                  <span className="text-gray-8 font-semibold mr-[6px] text-lg">주유비</span>
                </div>
              </div>
              <Stack spacing={2}>
                {data?.fuelingHistories?.map((fuelingHistory, index) => (
                  <div className="cursor-pointer" onClick={() => onClickItem('fuel', '주유비', index)} key={index}>
                    <FuelingItemView fuelingHistory={fuelingHistory}></FuelingItemView>
                  </div>
                ))}
              </Stack>
            </div>
          )}
          {data?.otherExpensesHistory && data?.otherExpensesHistory.length > 0 && (
            <div
              className={`${otherExpenseHistoryBorder} py-6 px-4 cursor-pointer`}
              onClick={() => onClickItem('otherExpense', '기타내역')}
            >
              <OtherExpenseItemView data={data?.otherExpensesHistory} />
            </div>
          )}
        </>
      )}
      <div className="px-4">
        <div className="border-t border-gray-4">
          <div className="flex xxs:flex-col justify-between items-center py-4">
            <BasicButton
              className="add-drive-form-btn"
              name={'일지'}
              icon={
                <div className="rounded-full bg-gray-2 w-[20px] h-[20px] flex items-center justify-center">
                  <PlusSmallIcon color={colors.gray[7]} strokeWidth={0.5}></PlusSmallIcon>
                </div>
              }
              bgColor={colors.gray[0]}
              borderColor={colors.gray[4]}
              textColor={colors.gray[7]}
              fontSize={15}
              fontWeight="medium"
              onClick={() => onClickAddBtn({ name: '일지 추가', type: 'drive' })}
              border="none"
              justify={isXXS ? 'start' : 'center'}
            />
            <BasicButton
              className="addFuelFormBtn"
              name={'주유비'}
              icon={
                <div className="rounded-full bg-gray-2 w-[20px] h-[20px] flex items-center justify-center">
                  <PlusSmallIcon color={colors.gray[7]} strokeWidth={0.5}></PlusSmallIcon>
                </div>
              }
              bgColor={colors.gray[0]}
              borderColor={colors.gray[4]}
              textColor={colors.gray[7]}
              fontSize={15}
              fontWeight="medium"
              onClick={() => onClickAddBtn({ name: '주유비 추가', type: 'fuel' })}
              border="none"
              justify={isXXS ? 'start' : 'center'}
            />
            <BasicButton
              className="addOtherExpenseFormBtn"
              name={'기타내역'}
              icon={
                <div className="rounded-full bg-gray-2 w-[20px] h-[20px] flex items-center justify-center">
                  <PlusSmallIcon color={colors.gray[7]} strokeWidth={0.5} />
                </div>
              }
              bgColor={colors.gray[0]}
              borderColor={colors.gray[4]}
              textColor={colors.gray[7]}
              fontSize={15}
              fontWeight="medium"
              onClick={() => onClickAddBtn({ name: '기타내역 추가', type: 'otherExpense' })}
              border="none"
              justify={isXXS ? 'start' : 'center'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DriveListView;
