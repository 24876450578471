import React from 'react';

import CheckBoxButton from './CheckBoxButton';

interface ButtonInfo {
  title: string;
  isClicked?: boolean;
  borderColor?: string;
  textColor?: string;
  fontWeight?: 'normal' | 'semibold' | 'bold';
  height?: number;
  onClickBtn: () => void;
}

export interface SwitchMultiButtonProps {
  className?: string;
  type: 'normal' | 'checkBox';
  buttonInfo: ButtonInfo[];
  borderColor?: string;
  textColor?: string;
  bgColor?: string;
}

function SwitchMultiButton({
  className,
  type = 'normal',
  buttonInfo,
  borderColor,
  textColor,
  bgColor,
}: SwitchMultiButtonProps) {
  return (
    <>
      <div className="flex w-full h-8 cursor-pointer">
        {buttonInfo.map((item, index) => (
          <div key={index}>
            {type === 'checkBox' ? (
              <CheckBoxButton
                key={index}
                isChecked={item.isClicked || false}
                checkedName={item.title}
                borderColor={item.borderColor || ''}
                textColor={item.textColor || ''}
                fontWeight={item.fontWeight || 'normal'}
                height={item.height || 28}
                onClick={item.onClickBtn}
                className={index !== 0 ? `${className} ml-4` : ''}
              ></CheckBoxButton>
            ) : (
              <div
                key={index}
                className={`border border-gray-4 items-center w-full text-center leading-8 ${
                  item.isClicked ? `${borderColor} text-[${textColor}] bg-[${bgColor}] font-semibold` : ''
                }
            ${index === 0 ? 'rounded-l-lg' : index === buttonInfo.length - 1 ? 'rounded-r-lg' : ''}`}
                onClick={item.onClickBtn}
              >
                {item.title}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default SwitchMultiButton;
